import { IBreadcrumbs } from "../../stores/breadcrumbs";

export const orderProductDelivery: IBreadcrumbs[] = [
  {
    link: "/order",
    title: "common.text.order",
    isDisabled: true,
  },
  {
    link: "/order/product-delivery",
    title: "common.text.itemDelivery",
  },
];

export const orderSalesOrder: IBreadcrumbs[] = [
  {
    link: "/order",
    title: "common.text.order",
    isDisabled: true,
  },
  {
    link: "/order/sales-order",
    title: "common.text.salesOrder",
  },
];

export const settingAccountUser: IBreadcrumbs[] = [
  {
    link: "/setting",
    title: "common.text.setting",
    isDisabled: true,
  },
  {
    link: "/setting/account",
    title: "common.text.account",
    isDisabled: true,
  },
  {
    link: "/setting/account/user",
    title: "common.text.user",
  },
];

export const settingAccountAccessRole: IBreadcrumbs[] = [
  {
    link: "/setting",
    title: "common.text.setting",
    isDisabled: true,
  },
  {
    link: "/setting/account",
    title: "common.text.account",
    isDisabled: true,
  },
  {
    link: "/setting/account/access-role",
    title: "common.text.accessRole",
  },
];

export const settingAccountCompany: IBreadcrumbs[] = [
  {
    link: "/setting",
    title: "common.text.setting",
    isDisabled: true,
  },
  {
    link: "/setting/account",
    title: "common.text.account",
    isDisabled: true,
  },
  {
    link: "/setting/account/company",
    title: "common.text.company",
  },
];

export const settingProduct: IBreadcrumbs[] = [
  {
    link: "/setting",
    title: "common.text.setting",
    isDisabled: true,
  },
  {
    link: "/setting/product",
    title: "common.text.item",
  },
];
