export const RECENT_FILTER_KEY = {
  userType: "filter.user_type",
  category: "filter.category",
  categoryType: "filter.category_type",
  company: "filter.company",
  deliveryType: "filter.delivery_type",
  orderDeliveryStatus: "filter.order_delivery_status",
  rewardRedeemStatus: "filter.reward_redeem_status",
  salesOrderStatus: "filter.sales_order_status",
};

export const PRODUCT_DELIVERY_STATUS_COLOR: any = {
  Done: "green",
  "On Progress": "orange",
  Active: "blue",
};

export const ORDER_DELIVERY_LOG_STATUS_COLOR: any = {
  Success: "green",
  Failed: "red",
};

export const PRODUCT_DELIVERY_DETAIL_STATUS_COLOR: any = {
  Available: "green",
  Redeemed: "red",
};

export const SALES_ORDER_STATUS_COLOR: any = {
  Done: "green",
  "On Progress": "orange",
  Active: "blue",
  "Partially Finished": "orange",
};

export const DELIVERY_TYPE = {
  direct: "Direct",
  loyalty: "Loyalty",
};

export const ACCESS_PERMISSION = {
  widget: {
    giftSent: "widget.gift-sent",
    giftNotSent: "widget.gift-not-sent",
    orderComplete: "widget.order-complete",
    orderInProcess: "widget.order-in-process",
    mostProductRedeem: "widget.most-product-redeem",
    productSummary: "widget.product-summary",
  },
  role: {
    index: "role.index",
    store: "role.store",
    show: "role.show",
    update: "role.update",
    destroy: "role.destroy",
  },
  user: {
    index: "user.index",
    store: "user.store",
    show: "user.show",
    update: "user.update",
    destroy: "user.destroy",
  },
  company: {
    index: "company.index",
    store: "company.store",
    show: "company.show",
    update: "company.update",
    destroy: "company.destroy",
  },
  product: {
    index: "product.index",
  },
  salesOrder: {
    index: "sales-order.index",
    store: "sales-order.store",
    update: "sales-order.update",
    show: "sales-order.show",
    detailCode: "sales-order.detail-code",
    import: "sales-order.import",
  },
  orderDelivery: {
    index: "order-delivery.index",
    show: "order-delivery.show",
    detailCode: "order-delivery.detail-code",
    detailGift: "order-delivery.detail-gift",
    excel: "order-delivery.excel",
  },
};
