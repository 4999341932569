import Button from "antd/es/button";
import Col from "antd/es/grid/col";
import Row from "antd/es/grid/row";
import Table from "antd/es/table";
import { ColumnType } from "antd/es/table/interface";
import { AxiosError, AxiosResponse } from "axios";
import { FC, useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  VuiNumberFormat,
  VuiSearch,
} from "../../../../../@vodea/vodea-ui/components";
import VuiContainer from "../../../../../@vodea/vodea-ui/components/Container";
import VuiContent from "../../../../../@vodea/vodea-ui/components/Content";
import VuiSectionTitle from "../../../../../@vodea/vodea-ui/components/Sections/Title";
import VuiSelectSingle from "../../../../../@vodea/vodea-ui/components/Select/Single";
import { StatusType } from "../../../../../@vodea/vodea-ui/components/Status";
import { RECENT_FILTER_KEY } from "../../../../../constant";
import { recentFilterData } from "../../../../../functions/global";
import CategoryRepository from "../../../../../repositories/CategoryRepository";
import ProductRepository from "../../../../../repositories/ProductRepository";
import { IBreadcrumbs } from "../../../../../stores/breadcrumbs";
import {
  ConstantService,
  ITableQuery,
  UrlParamsType,
  useBreadcrumbs,
  useDebounce,
  useIsMounted,
} from "../../../../../utils";

const qs = require("qs");

interface IParams extends UrlParamsType {
  category: any;
}

interface ITableData {
  id: number;
  name: string;
  created_at: string;
  created_by: string;
  status: StatusType;
}

interface IAppSettingProductList {
  breadcrumbs: IBreadcrumbs[];
}

const AppSettingProductList: FC<IAppSettingProductList> = ({ breadcrumbs }) => {
  useBreadcrumbs(breadcrumbs);
  const { t } = useTranslation();
  const hasInitialize = useRef(false);
  const isMounted = useIsMounted();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const [selectedCategory, setSelectedCategory] = useState<any>(
    searchParams.get("category")
      ? recentFilterData.getSelectedFilter(RECENT_FILTER_KEY.category, [
          Number(searchParams.get("category")),
        ])[0]
      : null
  );

  const [search, setSearch] = useState<string>(
    searchParams.get("search") || ""
  );
  const debouncedSearch = useDebounce<string>(search, 500);
  const [tableQuery, setTableQuery] = useState<ITableQuery>({
    page: Number(
      searchParams.get("page") || ConstantService.getConstant("DEFAULT_PAGE")
    ),
    perPage: Number(
      searchParams.get("perPage") ||
        ConstantService.getConstant("DEFAULT_PER_PAGE")
    ),
    sortField: searchParams.get("sortField") || null,
    sortOrder: searchParams.get("sortOrder") || null,
  });
  const [tableLoading, setTableLoading] = useState<boolean>(false);
  const [tableData, setTableData] = useState<ITableData[]>([]);
  const [totalData, setTotalData] = useState<number>(0);
  const tableColumns: ColumnType<ITableData>[] = [
    {
      title: t("common.text.category"),
      dataIndex: "category_name",
      key: "category_name",
    },
    {
      title: t("common.text.name"),
      dataIndex: "name",
      key: "name",
    },
    {
      title: t("common.text.price"),
      dataIndex: "base_price",
      key: "base_price",
      render: (text: any) => <VuiNumberFormat value={text} prefix={"Rp "} />,
    },
  ];

  const loadData = useCallback(
    async (searchParams) => {
      if (!isMounted) {
        return;
      }
      setTableLoading(true);

      const params: IParams = {
        search: searchParams.get("search"),
        page: Number(searchParams.get("page")),
        per_page: Number(searchParams.get("perPage")),
        order_by: searchParams.get("sortField"),
        sorted_by: searchParams.get("sortOrder"),
        category: searchParams.get("category"),
      };

      await ProductRepository.findAll(params)
        .then((response: AxiosResponse) => {
          if (isMounted) {
            const { data: responseData, meta } = response.data;
            setTableLoading(false);
            setTableData(responseData);
            setTotalData(meta.total);
          }
        })
        .catch((error: AxiosError) => {
          if (isMounted) {
            setTableLoading(false);
          }
        });

      hasInitialize.current = true;
    },
    [isMounted]
  );

  const handleTableChange = useCallback(
    (pagination: any, filters: any, sorter: any, extra: any) => {
      setTableQuery((prevState) => ({
        ...prevState,
        page: pagination.current,
        perPage: pagination.pageSize,
        ...(sorter.order ? { sortOrder: sorter.order } : {}),
        ...(sorter.field ? { sortField: sorter.field } : {}),
      }));
    },
    []
  );

  const setQueryParams = useCallback(() => {
    const params: Partial<IParams> = {};

    Object.assign(params, {
      ...(debouncedSearch ? { search: debouncedSearch } : {}),
      ...(tableQuery.page ? { page: tableQuery.page } : {}),
      ...(tableQuery.perPage ? { perPage: tableQuery.perPage } : {}),
      ...(tableQuery.sortField ? { sortField: tableQuery.sortField } : {}),
      ...(tableQuery.sortOrder ? { sortOrder: tableQuery.sortOrder } : {}),
      ...(selectedCategory ? { category: selectedCategory.value } : {}),
    });

    const queryParams = qs.stringify(params, { indices: false });

    if (queryParams) {
      setSearchParams(`?${queryParams}`);
    } else {
      navigate("");
    }
  }, [
    setSearchParams,
    navigate,
    debouncedSearch,
    tableQuery,
    selectedCategory,
  ]);

  // Lifecycle
  useEffect(() => {
    if (searchParams.get("page")) {
      (async () => {
        await loadData(searchParams);
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  const handleReset = () => {
    setSelectedCategory(null);
  };

  useEffect(() => {
    isMounted ? setQueryParams() : setSearchParams("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMounted, tableQuery]);

  useEffect(() => {
    if (isMounted && hasInitialize.current) {
      setTableQuery((s) => ({
        ...s,
        page: Number(ConstantService.getConstant("DEFAULT_PAGE")),
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCategory, debouncedSearch]);

  return (
    <>
      <VuiContainer>
        <VuiSectionTitle title={t("common.text.item")} />

        <VuiContent>
          <Row gutter={[10, 10]} className="mb24">
            <Col className="gutter-row" span={6} xs={24} md={12} lg={10}>
              <VuiSearch
                allowClear
                defaultValue={search}
                onSearch={(value) => setSearch(value)}
              />
            </Col>
          </Row>

          <Row gutter={[10, 10]} className="mb16">
            <Col className="gutter-row">
              <VuiSelectSingle
                value={selectedCategory}
                onChange={(value: any) => {
                  setSelectedCategory(value);
                  recentFilterData.save(RECENT_FILTER_KEY.category, [value]);
                }}
                repository={CategoryRepository}
                placeholder={t("common.text.allCategories")}
              />
            </Col>
            <Col>
              <Button type="link" onClick={handleReset}>
                Reset
              </Button>
            </Col>
          </Row>

          <Table
            rowKey={"id"}
            bordered
            columns={tableColumns}
            dataSource={tableData}
            loading={tableLoading}
            onChange={handleTableChange}
            pagination={
              tableData.length > 0
                ? {
                    current: tableQuery.page,
                    pageSize: tableQuery.perPage,
                    total: totalData,
                  }
                : false
            }
          />
        </VuiContent>
      </VuiContainer>
    </>
  );
};

export default AppSettingProductList;
