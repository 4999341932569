import React, { useCallback, useEffect } from "react";
import VuiContainer from "../../../../../@vodea/vodea-ui/components/Container";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Form from "antd/lib/form";
import { useState } from "@hookstate/core";
import { Col, Row } from "antd/lib/grid";
import Card from "antd/lib/card";
import Space from "antd/lib/space";
import Input from "antd/es/input";
import DatePicker from "antd/es/date-picker";
import Button from "antd/es/button";
import Radio from "antd/es/radio";
import Text from "antd/es/typography/Text";
import { AxiosError, AxiosResponse } from "axios";
import {
  $clone,
  convertLabelInValue,
  handleSaveResponseError,
  handleSaveResponseSuccess,
  openNotification,
  useBreadcrumbs,
  useIsMounted,
} from "../../../../../utils";
import moment from "moment";
import {
  VuiActionTable,
  VuiFormAction,
  VuiFormLayout,
  VuiFormTitle,
  VuiInputCurrency,
  VuiNumberFormat,
} from "../../../../../@vodea/vodea-ui/components";
import VuiContent from "../../../../../@vodea/vodea-ui/components/Content";
import VuiSectionTitle from "../../../../../@vodea/vodea-ui/components/Sections/Title";
import { IBreadcrumbs } from "../../../../../stores/breadcrumbs";

import { Skeleton, Table } from "antd";
import { ColumnType } from "antd/lib/table";
import VuiModalConfirmation from "../../../../../@vodea/vodea-ui/components/Modal/Confirmation";
import { PlusOutlined } from "@ant-design/icons";
import ModalAddDirectBatch from "../Components/ModalAddDirectBatch";
import ModalAddLoyaltyBatch from "../Components/ModalAddLoyaltyBatch";
import VuiModalImport from "../../../../../@vodea/vodea-ui/components/Modal/Import";
import SalesOrderRepository from "../../../../../repositories/SalesOrderRepository";
import { handleBackendError } from "../../../../../functions/global";
import CompanyRepository from "../../../../../repositories/CompanyRepository";
import ConstantRepository from "../../../../../repositories/ConstantRepository";
import CategoryRepository from "../../../../../repositories/CategoryRepository";
import VuiSelectSingle from "../../../../../@vodea/vodea-ui/components/Select/Single";
import OperatorRepository from "../../../../../repositories/OperatorRepository";
import ProductRepository from "../../../../../repositories/ProductRepository";
import CampaignRepository from "../../../../../repositories/CampaignRepository";
import { ACCESS_PERMISSION } from "../../../../../constant";
import VuiAccessible from "../../../../../@vodea/vodea-ui/components/Accessible";
import ImportTemplateRepository from "../../../../../repositories/ImportTemplateRepository";
import fileDownload from "js-file-download";
import { ProductVariant } from "../../../../../models/ProductVariant";
import { Resource } from "../../../../../models/Resource";
import ModalUpdateInformation from "../Components/ModalUpdateInformation";
import Link from "antd/lib/typography/Link";

const deliveryType: string[] = ["direct", "loyalty"];

interface IAppOrderSalesOrderForm {
  breadcrumbs: IBreadcrumbs[];
}

const AppOrderSalesOrderForm: React.FC<IAppOrderSalesOrderForm> = ({
  breadcrumbs,
}) => {
  useBreadcrumbs(breadcrumbs);
  const isMounted = useIsMounted();
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const title = t("salesOrder.title");
  const pageTitle = t(id ? "common.text.detailItem" : "common.text.addItem", {
    item: t("common.text.salesOrder"),
  });
  const [data, setData] = React.useState<any>(null);
  const [tableData, setTableData] = React.useState<any[]>([]);
  const [tableColumns, setTableColumns] = React.useState<ColumnType<any>[]>([
    {
      title: t("common.text.category"),
      key: "category",
      render: (text: any, record: any) => record.category.name,
    },
    {
      title: t("common.text.product"),
      key: "product",
      render: (text: any, record: any) => record.product.name,
    },
    {
      title: t("common.text.item"),
      key: "variant",
      render: (text: any, record: any) => record.variant.name,
    },
    {
      title: t("common.text.validDate"),
      dataIndex: "valid_from",
      key: "valid_from",
      render: (text: any, record: any) => {
        return `${moment(text).format("DD MMM YYYY")} - ${moment(
          record.valid_to
        ).format("DD MMM YYYY")}`;
      },
    },
    {
      title: t("common.text.qty"),
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: `${t("common.text.price")} (IDR)`,
      dataIndex: "price",
      key: "price",
      render: (text: any) => <VuiNumberFormat value={text} />,
    },
    {
      title: `${t("common.text.total")} (IDR)`,
      key: "total",
      render: (text: any, record: any) => (
        <VuiNumberFormat value={record.quantity * record.price} />
      ),
    },
  ]);
  const [tableDataReward, setTableDataReward] = React.useState<any[]>([]);
  const [tableColumnsReward, setTableColumnsReward] = React.useState<
    ColumnType<any>[]
  >([
    {
      title: t("common.text.point"),
      dataIndex: "point",
      key: "point",
    },
    {
      title: t("common.text.qty"),
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: t("common.text.validFrom"),
      dataIndex: "valid_from",
      key: "valid_from",
      render: (text: any) => {
        return moment(text).format("DD MMMM YYYY");
      },
    },
    {
      title: t("common.text.validTo"),
      dataIndex: "valid_to",
      key: "valid_to",
      render: (text: any) => {
        return moment(text).format("DD MMMM YYYY");
      },
    },
  ]);

  // Detail
  const [form] = Form.useForm();
  const disable = useState(false);
  const loading = useState(false);
  const isFetchingData = useState(false);
  const selectedCompany = Form.useWatch("company_id", form);

  useEffect(() => {
    loadData();
    loadDeliveryTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadData = () => {
    if (!id) {
      return;
    }

    disable.set(true);
    isFetchingData.set(true);

    SalesOrderRepository.findOne(id, {
      with: [
        "salesOrderGifts.product.operator.category",
        "salesOrderItems.product.operator.category",
        "type",
        "company",
        "campaign",
      ],
    })
      .then((response: AxiosResponse) => {
        const { data: responseData } = response.data;
        setData(responseData);
        const type: string = responseData.type.label.toLowerCase();
        const modified: any = {
          number: responseData.number,
          date: moment(responseData.date),
          company_id: convertLabelInValue(responseData.company) || null,
          campaign_id: convertLabelInValue(responseData.campaign) || null,
        };

        setDeliveryTypeValue(type);

        if (type === deliveryType[0]) {
          const modifiedSalesOrderItems = responseData.sales_order_items.map(
            (item: any) => {
              return {
                id: item.id,
                category: item.product.operator.category,
                product: item.product.operator,
                variant: item.product,
                quantity: item.quantity,
                price: item.price,
                is_multiple_redeem: item.is_multiple_redeem,
                valid_to: item.valid_to,
                valid_from: item.valid_from,
              };
            }
          );
          setTableData(modifiedSalesOrderItems);
          if (tableColumns.length === 7) {
            setTableColumns((s) => [
              ...s,
              {
                title: "",
                key: "action",
                render: (text: any, record: any) => (
                  <VuiAccessible
                    access={ACCESS_PERMISSION.salesOrder.detailCode}
                  >
                    <VuiActionTable<any>
                      detailLabel={t("common.text.view")}
                      detailLink={`${pathname}/batch/${record.id}`}
                    />
                  </VuiAccessible>
                ),
              },
            ]);
          }
        }

        if (type === deliveryType[1]) {
          const modifiedSalesOrderItems = responseData.sales_order_items.map(
            (item: any) => {
              return {
                id: item.id,
                quantity: item.quantity,
                point: item.point,
                valid_to: item.valid_to,
                valid_from: item.valid_from,
              };
            }
          );
          setTableDataReward(modifiedSalesOrderItems);
          if (tableColumnsReward.length === 4) {
            setTableColumnsReward((s) => [
              ...s,
              {
                title: "",
                key: "action",
                render: (text: any, record: any) => (
                  <VuiAccessible
                    access={ACCESS_PERMISSION.salesOrder.detailCode}
                  >
                    <VuiActionTable<any>
                      detailLink={`${pathname}/reward/${record.id}`}
                    />
                  </VuiAccessible>
                ),
              },
            ]);
          }

          const salesOrderGifts = responseData.sales_order_gifts.map(
            (item: any) => {
              return {
                category_id: convertLabelInValue(
                  item.product.operator.category
                ),
                product_id: convertLabelInValue(item.product.operator),
                variant_id: convertLabelInValue(item.product),
                point_value: item.point_value,
                quantity: item.quantity,
                price: item.price,
              };
            }
          );

          modified.gifts = salesOrderGifts;
        }

        form.setFieldsValue({
          ...modified,
        });

        isFetchingData.set(false);
      })
      .catch((error: AxiosError) => {
        isFetchingData.set(false);
        navigate(-1);
        handleBackendError(error, t("notification.error.default"));
      });
  };

  const loadDeliveryTypes = () => {
    ConstantRepository.select({
      for: "delivery_type",
    })
      .then((response: AxiosResponse) => {
        const { data: responseData } = response.data;
        setDeliveryTypes(responseData);
      })
      .catch((error: AxiosError) => {
        navigate(-1);
        handleBackendError(error, t("notification.error.default"));
      });
  };

  const onFinish = async (data: any) => {
    if (deliveryTypeValue === deliveryType[0] && tableData.length < 1) {
      openNotification(
        "error",
        t("validation.required", {
          item: t("common.form.product.label"),
        })
      );
      return;
    }

    if (deliveryTypeValue === deliveryType[1] && tableDataReward.length < 1) {
      openNotification(
        "error",
        t("validation.required", {
          item: t("common.text.reward"),
        })
      );
      return;
    }

    const responseMessage = t(`notification.success.createItem`, {
      item: title,
    });

    loading.set(true);

    const formData: any = {
      ...data,
      company_id: data.company_id.value,
      campaign_id: data.campaign_id.value,
      date: moment(data.date).format("YYYY-MM-DD"),
      type_id: deliveryTypes.find(
        (type) => type.label.toLowerCase() === deliveryTypeValue
      )?.id,
    };

    if (deliveryTypeValue === deliveryType[0]) {
      formData.sales_order_items = tableData.map((item) => {
        return {
          product_id: item.variant.id,
          quantity: item.quantity,
          price: item.price,
          is_multiple_redeem: item.is_multiple_redeem,
          valid_from: item.valid_from,
          valid_to: item.valid_to,
          ...(item?.import_code_id
            ? { import_code_id: item.import_code_id }
            : {}),
        };
      });
    }

    if (deliveryTypeValue === deliveryType[1]) {
      formData.sales_order_items = tableDataReward.map((item) => {
        return {
          quantity: item.quantity,
          point: item.point,
          valid_from: item.valid_from,
          valid_to: item.valid_to,
        };
      });

      formData.sales_order_gifts = data.gifts.map((item: any) => {
        return {
          product_id: item.variant_id.value,
          quantity: item.quantity,
          point_value: item.point_value,
          price: item.price,
        };
      });

      delete formData.gifts;
    }

    SalesOrderRepository.create(formData)
      .then((response: AxiosResponse) => {
        if (isMounted) {
          loading.set(false);
          handleSaveResponseSuccess(response, responseMessage, navigate);
        }
      })
      .catch((error: AxiosError) => {
        if (isMounted) {
          loading.set(false);
          handleSaveResponseError(error);
        }
      });
  };

  // Delivery Type Change
  const [deliveryTypeValue, setDeliveryTypeValue] = React.useState<
    null | string
  >(deliveryType[0]);
  const [selectedDeliveryType, setSelectedDeliveryType] = React.useState<
    null | string
  >(null);
  const [deliveryTypes, setDeliveryTypes] = React.useState<any[]>([]);

  const onDeliveryTypeChange = (e: any) => {
    const oldValue = deliveryTypeValue;
    const newValue = e.target.value;
    if (oldValue) {
      if (newValue !== oldValue) {
        setSelectedDeliveryType(newValue);
      }
    } else {
      setDeliveryTypeValue(newValue);
    }
  };

  const deliveryTypeModalCallback = useCallback(async () => {
    setDeliveryTypeValue(selectedDeliveryType);
    form.setFieldsValue({ campaign_id: null });
    setSelectedDeliveryType(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t, selectedDeliveryType]);

  //Direct Add Batch Modal
  const [showDirectAddBatchModal, setShowDirectAddBatchModal] =
    React.useState<boolean>(false);

  //Loyalty Add Batch Modal
  const [showLoyaltyAddBatchModal, setShowLoyaltyAddBatchModal] =
    React.useState<boolean>(false);

  //Import Modal
  const [showImportModal, setShowImportModal] = React.useState<boolean>(false);

  const [exportLoading, setExportLoading] = React.useState<boolean>(false);
  const handleExportProduct = useCallback(() => {
    if (id) {
      setExportLoading(true);
      SalesOrderRepository.export(id)
        .then((response: AxiosResponse) => {
          fileDownload(response.data, "products.xlsx");
          setExportLoading(false);
        })
        .catch((error: AxiosError) => {
          setExportLoading(false);
          handleBackendError(error, t("notification.error.default"));
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  //render
  const renderDirectTypeContent = useCallback(() => {
    const total =
      tableData.length > 0
        ? tableData
            .map((item) => item.quantity * item.price)
            .reduce((total, num) => total + num)
        : 0;
    return (
      <Col
        className="gutter-row"
        md={{
          span: 24,
        }}
        xs={{
          span: 24,
        }}
      >
        <Card
          title={t("common.text.order")}
          extra={
            <Space>
              {id && (
                <Button
                  type="primary"
                  ghost
                  onClick={handleExportProduct}
                  loading={exportLoading}
                >
                  {t("common.button.exportOrder")}
                </Button>
              )}

              {!id && (
                <Button
                  disabled={disable.get()}
                  onClick={() => setShowImportModal(true)}
                  type="primary"
                  ghost
                >
                  {t("common.button.importOrder")}
                </Button>
              )}

              {!id && (
                <Button
                  onClick={() => setShowDirectAddBatchModal(true)}
                  disabled={disable.get()}
                  style={{ minWidth: 72 }}
                  type="primary"
                >
                  {t("common.button.addItem", {
                    item: t("common.text.order"),
                  })}
                </Button>
              )}
            </Space>
          }
        >
          <Skeleton
            loading={isFetchingData.get()}
            title={false}
            active
            paragraph={{ rows: 4, width: "100%" }}
          >
            <div>
              <Table
                rowKey={"id"}
                bordered
                columns={tableColumns}
                dataSource={tableData}
                pagination={false}
              />
              <div className="text-align-right mt24">
                <>
                  <Text strong className="mr24">
                    Total
                  </Text>
                  <Text strong>
                    <VuiNumberFormat value={total} prefix={"Rp "} />
                  </Text>
                </>
              </div>
            </div>
          </Skeleton>
        </Card>
      </Col>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableData, tableColumns, disable]);

  const renderLoyaltyTypeContent = () => {
    return (
      <>
        <Skeleton
          loading={isFetchingData.get()}
          title={false}
          active
          paragraph={{ rows: 4, width: "100%" }}
        >
          <Col
            className="gutter-row"
            md={{
              span: 24,
            }}
            xs={{
              span: 24,
            }}
          >
            <Card
              title={t("common.text.reward")}
              extra={
                <Space>
                  <Button
                    disabled={disable.get()}
                    onClick={() => setShowImportModal(true)}
                    type="primary"
                    ghost
                  >
                    {t("common.button.import")}
                  </Button>

                  <Button
                    onClick={() => setShowLoyaltyAddBatchModal(true)}
                    disabled={disable.get()}
                    style={{ minWidth: 72 }}
                    type="primary"
                  >
                    {t("common.button.addItem", {
                      item: t("common.text.batch"),
                    })}
                  </Button>
                </Space>
              }
            >
              <div>
                <Table
                  rowKey={"id"}
                  bordered
                  columns={tableColumnsReward}
                  dataSource={tableDataReward}
                  pagination={false}
                />
              </div>
            </Card>
          </Col>
          <Col
            className="gutter-row"
            md={{
              span: 24,
            }}
            xs={{
              span: 24,
            }}
          >
            <Form.List
              name={["gifts"]}
              rules={[
                {
                  validator: async (_, names) => {
                    if (!names || names.length < 1) {
                      return Promise.reject(
                        new Error(
                          t("validation.required", {
                            item: t("common.text.gift"),
                          })
                        )
                      );
                    }
                  },
                },
              ]}
            >
              {(fields, { add, remove }, { errors }) => (
                <Card
                  title={t("common.text.gift")}
                  extra={
                    <Space>
                      <Button
                        disabled={disable.get()}
                        style={{ minWidth: 72 }}
                        type="primary"
                        onClick={() => add()}
                        icon={<PlusOutlined />}
                      >
                        {t("common.button.addItem", {
                          item: t("common.text.gift"),
                        })}
                      </Button>
                    </Space>
                  }
                >
                  <Space
                    style={{
                      width: "100%",
                    }}
                    direction="vertical"
                    size={15}
                  >
                    <Form.ErrorList errors={errors} />
                    <div className="ant-table-wrapper">
                      <div className="ant-table ant-table-bordered">
                        <div className="ant-table-container">
                          <div
                            className="ant-table-content"
                            style={{ overflowX: "auto" }}
                          >
                            <table style={{ tableLayout: "auto" }}>
                              <thead className="ant-table-thead">
                                <tr>
                                  <th className="ant-table-cell">Category</th>
                                  <th className="ant-table-cell">Product</th>
                                  <th className="ant-table-cell">Variant</th>
                                  <th className="ant-table-cell">Quantity</th>
                                  <th className="ant-table-cell">
                                    Point Value
                                  </th>
                                  <th className="ant-table-cell">Price</th>
                                  <th className="ant-table-cell">Total</th>
                                </tr>
                              </thead>
                              <tbody className="ant-table-tbody">
                                {fields.map(({ key, name, ...restField }) => {
                                  const getTotal = () => {
                                    const gifts = form.getFieldValue("gifts");
                                    const quantity: any = gifts[name]?.quantity;
                                    const price = gifts[name]?.price;
                                    const total =
                                      Number(quantity) * Number(price);
                                    return `Rp${isNaN(total) ? "0" : total}`;
                                  };

                                  return (
                                    <tr
                                      key={key}
                                      className="ant-table-row ant-table-row-level-0"
                                    >
                                      <td className="ant-table-cell">
                                        <Form.Item
                                          className="mb0"
                                          {...restField}
                                          name={[name, "category_id"]}
                                          rules={[
                                            {
                                              required: true,
                                              message: t(
                                                "validation.required",
                                                {
                                                  item: t(
                                                    "common.form.category.label"
                                                  ),
                                                }
                                              ),
                                            },
                                          ]}
                                        >
                                          <VuiSelectSingle
                                            onChange={() => {
                                              const gifts = $clone(
                                                form.getFieldValue("gifts")
                                              );

                                              gifts[name].product_id = null;
                                              gifts[name].variant_id = null;

                                              form.setFieldsValue({
                                                gifts: gifts,
                                              });
                                            }}
                                            disabled={disable.get()}
                                            repository={CategoryRepository}
                                            placeholder={t(
                                              "common.form.category.placeholder"
                                            )}
                                          />
                                        </Form.Item>
                                      </td>
                                      <td className="ant-table-cell">
                                        <Form.Item className="mb0" shouldUpdate>
                                          {() => {
                                            return (
                                              <Form.Item
                                                className="mb0"
                                                {...restField}
                                                name={[name, "product_id"]}
                                                rules={[
                                                  {
                                                    required: true,
                                                    message: t(
                                                      "validation.required",
                                                      {
                                                        item: t(
                                                          "common.form.product.label"
                                                        ),
                                                      }
                                                    ),
                                                  },
                                                ]}
                                              >
                                                <VuiSelectSingle
                                                  onChange={() => {
                                                    const gifts = $clone(
                                                      form.getFieldValue(
                                                        "gifts"
                                                      )
                                                    );
                                                    gifts[name].variant_id =
                                                      null;

                                                    form.setFieldsValue({
                                                      gifts: gifts,
                                                    });
                                                  }}
                                                  disabled={
                                                    disable.get() ||
                                                    !form.getFieldValue(
                                                      "gifts"
                                                    )[name]?.category_id
                                                  }
                                                  selectParams={{
                                                    category:
                                                      form.getFieldValue(
                                                        "gifts"
                                                      )[name]?.category_id
                                                        ?.value,
                                                  }}
                                                  repository={
                                                    OperatorRepository
                                                  }
                                                  placeholder={t(
                                                    "common.form.product.placeholder"
                                                  )}
                                                />
                                              </Form.Item>
                                            );
                                          }}
                                        </Form.Item>
                                      </td>
                                      <td className="ant-table-cell">
                                        <Form.Item className="mb0" shouldUpdate>
                                          {() => {
                                            return (
                                              <Form.Item
                                                className="mb0"
                                                {...restField}
                                                name={[name, "variant_id"]}
                                                rules={[
                                                  {
                                                    required: true,
                                                    message: t(
                                                      "validation.required",
                                                      {
                                                        item: t(
                                                          "common.form.variant.label"
                                                        ),
                                                      }
                                                    ),
                                                  },
                                                ]}
                                              >
                                                <VuiSelectSingle
                                                  disabled={
                                                    disable.get() ||
                                                    !form.getFieldValue(
                                                      "gifts"
                                                    )[name]?.product_id
                                                  }
                                                  selectParams={{
                                                    operator:
                                                      form.getFieldValue(
                                                        "gifts"
                                                      )[name]?.product_id
                                                        ?.value,
                                                  }}
                                                  repository={ProductRepository}
                                                  placeholder={t(
                                                    "common.form.variant.placeholder"
                                                  )}
                                                />
                                              </Form.Item>
                                            );
                                          }}
                                        </Form.Item>
                                      </td>
                                      <td className="ant-table-cell">
                                        <Form.Item
                                          className="mb0"
                                          {...restField}
                                          style={{ minWidth: "240px" }}
                                          name={[name, "quantity"]}
                                          rules={[
                                            {
                                              required: true,
                                              message: t(
                                                "validation.required",
                                                {
                                                  item: t(
                                                    "common.form.quantity.label"
                                                  ),
                                                }
                                              ),
                                            },
                                          ]}
                                        >
                                          <Input
                                            disabled={disable.get()}
                                            type="number"
                                            placeholder={t(
                                              "common.form.quantity.placeholder"
                                            )}
                                          />
                                        </Form.Item>
                                      </td>
                                      <td className="ant-table-cell">
                                        <Form.Item
                                          className="mb0"
                                          {...restField}
                                          style={{ minWidth: "240px" }}
                                          name={[name, "point_value"]}
                                          rules={[
                                            {
                                              required: true,
                                              message: t(
                                                "validation.required",
                                                {
                                                  item: t(
                                                    "common.form.pointValue.label"
                                                  ),
                                                }
                                              ),
                                            },
                                          ]}
                                        >
                                          <Input
                                            disabled={disable.get()}
                                            type="number"
                                            placeholder={t(
                                              "common.form.pointValue.placeholder"
                                            )}
                                          />
                                        </Form.Item>
                                      </td>
                                      <td className="ant-table-cell">
                                        <Form.Item
                                          className="mb0"
                                          {...restField}
                                          style={{ minWidth: "240px" }}
                                          name={[name, "price"]}
                                          rules={[
                                            {
                                              required: true,
                                              message: t(
                                                "validation.required",
                                                {
                                                  item: t(
                                                    "common.form.price.label"
                                                  ),
                                                }
                                              ),
                                            },
                                          ]}
                                        >
                                          <VuiInputCurrency
                                            disabled={disable.get()}
                                            prefix="Rp "
                                            placeholder={t(
                                              "common.form.price.placeholder"
                                            )}
                                          />
                                        </Form.Item>
                                      </td>
                                      <td className="ant-table-cell">
                                        <Form.Item className="mb0" shouldUpdate>
                                          {() => {
                                            return (
                                              <VuiNumberFormat
                                                value={getTotal()}
                                                prefix={"Rp "}
                                              />
                                            );
                                          }}
                                        </Form.Item>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                    {fields.length > 0 && (
                      <div className="text-align-right mt24">
                        <Form.Item shouldUpdate>
                          {() => {
                            const gifts = form.getFieldValue("gifts");
                            const total = gifts
                              .map((item: any) => {
                                const quantity = item?.quantity || 0;
                                const price = item?.price || 0;
                                return Number(quantity) * Number(price);
                              })
                              .reduce(
                                (total: number, num: number) => total + num
                              );

                            return (
                              <>
                                <Text strong className="mr24">
                                  Total
                                </Text>
                                <Text strong>
                                  <VuiNumberFormat
                                    value={total}
                                    prefix={"Rp "}
                                  />
                                </Text>
                              </>
                            );
                          }}
                        </Form.Item>
                      </div>
                    )}
                  </Space>
                </Card>
              )}
            </Form.List>
          </Col>
        </Skeleton>
      </>
    );
  };

  const handleDownloadTemplate = useCallback(() => {
    const isDirectType = deliveryTypeValue === deliveryType[0];
    return new Promise((resolve, reject) => {
      if (isDirectType) {
        ImportTemplateRepository.direct()
          .then((response: any) => {
            fileDownload(response.data, "direct-template.xlsx");
            resolve(true);
          })
          .catch((err: AxiosError) => {
            reject(err);
          });
      } else {
        ImportTemplateRepository.loyalty()
          .then((response: any) => {
            fileDownload(response.data, "loyalty-template.xlsx");
            resolve(true);
          })
          .catch((err: AxiosError) => {
            reject(err);
          });
      }
    });
  }, [deliveryTypeValue]);

  const handleUploadTemplate = useCallback(
    (file: any) => {
      const isDirectType = deliveryTypeValue === deliveryType[0];
      return new Promise((resolve, reject) => {
        if (isDirectType) {
          SalesOrderRepository.importDirect(file)
            .then((response: AxiosResponse<Resource<ProductVariant[]>>) => {
              const { data: responseData } = response.data;
              const tempData: any[] = responseData.map((item) => {
                return {
                  ...item,
                  category: {
                    name: item?.category_name,
                  },
                  product: {
                    name: item?.operator_name,
                  },
                  variant: {
                    id: item?.product_id,
                    name: item?.variant_name,
                  },
                };
              });
              setTableData((prevState) => [...prevState, ...tempData]);
              resolve(true);
            })
            .catch((err: AxiosError) => {
              reject(err);
            });
        } else {
          SalesOrderRepository.importLoyalty(file)
            .then((response: AxiosResponse<Resource<ProductVariant[]>>) => {
              const { data: responseData } = response.data;
              setTableDataReward((prevState) => [
                ...prevState,
                ...responseData,
              ]);
              resolve(true);
            })
            .catch((err: AxiosError) => {
              reject(err);
            });
        }
      });
    },
    [deliveryTypeValue]
  );

  const [openModalEdit, setOpenModalEdit] = React.useState(false);

  return (
    <>
      <VuiContainer>
        <VuiSectionTitle onBack={() => navigate(-1)} title={pageTitle} />

        <VuiContent>
          <VuiFormLayout
            form={form}
            preserve={false}
            layout={"vertical"}
            onFinish={onFinish}
          >
            <Row gutter={[16, 16]}>
              <Col
                className="gutter-row"
                md={{
                  span: 16,
                }}
                xs={{
                  span: 24,
                }}
              >
                <Card title={t("common.text.preference")}>
                  <Space
                    style={{
                      width: "100%",
                    }}
                    direction="vertical"
                    size={5}
                  >
                    <div className="vui-form-group type-column">
                      <div className="input-section">
                        <Skeleton
                          loading={isFetchingData.get()}
                          active
                          title={false}
                          paragraph={{ rows: 2, width: [120, "100%"] }}
                        >
                          {id && data ? (
                            <div style={{ marginBottom: 24 }}>
                              <VuiFormTitle
                                title={t("common.form.company.label")}
                              />
                              <Text strong>{data.company.name || "-"}</Text>
                            </div>
                          ) : (
                            <Form.Item
                              name="company_id"
                              label={t("common.form.company.label")}
                              rules={[
                                {
                                  required: true,
                                  message: t("validation.required", {
                                    item: t("common.form.company.label"),
                                  }),
                                },
                              ]}
                            >
                              <VuiSelectSingle
                                disabled={disable.get()}
                                repository={CompanyRepository}
                                placeholder={t(
                                  "common.form.company.placeholder"
                                )}
                                onChange={() => {
                                  form.setFieldsValue({ campaign_id: null });
                                }}
                              />
                            </Form.Item>
                          )}
                        </Skeleton>
                      </div>
                    </div>

                    <Row>
                      <Col xs={24} md={8}>
                        <div className="vui-form-group type-column">
                          <div className="input-section">
                            <Skeleton
                              loading={isFetchingData.get()}
                              active
                              title={false}
                              paragraph={{ rows: 2, width: [120, "100%"] }}
                            >
                              {id && data ? (
                                <div style={{ marginBottom: 24 }}>
                                  <VuiFormTitle
                                    title={t("common.form.deliveryType.label")}
                                  />
                                  <Text strong>{data.type.label || "-"}</Text>
                                </div>
                              ) : (
                                <Form.Item
                                  label={t("common.form.deliveryType.label")}
                                >
                                  <Radio.Group
                                    value={deliveryTypeValue}
                                    disabled={disable.get()}
                                  >
                                    <Radio
                                      onClick={onDeliveryTypeChange}
                                      value={deliveryType[0]}
                                    >
                                      {t("common.text.direct")}
                                    </Radio>
                                    <Radio
                                      onClick={onDeliveryTypeChange}
                                      value={deliveryType[1]}
                                    >
                                      {t("common.text.loyalty")}
                                    </Radio>
                                  </Radio.Group>
                                </Form.Item>
                              )}
                            </Skeleton>
                          </div>
                        </div>
                      </Col>
                      <Col xs={24} md={16}>
                        <div className="vui-form-group type-column">
                          <div className="input-section">
                            <Skeleton
                              loading={isFetchingData.get()}
                              active
                              title={false}
                              paragraph={{ rows: 2, width: [120, "100%"] }}
                            >
                              {id && data ? (
                                <div style={{ marginBottom: 24 }}>
                                  <VuiFormTitle
                                    title={t("common.form.campaign.label")}
                                  />
                                  <Text strong>
                                    {data.campaign.name || "-"}
                                  </Text>
                                </div>
                              ) : (
                                <Form.Item
                                  name="campaign_id"
                                  label={t("common.form.campaign.label")}
                                  rules={[
                                    {
                                      required: true,
                                      message: t("validation.required", {
                                        item: t("common.form.campaign.label"),
                                      }),
                                    },
                                  ]}
                                >
                                  <VuiSelectSingle
                                    disabled={disable.get() || !selectedCompany}
                                    repository={CampaignRepository}
                                    selectParams={{
                                      company_id: selectedCompany?.value,
                                      type: deliveryTypes.find(
                                        (type) =>
                                          type.label.toLowerCase() ===
                                          deliveryTypeValue
                                      )?.id,
                                    }}
                                    placeholder={t(
                                      "common.form.campaign.placeholder"
                                    )}
                                  />
                                </Form.Item>
                              )}
                            </Skeleton>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Space>
                </Card>
              </Col>

              <Col
                className="gutter-row"
                md={{
                  span: 8,
                }}
                xs={{
                  span: 24,
                }}
              >
                <Card
                  title={t("common.text.information")}
                  style={{ height: "100%" }}
                  extra={
                    id &&
                    data && (
                      <VuiAccessible
                        access={ACCESS_PERMISSION.salesOrder.update}
                      >
                        <Link onClick={() => setOpenModalEdit(true)}>Edit</Link>
                      </VuiAccessible>
                    )
                  }
                >
                  <div className="vui-form-group type-column">
                    <div className="input-section">
                      <Skeleton
                        loading={isFetchingData.get()}
                        active
                        title={false}
                        paragraph={{ rows: 2, width: [120, "100%"] }}
                      >
                        {id && data ? (
                          <div style={{ marginBottom: 24 }}>
                            <VuiFormTitle
                              title={t("common.form.orderNumber.label")}
                            />
                            <Text strong>{data.number || "-"}</Text>
                          </div>
                        ) : (
                          <Form.Item
                            name="number"
                            label={t("common.form.orderNumber.label")}
                            rules={[
                              {
                                required: true,
                                message: t("validation.required", {
                                  item: t("common.form.orderNumber.label"),
                                }),
                              },
                            ]}
                          >
                            <Input
                              disabled={disable.get()}
                              placeholder={t(
                                "common.form.orderNumber.placeholder"
                              )}
                            />
                          </Form.Item>
                        )}
                      </Skeleton>
                    </div>
                  </div>

                  <div className="vui-form-group type-column">
                    <div className="input-section">
                      <Skeleton
                        loading={isFetchingData.get()}
                        active
                        title={false}
                        paragraph={{ rows: 2, width: [120, "100%"] }}
                      >
                        {id && data ? (
                          <div style={{ marginBottom: 24 }}>
                            <VuiFormTitle title={t("common.form.date.label")} />
                            <Text strong>
                              {moment(data.date).format("DD MMM YYYY") || "-"}
                            </Text>
                          </div>
                        ) : (
                          <Form.Item
                            name="date"
                            label={t("common.form.date.label")}
                            rules={[
                              {
                                required: true,
                                message: t("validation.required", {
                                  item: t("common.form.date.label"),
                                }),
                              },
                            ]}
                          >
                            <DatePicker
                              disabled={disable.get()}
                              format="DD-MM-YYYY"
                              style={{ width: 400, maxWidth: "100%" }}
                              placeholder={t("common.form.date.placeholder")}
                            />
                          </Form.Item>
                        )}
                      </Skeleton>
                    </div>
                  </div>
                </Card>
              </Col>
              {deliveryTypeValue === deliveryType[0]
                ? renderDirectTypeContent()
                : renderLoyaltyTypeContent()}
            </Row>
            {!id && (
              <VuiFormAction
                label={"common.text.submitSalesOrderWithMark"}
                saveLabel={"common.button.submit"}
                isLoading={loading.get()}
                permission={ACCESS_PERMISSION.salesOrder.store}
              />
            )}
          </VuiFormLayout>
        </VuiContent>
      </VuiContainer>

      <VuiModalConfirmation
        show={!!selectedDeliveryType}
        onOk={deliveryTypeModalCallback}
        onCancel={() => setSelectedDeliveryType(null)}
        dangerSubmit={false}
        submitBtnTitle={t("common.text.change")}
        headerTitle={t("modal.confirmation.headerTitle")}
        title={t("salesOrder.modal.changeDeliveryType.title")}
        subtitle={t("salesOrder.modal.changeDeliveryType.subtitle")}
      />

      <ModalAddDirectBatch
        show={showDirectAddBatchModal}
        onCancel={() => setShowDirectAddBatchModal(false)}
        onSave={(data) => {
          setTableData((s) => [...s, data]);
        }}
      />

      <ModalAddLoyaltyBatch
        show={showLoyaltyAddBatchModal}
        onCancel={() => setShowLoyaltyAddBatchModal(false)}
        onSave={(data) => {
          setTableDataReward((s) => [...s, data]);
        }}
      />

      {id && (
        <ModalUpdateInformation
          show={openModalEdit}
          id={id}
          onCancel={() => setOpenModalEdit(false)}
          onSave={(data) => {
            setData((prevState: any) => ({
              ...prevState,
              number: data.number,
              date: data.date,
            }));

            form.setFieldsValue({
              ...form.getFieldsValue(),
              number: data.number,
              date: data.date,
            });
          }}
          data={data}
        />
      )}

      <VuiModalImport
        show={showImportModal}
        onUpload={handleUploadTemplate}
        onCancel={() => setShowImportModal(false)}
        onDownload={handleDownloadTemplate}
        headerTitle="Import"
        maxFileOnMB={10}
      />
    </>
  );
};

export default AppOrderSalesOrderForm;
