import { Table, Tag } from "antd";
import Text from "antd/es/typography/Text";
import Card from "antd/lib/card";
import { Col, Row } from "antd/lib/grid";
import Space from "antd/lib/space";
import { AxiosError, AxiosResponse } from "axios";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { VuiActionTable } from "../../../../../@vodea/vodea-ui/components";
import VuiAccessible from "../../../../../@vodea/vodea-ui/components/Accessible";
import VuiContainer from "../../../../../@vodea/vodea-ui/components/Container";
import VuiContent from "../../../../../@vodea/vodea-ui/components/Content";
import VuiFormTitle from "../../../../../@vodea/vodea-ui/components/Form/Title";
import VuiSectionTitle from "../../../../../@vodea/vodea-ui/components/Sections/Title";
import {
  ACCESS_PERMISSION,
  DELIVERY_TYPE,
  PRODUCT_DELIVERY_STATUS_COLOR,
  SALES_ORDER_STATUS_COLOR,
} from "../../../../../constant";
import { handleBackendError } from "../../../../../functions/global";
import ProductDeliveryRepository from "../../../../../repositories/ProductDeliveryRepository";
import { IBreadcrumbs } from "../../../../../stores/breadcrumbs";
import { useAppDispatch, useAppSelector } from "../../../../../stores/hooks";
import { $clone, useBreadcrumbs, useIsMounted } from "../../../../../utils";
import _ from "lodash";

interface IAppOrderProductDeliveryView {
  breadcrumbs: IBreadcrumbs[];
}

const AppOrderProductDeliveryView: React.FC<IAppOrderProductDeliveryView> = ({
  breadcrumbs,
}) => {
  const isMounted = useIsMounted();
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();
  const title = t("productDelivery.title");
  const system = useAppSelector((state) => state.system);
  const [breadcrumbsState, setbreadcrumbsState] = React.useState<
    IBreadcrumbs[]
  >([]);
  useBreadcrumbs(breadcrumbsState);
  const [productDelivery, setProductDelivery] = React.useState<any>({});
  const productTableColumns = [
    {
      title: t("common.text.category"),
      key: "category",
      render: (text: any, record: any) => {
        return record.sales_order_item.product.operator.category.name;
      },
    },
    {
      title: t("common.text.product"),
      key: "product",
      render: (text: any, record: any) => {
        return record.sales_order_item.product.operator.name;
      },
    },
    {
      title: t("common.text.item"),
      key: "variant",
      render: (text: any, record: any) => {
        return record.sales_order_item.product.name;
      },
    },
    {
      title: t("common.text.order") + "/" + t("common.text.delivery"),
      key: "stock",
      render: (text: any, record: any) => {
        return `${record.sales_order_item.quantity}/${record.sales_order_item.total_delivered}`;
      },
    },
    {
      title: t("common.text.status"),
      dataIndex: "status_name",
      key: "status",
      render: (text: any) => {
        return (
          <Tag color={_.get(PRODUCT_DELIVERY_STATUS_COLOR, text)}>{text}</Tag>
        );
      },
    },
    {
      title: "",
      key: "action",
      render: (text: any, record: any) => (
        <VuiAccessible access={ACCESS_PERMISSION.orderDelivery.detailCode}>
          <VuiActionTable<any> detailLink={`${pathname}/reward/${record.id}`} />
        </VuiAccessible>
      ),
    },
  ];
  const giftTableColumns = [
    {
      title: t("common.text.category"),
      key: "category",
      render: (text: any, record: any) => {
        return record.sales_order_gift.product.operator.category.name;
      },
    },
    {
      title: t("common.text.product"),
      key: "product",
      render: (text: any, record: any) => {
        return record.sales_order_gift.product.operator.name;
      },
    },
    {
      title: t("common.text.variant"),
      key: "variant",
      render: (text: any, record: any) => {
        return record.sales_order_gift.product.name;
      },
    },
    {
      title: t("common.text.order") + "/" + t("common.text.delivery"),
      key: "stock",
      render: (text: any, record: any) => {
        return `${record.sales_order_gift.quantity}/${record.sales_order_gift.total_delivered}`;
      },
    },
    {
      title: t("common.text.status"),
      dataIndex: "status_name",
      key: "status",
      render: (text: any) => {
        return <Tag color="green">{text}</Tag>;
      },
    },
    {
      title: "",
      key: "action",
      render: (text: any, record: any) => (
        <VuiAccessible access={ACCESS_PERMISSION.orderDelivery.detailGift}>
          <VuiActionTable<any> detailLink={`${pathname}/gift/${record.id}`} />
        </VuiAccessible>
      ),
    },
  ];
  const rewardTableColumns = [
    {
      title: t("common.text.point"),
      key: "point",
      render: (text: any, record: any) => {
        return record.sales_order_item.point;
      },
    },

    {
      title: t("common.text.order") + "/" + t("common.text.delivery"),
      key: "stock",
      render: (text: any, record: any) => {
        return `${record.sales_order_item.quantity}/${record.sales_order_item.total_delivered}`;
      },
    },
    {
      title: t("common.text.status"),
      dataIndex: "status_name",
      key: "status",
      render: (text: any) => {
        return <Tag color="green">{text}</Tag>;
      },
    },
    {
      title: "",
      key: "action",
      render: (text: any, record: any) => (
        <VuiAccessible access={ACCESS_PERMISSION.orderDelivery.detailCode}>
          <VuiActionTable<any> detailLink={`${pathname}/reward/${record.id}`} />
        </VuiAccessible>
      ),
    },
  ];

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    overideBreadcrumbs();
  }, [productDelivery.number]);

  const loadData = () => {
    if (!id) {
      return;
    }

    ProductDeliveryRepository.findOne(id, {
      with: [
        "orderDeliveryItems.salesOrderItem.product.operator.category",
        "orderDeliveryGifts.salesOrderGift.product.operator.category",
      ],
    })
      .then((response: AxiosResponse) => {
        const { data: responseData } = response.data;
        setProductDelivery(responseData);
      })
      .catch((error: AxiosError) => {
        handleBackendError(error, t("notification.error.default"));
      });
  };

  const overideBreadcrumbs = () => {
    let breadcrumbClone = $clone(breadcrumbs);
    if (productDelivery.number) {
      const sliceBreadcrumbs = breadcrumbClone.slice(
        0,
        breadcrumbClone.length - 1
      );

      const lastBreadcrumbs = {
        ...breadcrumbClone[breadcrumbClone.length - 1],
        title: productDelivery.number,
      };

      sliceBreadcrumbs.push(lastBreadcrumbs);
      breadcrumbClone = sliceBreadcrumbs;
    }
    setbreadcrumbsState(breadcrumbClone);
  };

  const renderReward = () => {
    return (
      <Col
        className="gutter-row"
        md={{
          span: 24,
          order: 2,
        }}
        xs={{
          span: 24,
          order: 1,
        }}
      >
        <Card title={t("common.text.reward")}>
          <div className="mb24">
            <Table
              rowKey={"id"}
              bordered
              columns={rewardTableColumns}
              dataSource={productDelivery?.order_delivery_items || []}
              pagination={false}
            />
          </div>
          <div className="text-align-right mt24">
            <>
              <Text strong className="mr24">
                Total Reward
              </Text>
              <Text strong>{productDelivery.total_reward}</Text>
            </>
          </div>
          <div className="text-align-right mt12">
            <>
              <Text strong className="mr24">
                Total Qty
              </Text>
              <Text strong>{productDelivery.total_stock_reward}</Text>
            </>
          </div>
        </Card>
      </Col>
    );
  };

  const renderGift = () => {
    return (
      <Col
        className="gutter-row"
        md={{
          span: 24,
          order: 2,
        }}
        xs={{
          span: 24,
          order: 1,
        }}
      >
        <Card title={t("common.text.gift")}>
          <div className="mb24">
            <Table
              rowKey={"id"}
              bordered
              columns={giftTableColumns}
              dataSource={productDelivery?.order_delivery_gifts || []}
              pagination={false}
            />
          </div>
          <div className="text-align-right mt24">
            <>
              <Text strong className="mr24">
                Total Item
              </Text>
              <Text strong>{productDelivery.total_gift}</Text>
            </>
          </div>
          <div className="text-align-right mt12">
            <>
              <Text strong className="mr24">
                Total Qty
              </Text>
              <Text strong>{productDelivery.total_stock_gift}</Text>
            </>
          </div>
        </Card>
      </Col>
    );
  };

  const renderProduct = () => {
    return (
      <Col
        className="gutter-row"
        md={{
          span: 24,
          order: 2,
        }}
        xs={{
          span: 24,
          order: 1,
        }}
      >
        <Card title={t("common.text.product")}>
          <div className="mb24">
            <Table
              rowKey={"id"}
              bordered
              columns={productTableColumns}
              dataSource={productDelivery?.order_delivery_items || []}
              pagination={false}
            />
          </div>
          <div className="text-align-right mt24">
            <>
              <Text strong className="mr24">
                Total Item
              </Text>
              <Text strong>{productDelivery.total_reward}</Text>
            </>
          </div>
          <div className="text-align-right mt12">
            <>
              <Text strong className="mr24">
                Total Qty
              </Text>
              <Text strong>{productDelivery.total_stock_reward}</Text>
            </>
          </div>
        </Card>
      </Col>
    );
  };

  return (
    <>
      <VuiContainer>
        <VuiSectionTitle
          onBack={() => navigate(-1)}
          title={`${title} | ${productDelivery.number || ""}`}
        />

        <VuiContent>
          <Row gutter={[16, 16]}>
            <Col
              className="gutter-row"
              md={{
                span: 24,
                order: 1,
              }}
              xs={{
                span: 24,
                order: 2,
              }}
            >
              <Card title={t("common.text.itemInformation")}>
                <Space
                  style={{
                    width: "100%",
                  }}
                  direction="vertical"
                  size={5}
                >
                  <Row gutter={96}>
                    <Col>
                      <VuiFormTitle title={t("common.text.company")} />
                      <Text>
                        <b>{productDelivery.company_name}</b>
                      </Text>
                    </Col>
                    <Col>
                      <VuiFormTitle title={t("common.text.orderNumber")} />
                      <Text>
                        <b>{productDelivery.number}</b>
                      </Text>
                    </Col>
                    <Col>
                      <VuiFormTitle title={t("common.text.deliveryType")} />
                      <Text>
                        <b>{productDelivery.delivery_type}</b>
                      </Text>
                    </Col>
                  </Row>
                </Space>
              </Card>
            </Col>
            {productDelivery?.delivery_type === DELIVERY_TYPE.loyalty &&
              renderReward()}
            {productDelivery?.delivery_type === DELIVERY_TYPE.loyalty &&
              renderGift()}
            {productDelivery?.delivery_type === DELIVERY_TYPE.direct &&
              renderProduct()}
          </Row>
        </VuiContent>
      </VuiContainer>
    </>
  );
};

export default AppOrderProductDeliveryView;
