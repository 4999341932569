import AppLayout from "../layouts/App";
import AppDashboard from "../pages/App/Dashboard";
import { Navigate } from "react-router-dom";
import { breadcrumbs } from "../constant/breadcrumbs";
import AppSettingAccountUserForm from "../pages/App/Setting/Account/User/Form";
import AppSettingAccountUserList from "../pages/App/Setting/Account/User/List";
import AppSettingAccountAccessRoleList from "../pages/App/Setting/Account/AccessRole/List";
import AppSettingAccountAccessRoleForm from "../pages/App/Setting/Account/AccessRole/Form";
import AppSettingAccountCompanyList from "../pages/App/Setting/Account/Company/List";
import AppSettingAccountCompanyForm from "../pages/App/Setting/Account/Company/Form";
import AppSettingProductList from "../pages/App/Setting/Product/List";
import AppAccountProfile from "../pages/App/Account/Profile";
import AppAccountChangePassword from "../pages/App/Account/ChangePassword";
import AppOrderSalesOrderList from "../pages/App/Order/SalesOrder/List";
import AppOrderSalesOrderForm from "../pages/App/Order/SalesOrder/Form";
import AppOrderSalesOrderBatchView from "../pages/App/Order/SalesOrder/View/Batch";
import AppOrderSalesOrderRewardView from "../pages/App/Order/SalesOrder/View/Reward";
import AppOrderProductDeliveryList from "../pages/App/Order/ProductDelivery/List";
import AppOrderProductDeliveryView from "../pages/App/Order/ProductDelivery/View";
import AppOrderProductDeliveryRewardView from "../pages/App/Order/ProductDelivery/View/Reward";
import AppOrderProductDeliveryGiftView from "../pages/App/Order/ProductDelivery/View/Gift";

const appRoutes = [
  {
    path: "/",
    element: <AppLayout />,
    children: [
      { path: "/", element: <Navigate to={"/dashboard"} /> },
      {
        path: "/dashboard",
        element: <AppDashboard breadcrumbs={breadcrumbs.dashboard.index} />,
      },
      // {
      //   path: "/report",
      //   element: <AppReport breadcrumbs={breadcrumbs.report.index} />,
      // },

      {
        path: "/order",
        children: [
          { path: "/", element: <Navigate to={"/order/sales-order"} /> },
          {
            path: "/sales-order",
            children: [
              {
                path: "/",
                element: (
                  <AppOrderSalesOrderList
                    breadcrumbs={breadcrumbs.orderSalesOrder.index}
                  />
                ),
              },
              {
                path: "/add",
                element: (
                  <AppOrderSalesOrderForm
                    breadcrumbs={breadcrumbs.orderSalesOrder.add}
                  />
                ),
              },
              {
                path: "/:id",
                children: [
                  {
                    path: "/",
                    element: (
                      <AppOrderSalesOrderForm
                        breadcrumbs={breadcrumbs.orderSalesOrder.detail}
                      />
                    ),
                  },
                  {
                    path: "/batch/:id",
                    element: (
                      <AppOrderSalesOrderBatchView
                        breadcrumbs={breadcrumbs.orderSalesOrder.batch}
                      />
                    ),
                  },
                  {
                    path: "/reward/:id",
                    element: (
                      <AppOrderSalesOrderRewardView
                        breadcrumbs={breadcrumbs.orderSalesOrder.reward}
                      />
                    ),
                  },
                ],
              },
            ],
          },
          {
            path: "/product-delivery",
            children: [
              {
                path: "/",
                element: (
                  <AppOrderProductDeliveryList
                    breadcrumbs={breadcrumbs.orderProductDelivery.index}
                  />
                ),
              },
              {
                path: "/:id",
                children: [
                  {
                    path: "/",
                    element: (
                      <AppOrderProductDeliveryView
                        breadcrumbs={breadcrumbs.orderProductDelivery.detail}
                      />
                    ),
                  },
                  {
                    path: "/reward/:id",
                    element: (
                      <AppOrderProductDeliveryRewardView
                        breadcrumbs={breadcrumbs.orderProductDelivery.reward}
                      />
                    ),
                  },
                  {
                    path: "/gift/:id",
                    element: (
                      <AppOrderProductDeliveryGiftView
                        breadcrumbs={breadcrumbs.orderProductDelivery.gift}
                      />
                    ),
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: "/account",
        children: [
          { path: "/", element: <Navigate to={"/account/profile"} /> },
          {
            path: "/profile",
            element: (
              <AppAccountProfile
                breadcrumbs={breadcrumbs.accountProfile.index}
              />
            ),
          },
          {
            path: "/change-password",
            element: (
              <AppAccountChangePassword
                breadcrumbs={breadcrumbs.accountChangePassword.index}
              />
            ),
          },
        ],
      },

      {
        path: "/setting",
        children: [
          { path: "/", element: <Navigate to={"/setting/account"} /> },
          {
            path: "/account",
            children: [
              {
                path: "/",
                element: <Navigate to={"/setting/account/company"} />,
              },
              {
                path: "/company",
                children: [
                  {
                    path: "/",
                    element: (
                      <AppSettingAccountCompanyList
                        breadcrumbs={breadcrumbs.settingAccountCompany.index}
                      />
                    ),
                  },
                  {
                    path: "/add",
                    element: (
                      <AppSettingAccountCompanyForm
                        breadcrumbs={breadcrumbs.settingAccountCompany.add}
                      />
                    ),
                  },
                  {
                    path: "/:id",
                    element: (
                      <AppSettingAccountCompanyForm
                        breadcrumbs={breadcrumbs.settingAccountCompany.update}
                      />
                    ),
                  },
                ],
              },
              {
                path: "/user",
                children: [
                  {
                    path: "/",
                    element: (
                      <AppSettingAccountUserList
                        breadcrumbs={breadcrumbs.settingAccountUser.index}
                      />
                    ),
                  },
                  {
                    path: "/add",
                    element: (
                      <AppSettingAccountUserForm
                        breadcrumbs={breadcrumbs.settingAccountUser.add}
                      />
                    ),
                  },
                  {
                    path: "/:id",
                    element: (
                      <AppSettingAccountUserForm
                        breadcrumbs={breadcrumbs.settingAccountUser.update}
                      />
                    ),
                  },
                ],
              },
              {
                path: "/access-role",
                children: [
                  {
                    path: "/",
                    element: (
                      <AppSettingAccountAccessRoleList
                        breadcrumbs={breadcrumbs.settingAccountAccessRole.index}
                      />
                    ),
                  },
                  {
                    path: "/add",
                    element: (
                      <AppSettingAccountAccessRoleForm
                        breadcrumbs={breadcrumbs.settingAccountAccessRole.add}
                      />
                    ),
                  },
                  {
                    path: "/:id",
                    element: (
                      <AppSettingAccountAccessRoleForm
                        breadcrumbs={
                          breadcrumbs.settingAccountAccessRole.update
                        }
                      />
                    ),
                  },
                ],
              },
            ],
          },
          {
            path: "/product",
            element: (
              <AppSettingProductList
                breadcrumbs={breadcrumbs.settingProduct.index}
              />
            ),
          },
        ],
      },
    ],
  },
];

export default appRoutes;
