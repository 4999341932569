import React from "react";
import Button, { ButtonProps } from "antd/es/button";
import Spin from "antd/lib/spin";

interface IVuiButton {
  loading?: boolean;
  label: string;
  buttonProps: ButtonProps;
}

const VuiButton = ({
  loading = false,
  buttonProps,
  label = "",
}: IVuiButton) => {
  const {
    htmlType = "button",
    type = "primary",
    block = true,
    ...other
  } = buttonProps;

  return (
    <Spin spinning={loading}>
      <Button htmlType={htmlType} type={type} block={block} {...other}>
        {label}
      </Button>
    </Spin>
  );
};

export default VuiButton;
