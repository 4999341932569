import NumberFormat, { NumberFormatProps } from "react-number-format";

const VuiNumberFormat = (props: NumberFormatProps) => {
  const {
    displayType = "text",
    thousandSeparator = ".",
    decimalSeparator = ",",
    decimalScale = 0,
    ...other
  } = props;

  return (
    <NumberFormat
      thousandSeparator={thousandSeparator}
      decimalSeparator={decimalSeparator}
      decimalScale={decimalScale}
      displayType={displayType}
      {...other}
    />
  );
};

export default VuiNumberFormat;
