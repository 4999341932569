import React, { FC } from "react";
import { haveAccess, useAccess } from "../../../../utils";

interface Props {
  access: string | string[];
}

const VuiAccessible: FC<Props> = ({ access, children }) => {
  const acl = useAccess();

  if (Boolean(access)) {
    if (!haveAccess(acl, access)) {
      return null;
    }
  }

  return <>{children}</>;
};

export default VuiAccessible;
