import { IBreadcrumbs, updateBreadcrumbs } from "../../stores/breadcrumbs";
import { useEffect } from "react";
import { useAppDispatch } from "../../stores/hooks";
import { useTranslation } from "react-i18next";

export const useBreadcrumbs = (breadcrumbs: IBreadcrumbs[]) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    const overideBreadcrumbs = breadcrumbs.map((item) => {
      return {
        ...item,
        title: t(item.title),
      };
    });
    dispatch(updateBreadcrumbs(overideBreadcrumbs));
  }, [dispatch, breadcrumbs]);
};
