import { api, createCancelTokenHandler } from "../utils";

const endPoint = () => window._env_.REACT_APP_PASSPORT_END_POINT;

const Repository = {
  loyalty: function (params: any = null) {
    return api.get(`${endPoint()}/api/import-template/sales-order-loyalty`, {
      params,
      responseType: "blob",
      cancelToken:
        cancelTokenHandlerObject[this.loyalty.name].handleRequestCancellation()
          .token,
    });
  },
  direct: function (params: any = null) {
    return api.get(`${endPoint()}/api/import-template/sales-order-item`, {
      params,
      responseType: "blob",
      cancelToken:
        cancelTokenHandlerObject[this.direct.name].handleRequestCancellation()
          .token,
    });
  },
};

const cancelTokenHandlerObject = createCancelTokenHandler(Repository);

export default Repository;
