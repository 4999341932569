import Button from "antd/es/button";
import Col from "antd/es/grid/col";
import Row from "antd/es/grid/row";
import Table from "antd/es/table";
import { ColumnType } from "antd/es/table/interface";
import { AxiosError, AxiosResponse } from "axios";
import { FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { VuiSearch } from "../../../../../@vodea/vodea-ui/components";
import VuiAccessible from "../../../../../@vodea/vodea-ui/components/Accessible";
import VuiContainer from "../../../../../@vodea/vodea-ui/components/Container";
import VuiContent from "../../../../../@vodea/vodea-ui/components/Content";
import VuiSectionTitle from "../../../../../@vodea/vodea-ui/components/Sections/Title";
import { ACCESS_PERMISSION } from "../../../../../constant";
import { handleBackendError } from "../../../../../functions/global";
import ProductDeliveryRepository from "../../../../../repositories/ProductDeliveryRepository";
import { IBreadcrumbs } from "../../../../../stores/breadcrumbs";
import {
  useBreadcrumbs,
  useDebounce,
  useIsMounted,
} from "../../../../../utils";

var fileDownload = require("js-file-download");

const qs = require("qs");

interface IParams {
  search: string | null;
}

interface IAppOrderProductDeliveryGiftView {
  breadcrumbs: IBreadcrumbs[];
}

const AppOrderProductDeliveryGiftView: FC<IAppOrderProductDeliveryGiftView> = ({
  breadcrumbs,
}) => {
  useBreadcrumbs(breadcrumbs);
  const { id } = useParams();
  const { t } = useTranslation();
  const isMounted = useIsMounted();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [title, setTitle] = useState<string>(
    t("common.text.detailItem", {
      item: t("common.text.gift"),
    })
  );
  const [search, setSearch] = useState<string>(
    searchParams.get("search") || ""
  );
  const debouncedSearch = useDebounce<string>(search, 500);
  const [tableLoading, setTableLoading] = useState<boolean>(false);
  const [tableData, setTableData] = useState<any[]>([]);

  const tableColumns: ColumnType<any>[] = [
    {
      title: t("common.text.name"),
      dataIndex: "name",
      key: "name",
    },
    {
      title: t("common.text.phoneNumber"),
      dataIndex: "phone_number",
      key: "phone_number",
    },
    {
      title: t("common.text.email"),
      dataIndex: "email",
      key: "email",
    },
  ];

  const loadData = () => {
    if (!isMounted) {
      return;
    }

    setTableLoading(true);

    const params: IParams = {
      search: searchParams.get("search"),
    };

    ProductDeliveryRepository.detailGift(id, params)
      .then((response: AxiosResponse) => {
        const { data: responseData } = response.data;
        const productName = response.data.product_name;
        if (productName) {
          setTitle(productName);
        }
        setTableData(responseData);
        setTableLoading(false);
      })
      .catch((error: AxiosError) => {
        handleBackendError(error, t("notification.error.default"));
        setTableLoading(false);
      });
  };

  const setQueryParams = useCallback(() => {
    const params: Partial<IParams> = {};

    Object.assign(params, {
      ...(debouncedSearch ? { search: debouncedSearch } : {}),
    });

    const queryParams = qs.stringify(params, { indices: false });

    if (queryParams) {
      setSearchParams(`?${queryParams}`);
    } else {
      navigate("", { replace: true });
    }
  }, [setSearchParams, navigate, debouncedSearch]);

  // Lifecycle
  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  useEffect(() => {
    isMounted ? setQueryParams() : setSearchParams("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearch, isMounted]);

  const handleExport = async () => {
    try {
      let res = await ProductDeliveryRepository.export(id);
      await fileDownload(res.data, `${Date.now()} - ${title} .xlsx`);
    } catch (err: any) {
      console.log(err, "err");
      handleBackendError(err);
    }
  };

  return (
    <>
      <VuiContainer>
        <VuiSectionTitle onBack={() => navigate(-1)} title={title}>
          <VuiAccessible access={ACCESS_PERMISSION.orderDelivery.excel}>
            <Button onClick={handleExport} type="primary">
              {t("common.button.export")}
            </Button>
          </VuiAccessible>
        </VuiSectionTitle>

        <VuiContent>
          <Row gutter={[10, 10]} className="mb24">
            <Col className="gutter-row" span={6} xs={24} md={12} lg={10}>
              <VuiSearch
                allowClear
                defaultValue={search}
                onSearch={(value: any) => setSearch(value)}
              />
            </Col>
          </Row>

          <Table
            rowKey={"id"}
            bordered
            columns={tableColumns}
            dataSource={tableData}
            loading={tableLoading}
            pagination={false}
          />
        </VuiContent>
      </VuiContainer>
    </>
  );
};

export default AppOrderProductDeliveryGiftView;
