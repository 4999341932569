import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface FormatterState {
  date: string;
  datetime: string;
  dateDay: string;
}

const initialState: FormatterState = {
  date: "DD MMMM YYYY",
  datetime: "DD MMMM YYYY | HH:mm",
  dateDay: "dddd, DD MMMM YYYY",
};

export const formatterSlice = createSlice({
  name: "formatter",
  initialState,
  reducers: {
    changeDate: (state, action: PayloadAction<FormatterState["date"]>) => {
      Object.assign(state.date, action.payload);
    },
    changeDateTime: (
      state,
      action: PayloadAction<FormatterState["datetime"]>
    ) => {
      Object.assign(state.datetime, action.payload);
    },
    changeDateDay: (
      state,
      action: PayloadAction<FormatterState["dateDay"]>
    ) => {
      Object.assign(state.dateDay, action.payload);
    },
  },
});

export const { changeDate, changeDateTime, changeDateDay } =
  formatterSlice.actions;
export default formatterSlice.reducer;
