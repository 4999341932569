import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import VuiContainer from "../../../../../../@vodea/vodea-ui/components/Container";
import Button from "antd/es/button";
import { PlusOutlined } from "@ant-design/icons";
import VuiSectionTitle from "../../../../../../@vodea/vodea-ui/components/Sections/Title";
import Col from "antd/es/grid/col";
import Row from "antd/es/grid/row";
import Table from "antd/es/table";
import CompanyRepository from "../../../../../../repositories/CompanyRepository";
import { AxiosError, AxiosResponse } from "axios";
import VuiModalConfirmation from "../../../../../../@vodea/vodea-ui/components/Modal/Confirmation";
import { IBreadcrumbs } from "../../../../../../stores/breadcrumbs";
import {
  VuiActionTable,
  VuiSearch,
} from "../../../../../../@vodea/vodea-ui/components";
import {
  ConstantService,
  ITableQuery,
  openNotification,
  UrlParamsType,
  useBreadcrumbs,
  useDebounce,
  useIsMounted,
  ICollection,
} from "../../../../../../utils";
import { ColumnType } from "antd/es/table/interface";
import VuiContent from "../../../../../../@vodea/vodea-ui/components/Content";
import VuiSelectSingle from "../../../../../../@vodea/vodea-ui/components/Select/Single";
import { User, UserStatus } from "../../../../../../models/User";
import UserRepository from "../../../../../../repositories/UserRepository";
import ConstantRepository from "../../../../../../repositories/ConstantRepository";
import { recentFilterData } from "../../../../../../functions/global";
import {
  ACCESS_PERMISSION,
  RECENT_FILTER_KEY,
} from "../../../../../../constant";
import VuiAccessible from "../../../../../../@vodea/vodea-ui/components/Accessible";
import { Tag } from "antd";

const qs = require("qs");

interface IParams extends UrlParamsType {
  type: string;
}

interface IAppSettingAccountUserList {
  breadcrumbs: IBreadcrumbs[];
}

const AppSettingAccountUserList: FC<IAppSettingAccountUserList> = ({
  breadcrumbs,
}) => {
  useBreadcrumbs(breadcrumbs);
  const { t } = useTranslation();
  const hasInitialize = useRef(false);
  const isMounted = useIsMounted();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [selectedDeleteId, setSelectedDeleteId] = useState<number>(0);
  const [selectedType, setSelectedType] = useState<any>(
    searchParams.get("type")
      ? recentFilterData.getSelectedFilter(RECENT_FILTER_KEY.userType, [
          Number(searchParams.get("type")),
        ])[0]
      : null
  );
  const [search, setSearch] = useState<string>(
    searchParams.get("search") || ""
  );
  const debouncedSearch = useDebounce<string>(search, 250);
  const [tableQuery, setTableQuery] = useState<ITableQuery>({
    page: Number(
      searchParams.get("page") || ConstantService.getConstant("DEFAULT_PAGE")
    ),
    perPage: Number(
      searchParams.get("perPage") ||
        ConstantService.getConstant("DEFAULT_PER_PAGE")
    ),
    sortField: searchParams.get("sortField") || null,
    sortOrder: searchParams.get("sortOrder") || null,
  });
  const [tableLoading, setTableLoading] = useState<boolean>(false);
  const [tableData, setTableData] = useState<User[]>([]);
  const [totalData, setTotalData] = useState<number>(0);
  const tableColumns: ColumnType<User>[] = [
    {
      title: t("common.text.ID"),
      dataIndex: "number",
      key: "number",
    },
    {
      title: t("common.text.name"),
      dataIndex: "name",
      key: "name",
    },
    {
      title: t("common.text.role"),
      dataIndex: "role_name",
      key: "role_name",
    },
    {
      title: t("common.text.status"),
      dataIndex: "status",
      key: "status",
      render: (status: UserStatus) => {
        return (
          <Tag color={status === "Active" ? "blue" : "yellow"}>{status}</Tag>
        );
      },
    },
    {
      title: "",
      key: "action",
      render: (text: any, record: User) => (
        <VuiAccessible access={ACCESS_PERMISSION.user.show}>
          <VuiActionTable<User> detailLink={`${pathname}/${record.id}`} />
        </VuiAccessible>
      ),
    },
  ];

  const setDeleteModal = useCallback((id: number) => {
    setSelectedDeleteId(id);
    setShowDeleteModal(true);
  }, []);

  const deleteModalCallback = useCallback(async () => {
    if (selectedDeleteId) {
      // await BrandRepository.delete(selectedDeleteId)
      //   .then((response: AxiosResponse) => {
      //     setShowDeleteModal(false);
      //     setSelectedDeleteId(0);
      //     openNotification(
      //       "success",
      //       t("notification.success.deleteItem", { item: "Territory" })
      //     );
      //     // getTableData(true)
      //   })
      //   .catch((e: AxiosError) => {
      //     setShowDeleteModal(false);
      //     setSelectedDeleteId(0);
      //     openNotification("error", t("notification.error.default"));
      //   });
    }
  }, [t, selectedDeleteId]);

  const loadData = useCallback(
    async (searchParams) => {
      if (!isMounted || !searchParams.get("page")) {
        return;
      }

      setTableLoading(true);

      const params: IParams = {
        search: searchParams.get("search"),
        page: Number(searchParams.get("page")),
        per_page: Number(searchParams.get("perPage")),
        order_by: searchParams.get("sortField"),
        sorted_by: searchParams.get("sortOrder"),
        type: searchParams.get("type"),
      };

      await UserRepository.findAll(params)
        .then((response: AxiosResponse<ICollection<User[]>>) => {
          if (isMounted) {
            const { data: responseData, meta } = response.data;
            setTableLoading(false);
            setTableData(responseData);
            setTotalData(meta.total);
          }
        })
        .catch((error: AxiosError) => {
          if (isMounted) {
            setTableLoading(false);
          }
        });

      hasInitialize.current = true;
    },
    [isMounted]
  );

  const handleTableChange = useCallback(
    (pagination: any, filters: any, sorter: any, extra: any) => {
      setTableQuery((prevState) => ({
        ...prevState,
        page: pagination.current,
        perPage: pagination.pageSize,
        ...(sorter.order ? { sortOrder: sorter.order } : {}),
        ...(sorter.field ? { sortField: sorter.field } : {}),
      }));
    },
    []
  );

  const setQueryParams = useCallback(() => {
    const params: Partial<IParams> = {};

    Object.assign(params, {
      ...(debouncedSearch ? { search: debouncedSearch } : {}),
      ...(tableQuery.page ? { page: tableQuery.page } : {}),
      ...(tableQuery.perPage ? { perPage: tableQuery.perPage } : {}),
      ...(tableQuery.sortField ? { sortField: tableQuery.sortField } : {}),
      ...(tableQuery.sortOrder ? { sortOrder: tableQuery.sortOrder } : {}),
      ...(selectedType ? { type: selectedType.value } : {}),
    });

    const queryParams = qs.stringify(params, { indices: false });

    if (queryParams) {
      setSearchParams(`?${queryParams}`);
    } else {
      navigate("");
    }
  }, [setSearchParams, navigate, debouncedSearch, tableQuery, selectedType]);

  const handleReset = useCallback(() => {
    setSearch("");
    setSelectedType(null);
  }, []);

  useMemo(() => {
    (async () => {
      await loadData(searchParams);
    })();
  }, [searchParams]);

  useEffect(() => {
    isMounted ? setQueryParams() : setSearchParams("");
  }, [isMounted, tableQuery]);

  useEffect(() => {
    if (isMounted && hasInitialize.current) {
      setTableQuery((s) => ({
        ...s,
        page: Number(ConstantService.getConstant("DEFAULT_PAGE")),
      }));
    }
  }, [debouncedSearch, selectedType]);

  return (
    <>
      <VuiContainer>
        <VuiSectionTitle title={t("common.text.user")}>
          <VuiAccessible access={ACCESS_PERMISSION.user.store}>
            <Button
              onClick={() => navigate(`${pathname}/add`)}
              type="primary"
              icon={<PlusOutlined />}
            >
              {t("common.button.add")}
            </Button>
          </VuiAccessible>
        </VuiSectionTitle>

        <VuiContent>
          <Row gutter={[10, 10]} className="mb24">
            <Col className="gutter-row" span={6} xs={24} md={12} lg={10}>
              <VuiSearch
                allowClear
                value={search}
                onChange={(event) => setSearch(event.target.value)}
              />
            </Col>
          </Row>

          <Row gutter={[10, 10]} className="mb16">
            <Col className="gutter-row" xs={24} md={3}>
              <VuiSelectSingle
                value={selectedType}
                onChange={(value: any) => {
                  setSelectedType(value);
                  recentFilterData.save(RECENT_FILTER_KEY.userType, [value]);
                }}
                labelKey="label"
                repository={ConstantRepository}
                selectParams={{
                  for: "user_type",
                }}
                placeholder={t("common.text.allTypes")}
              />
            </Col>
            <Col>
              <Button type="link" onClick={handleReset}>
                Reset
              </Button>
            </Col>
          </Row>

          <Table
            rowKey={"id"}
            bordered
            columns={tableColumns}
            dataSource={tableData}
            loading={tableLoading}
            onChange={handleTableChange}
            pagination={{
              current: tableQuery.page,
              pageSize: tableQuery.perPage,
              total: totalData,
            }}
          />
        </VuiContent>
      </VuiContainer>

      <VuiModalConfirmation
        show={showDeleteModal}
        onOk={deleteModalCallback}
        onCancel={() => setShowDeleteModal(false)}
      />
    </>
  );
};

export default AppSettingAccountUserList;
