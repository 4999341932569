import "./style.less";
import React from "react";
import Card from "antd/es/card";
import { ArrowDownOutlined, ArrowUpOutlined } from "@ant-design/icons";
import clsx from "clsx";
import { Skeleton } from "antd";
import { measureTextWidth, Pie } from "@ant-design/plots";
import { PieConfig } from "@ant-design/plots/es/components/pie";

export declare type type = "up" | "down";

interface Props {
  title: string;
  data: any[];
  percentage: string;
  type: type;
  comparison: string;
  loading?: boolean;
}

const VuiCardPieChart: React.FC<Props> = ({
  title,
  data,
  percentage,
  loading,
  type,
  comparison,
}) => {
  function renderStatistic(containerWidth: any, text: any, style: any) {
    const { width: textWidth, height: textHeight } = measureTextWidth(
      text,
      style
    );
    const R = containerWidth / 2;

    let scale = 1;

    if (containerWidth < textWidth) {
      scale = Math.min(
        Math.sqrt(
          Math.abs(
            Math.pow(R, 2) /
              (Math.pow(textWidth / 2, 2) + Math.pow(textHeight, 2))
          )
        ),
        1
      );
    }

    const textStyleStr = `width:${containerWidth}px;`;
    return `<div style="${textStyleStr};font-size:${scale}em;line-height:${
      scale < 1 ? 1 : "inherit"
    };">${text}</div>`;
  }

  const config: PieConfig = {
    appendPadding: 10,
    data: data,
    angleField: "value",
    colorField: "type",
    radius: 1,
    innerRadius: 0.64,
    height: 300,
    legend: {
      offsetX: -10,
      maxItemWidth: 115,
    },
    label: {
      type: "inner",
      offset: "-50%",
      style: {
        textAlign: "center",
      },
      autoRotate: false,
      content: "{value}",
    },
    statistic: {
      title: {
        offsetY: -4,
        customHtml: (container, view) => {
          const { width, height } = container.getBoundingClientRect();
          const d = Math.sqrt(Math.pow(width / 2, 2) + Math.pow(height / 2, 2));
          return renderStatistic(d, "Qty", {
            fontSize: 28,
          });
        },
      },
      content: {
        offsetY: 4,
        style: {
          fontSize: "32px",
        },
        customHtml: (container, view, datum, data: any) => {
          const { width } = container.getBoundingClientRect();
          const text = datum
            ? `${datum.value}`
            : `${data.reduce((r: any, d: any) => r + d.value, 0)}`;
          return renderStatistic(width, text, {
            fontSize: 32,
          });
        },
      },
    },
    interactions: [
      {
        type: "element-selected",
      },
      {
        type: "element-active",
      },
      {
        type: "pie-statistic-active",
      },
    ],
  };

  return (
    <Card className="vui-table-card">
      <div className="info-wrapper">
        <Skeleton
          loading={loading}
          active
          paragraph={{ rows: 4, width: "100%" }}
        >
          <p className="title mb8">{title}</p>
          <div className="value-wrapper mb16">
            <label className="info-value">
              {comparison}{" "}
              <span
                className={clsx("percentage", {
                  up: type === "up",
                  down: type === "down",
                })}
              >
                {percentage}{" "}
                {type === "up" ? <ArrowUpOutlined /> : <ArrowDownOutlined />}
              </span>
            </label>
          </div>
          <div>
            <Pie {...config} />
          </div>
        </Skeleton>
      </div>
    </Card>
  );
};

export default VuiCardPieChart;
