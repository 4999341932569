import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import VuiContainer from "../../../../../../@vodea/vodea-ui/components/Container";
import { useTranslation } from "react-i18next";
import Input from "antd/es/input";
import { IBreadcrumbs } from "../../../../../../stores/breadcrumbs";
import {
  ConfigService,
  convertLabelInValue,
  formatFormData,
  handleSaveResponseError,
  handleSaveResponseSuccess,
  IResource,
  openNotification,
  useBreadcrumbs,
  useIsMounted,
} from "../../../../../../utils";
import { Button, Col, Form, Radio, Row, Space, Tag, Typography } from "antd";
import {
  VuiFormAction,
  VuiFormCard,
  VuiFormLayout,
  VuiFormTitle,
} from "../../../../../../@vodea/vodea-ui/components";
import VuiSectionTitle from "../../../../../../@vodea/vodea-ui/components/Sections/Title";
import { useNavigate, useParams } from "react-router-dom";
import VuiContent from "../../../../../../@vodea/vodea-ui/components/Content";
import VuiSelectSingle from "../../../../../../@vodea/vodea-ui/components/Select/Single";
import CompanyRepository from "../../../../../../repositories/CompanyRepository";
import VuiSelectMulti from "../../../../../../@vodea/vodea-ui/components/Select/Multi";
import { User, UserStatus } from "../../../../../../models/User";
import RoleRepository from "../../../../../../repositories/RoleRepository";
import UserRepository from "../../../../../../repositories/UserRepository";
import ConstantRepository from "../../../../../../repositories/ConstantRepository";
import { AxiosError, AxiosResponse } from "axios";
import { handleBackendError } from "../../../../../../functions/global";
import _ from "lodash";
import { ACCESS_PERMISSION } from "../../../../../../constant";

const appUrl = () => window._env_.REACT_APP_URL;

interface IAppSettingAccountUserForm {
  breadcrumbs: IBreadcrumbs[];
}

const AppSettingAccountUserForm: FC<IAppSettingAccountUserForm> = ({
  breadcrumbs,
}) => {
  useBreadcrumbs(breadcrumbs);
  const { id } = useParams();
  const { t } = useTranslation();
  const isMounted = useIsMounted();
  const navigate = useNavigate();

  //form
  const [form] = Form.useForm<User>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [accountTypeOptions, setAccountTypeOptions] = useState<any[]>([]);
  const selectedType = Form.useWatch("type_id", form);
  const [status, setStatus] = useState<UserStatus>("Active");
  const [isResendEmail, setIsResendEmail] = useState<boolean>(false);

  const loadData = useCallback(async () => {
    if (!id) {
      return;
    }

    await UserRepository.findOne(id, { with: ["type", "roles", "companies"] })
      .then((response: AxiosResponse<IResource<User>>) => {
        const { data: responseData } = response.data;
        const roleId =
          typeof responseData.roles !== "undefined"
            ? responseData.roles[0]
            : null;
        form.setFieldsValue({
          ...responseData,
          company_ids: _.isEmpty(responseData?.companies)
            ? []
            : convertLabelInValue(responseData.companies),
          role_id: convertLabelInValue(roleId) || null,
        });
        setStatus(responseData.status);
      })
      .catch((error: AxiosError) => {
        handleBackendError(error, t("notification.error.default"));
      });
  }, [id]);

  const getAccountTypeOptions = () => {
    ConstantRepository.select({ for: "user_type" })
      .then((res: AxiosResponse) => {
        const { data: responseData } = res.data;

        setAccountTypeOptions(responseData);
      })
      .catch((error: AxiosError) => {
        navigate(-1);
        handleBackendError(error, t("notification.error.default"));
      });
  };

  useMemo(() => {
    loadData();
  }, [id]);

  useEffect(() => {
    getAccountTypeOptions();
  }, []);

  const onFinish = useCallback(
    async (values: User) => {
      if (isMounted) setIsLoading(true);
      const formData = formatFormData(values);

      Object.assign(formData, {
        url: ConfigService.getConfig("REACT_APP_HOST_APP"),
      });

      const responseMessage = t(
        `notification.success.${id ? "updateItem" : "createItem"}`,
        {
          item: t("common.text.user"),
        }
      );

      if (id) {
        await UserRepository.update(id, formData)
          .then((response: AxiosResponse) => {
            if (isMounted) {
              setIsLoading(false);
              handleSaveResponseSuccess(response, responseMessage, navigate);
            }
          })
          .catch((error: AxiosError) => {
            if (isMounted) {
              setIsLoading(false);
              handleSaveResponseError(error);
            }
          });
      } else {
        await UserRepository.create(formData)
          .then((response: AxiosResponse) => {
            if (isMounted) {
              setIsLoading(false);
              handleSaveResponseSuccess(response, responseMessage, navigate);
            }
          })
          .catch((error: AxiosError) => {
            if (isMounted) {
              setIsLoading(false);
              handleSaveResponseError(error);
            }
          });
      }
    },
    [isMounted, id]
  );

  const handleResendEmail = () => {
    setIsResendEmail(true);
    const payload = {
      id,
      url: appUrl(),
    };
    UserRepository.resendEmail(payload)
      .then((res: AxiosResponse) => {
        handleSaveResponseSuccess(res, t("notification.success.resendEmail"));
        setIsResendEmail(false);
      })
      .catch((error: AxiosError) => {
        handleSaveResponseError(error);
        setIsResendEmail(false);
      });
  };

  return (
    <VuiContainer>
      <VuiSectionTitle
        onBack={() => navigate("/setting/account/user")}
        title={t(id ? "common.text.editItem" : "common.text.addItem", {
          item: t("common.text.user"),
        })}
      />

      <VuiContent>
        <VuiFormLayout form={form} onFinish={onFinish}>
          <Row>
            <Col xs={24} md={14}>
              <VuiFormCard>
                <Row gutter={16}>
                  <Col span={24}>
                    <Form.Item
                      rules={[
                        {
                          required: true,
                          message: t("validation.required", {
                            item: t("common.form.name.label"),
                          }),
                        },
                      ]}
                      name={"name"}
                      label={t("common.form.name.label")}
                    >
                      <Input placeholder={t("common.form.name.placeholder")} />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      rules={[
                        {
                          required: true,
                          message: t("validation.required", {
                            item: t("common.form.email.label"),
                          }),
                        },
                        {
                          type: "email",
                          message: t("validation.type.email", {
                            item: t("common.form.email.label"),
                          }),
                        },
                      ]}
                      name={"email"}
                      label={t("common.form.email.label")}
                    >
                      <Input placeholder={t("common.form.email.placeholder")} />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      rules={[
                        {
                          required: true,
                          message: t("validation.required", {
                            item: t("common.form.phoneNumber.label"),
                          }),
                        },
                        {
                          min: 11,
                          message: t("validation.type.min", {
                            item: t("common.form.phoneNumber.label"),
                            min: 11,
                          }),
                        },
                        {
                          max: 14,
                          message: t("validation.type.max", {
                            item: t("common.form.phoneNumber.label"),
                            max: 14,
                          }),
                        },
                      ]}
                      name={"phone_number"}
                      label={t("common.form.phoneNumber.label")}
                    >
                      <Input
                        placeholder={t("common.form.phoneNumber.placeholder")}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      label={t("common.form.accountType.label")}
                      name={"type_id"}
                      rules={[
                        {
                          required: true,
                          message: t("validation.required", {
                            item: t("common.form.accountType.label"),
                          }),
                        },
                      ]}
                    >
                      <Radio.Group
                        onChange={() => {
                          form.setFieldsValue({
                            role_id: null,
                          });
                        }}
                      >
                        {accountTypeOptions.map((item: any) => {
                          return (
                            <Radio key={item.id} value={item.id}>
                              {item.label}
                            </Radio>
                          );
                        })}
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="position"
                      label={t("common.form.position.label")}
                      rules={[
                        {
                          required: true,
                          message: t("validation.required", {
                            item: t("common.form.position.label"),
                          }),
                        },
                      ]}
                    >
                      <Input
                        placeholder={t("common.form.position.placeholder")}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name={"role_id"}
                      label={t("common.form.role.label")}
                      rules={[
                        {
                          required: true,
                          message: t("validation.required", {
                            item: t("common.form.role.label"),
                          }),
                        },
                      ]}
                    >
                      <VuiSelectSingle
                        disabled={!Boolean(selectedType)}
                        repository={RoleRepository}
                        selectParams={{ type: selectedType }}
                        placeholder={t("common.form.role.placeholder")}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      name={"company_ids"}
                      label={t("common.form.company.label")}
                      rules={[
                        {
                          required: true,
                          message: t("validation.required", {
                            item: t("common.form.company.label"),
                          }),
                        },
                      ]}
                    >
                      <VuiSelectMulti
                        placeholder={t("common.form.company.placeholder")}
                        repository={CompanyRepository}
                      />
                    </Form.Item>
                  </Col>
                  {id && (
                    <>
                      <Col span={24}>
                        <Space align="center" size="large">
                          <div>
                            <VuiFormTitle title={t("common.text.status")} />
                            <div>
                              <Tag
                                className="text-capitalize"
                                color={status === "Active" ? "green" : "yellow"}
                              >
                                {status}
                              </Tag>
                            </div>
                          </div>

                          {status === "Pending" && (
                            <Button
                              type="primary"
                              ghost
                              onClick={handleResendEmail}
                            >
                              {t("common.button.resendEmail")}
                            </Button>
                          )}
                        </Space>
                      </Col>
                    </>
                  )}
                </Row>
              </VuiFormCard>

              <VuiFormAction
                label={id ? "common.text.editItem" : "common.text.addItem"}
                labelOption={{ item: t("common.text.user") }}
                saveLabel={id ? "common.button.save" : "common.button.add"}
                isLoading={isLoading}
                permission={
                  id
                    ? ACCESS_PERMISSION.user.update
                    : ACCESS_PERMISSION.user.store
                }
              />
            </Col>
          </Row>
        </VuiFormLayout>
      </VuiContent>
    </VuiContainer>
  );
};

export default AppSettingAccountUserForm;
