import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import Button from "antd/es/button";
import Col from "antd/es/grid/col";
import Row from "antd/es/grid/row";
import Table from "antd/es/table";
import { ColumnType } from "antd/es/table/interface";
import { AxiosError, AxiosResponse } from "axios";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import {
  VuiActionTable,
  VuiSearch,
} from "../../../../../../@vodea/vodea-ui/components";
import VuiContainer from "../../../../../../@vodea/vodea-ui/components/Container";
import VuiContent from "../../../../../../@vodea/vodea-ui/components/Content";
import VuiModalConfirmation from "../../../../../../@vodea/vodea-ui/components/Modal/Confirmation";
import VuiSectionTitle from "../../../../../../@vodea/vodea-ui/components/Sections/Title";
import CompanyRepository from "../../../../../../repositories/CompanyRepository";
import RoleRepository from "../../../../../../repositories/RoleRepository";
import { IBreadcrumbs } from "../../../../../../stores/breadcrumbs";
import {
  ConstantService,
  ITableQuery,
  openNotification,
  UrlParamsType,
  useBreadcrumbs,
  useDebounce,
  useIsMounted,
} from "../../../../../../utils";
import VuiAccessible from "../../../../../../@vodea/vodea-ui/components/Accessible";
import { ACCESS_PERMISSION } from "../../../../../../constant";

const qs = require("qs");

interface IParams extends UrlParamsType {}

interface ITableData {
  id: number;
  name: string;
}

interface IAppSettingAccountAccessRoleList {
  breadcrumbs: IBreadcrumbs[];
}

const AppSettingAccountAccessRoleList: FC<IAppSettingAccountAccessRoleList> = ({
  breadcrumbs,
}) => {
  useBreadcrumbs(breadcrumbs);
  const { t } = useTranslation();
  const hasInitialize = useRef(false);
  const isMounted = useIsMounted();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [selectedDeleteId, setSelectedDeleteId] = useState<number>(0);

  const [search, setSearch] = useState<string>(
    searchParams.get("search") || ""
  );
  const debouncedSearch = useDebounce<string>(search, 500);
  const [tableQuery, setTableQuery] = useState<ITableQuery>({
    page: Number(
      searchParams.get("page") || ConstantService.getConstant("DEFAULT_PAGE")
    ),
    perPage: Number(
      searchParams.get("perPage") ||
        ConstantService.getConstant("DEFAULT_PER_PAGE")
    ),
    sortField: searchParams.get("sortField") || null,
    sortOrder: searchParams.get("sortOrder") || null,
  });
  const [tableLoading, setTableLoading] = useState<boolean>(false);
  const [tableData, setTableData] = useState<ITableData[]>([]);
  const [totalData, setTotalData] = useState<number>(0);
  const tableColumns: ColumnType<ITableData>[] = [
    {
      title: t("common.text.role"),
      dataIndex: "name",
      key: "name",
    },
    {
      title: "",
      key: "action",
      render: (text: any, record: ITableData) => (
        <VuiAccessible access={ACCESS_PERMISSION.role.show}>
          <VuiActionTable<ITableData> detailLink={`${pathname}/${record.id}`} />
        </VuiAccessible>
      ),
    },
  ];

  const setDeleteModal = useCallback((id: ITableData["id"]) => {
    setSelectedDeleteId(id);
    setShowDeleteModal(true);
  }, []);

  const deleteModalCallback = useCallback(async () => {
    if (selectedDeleteId) {
      // await CompanyRepository.delete(selectedDeleteId)
      //   .then((response: AxiosResponse) => {
      //     setShowDeleteModal(false);
      //     setSelectedDeleteId(0);
      //     openNotification(
      //       "success",
      //       t("notification.success.deleteItem", { item: "Territory" })
      //     );
      //     // getTableData(true)
      //   })
      //   .catch((e: AxiosError) => {
      //     setShowDeleteModal(false);
      //     setSelectedDeleteId(0);
      //     openNotification("error", t("notification.error.default"));
      //   });
    }
  }, [t, selectedDeleteId]);

  const loadData = useCallback(
    async (searchParams) => {
      if (!isMounted || !searchParams.get("page")) {
        return;
      }

      setTableLoading(true);

      const params: IParams = {
        search: searchParams.get("search"),
        page: Number(searchParams.get("page")),
        per_page: Number(searchParams.get("perPage")),
        order_by: searchParams.get("sortField"),
        sorted_by: searchParams.get("sortOrder"),
      };

      await RoleRepository.findAll(params)
        .then((response: AxiosResponse) => {
          if (isMounted) {
            const { data: responseData, meta } = response.data;
            setTableLoading(false);
            setTableData(responseData);
            setTotalData(meta.total);
          }
        })
        .catch((error: AxiosError) => {
          if (isMounted) {
            setTableLoading(false);
          }
        });

      hasInitialize.current = true;
    },
    [isMounted]
  );

  const handleTableChange = useCallback(
    (pagination: any, filters: any, sorter: any, extra: any) => {
      setTableQuery((prevState) => ({
        ...prevState,
        page: pagination.current,
        perPage: pagination.pageSize,
        ...(sorter.order ? { sortOrder: sorter.order } : {}),
        ...(sorter.field ? { sortField: sorter.field } : {}),
      }));
    },
    []
  );

  const setQueryParams = useCallback(() => {
    const params: Partial<IParams> = {};

    Object.assign(params, {
      ...(debouncedSearch ? { search: debouncedSearch } : {}),
      ...(tableQuery.page ? { page: tableQuery.page } : {}),
      ...(tableQuery.perPage ? { perPage: tableQuery.perPage } : {}),
      ...(tableQuery.sortField ? { sortField: tableQuery.sortField } : {}),
      ...(tableQuery.sortOrder ? { sortOrder: tableQuery.sortOrder } : {}),
    });

    const queryParams = qs.stringify(params, { indices: false });

    if (queryParams) {
      setSearchParams(`?${queryParams}`);
    } else {
      navigate("");
    }
  }, [setSearchParams, navigate, debouncedSearch, tableQuery]);

  // Lifecycle
  useEffect(() => {
    (async () => {
      await loadData(searchParams);
    })();
  }, [searchParams]);

  useEffect(() => {
    isMounted ? setQueryParams() : setSearchParams("");
  }, [isMounted, tableQuery]);

  useEffect(() => {
    if (isMounted && hasInitialize.current) {
      setTableQuery((s) => ({
        ...s,
        page: Number(ConstantService.getConstant("DEFAULT_PAGE")),
      }));
    }
  }, [debouncedSearch]);

  return (
    <>
      <VuiContainer>
        <VuiSectionTitle title={t("common.text.accessRole")}>
          <VuiAccessible access={ACCESS_PERMISSION.role.store}>
            <Button
              onClick={() => navigate(`${pathname}/add`)}
              type="primary"
              icon={<PlusOutlined />}
            >
              {t("common.button.add")}
            </Button>
          </VuiAccessible>
        </VuiSectionTitle>

        <VuiContent>
          <Row gutter={[10, 10]} className="mb24">
            <Col className="gutter-row" span={6} xs={24} md={12} lg={10}>
              <VuiSearch
                allowClear
                defaultValue={search}
                onSearch={(value) => setSearch(value)}
              />
            </Col>
          </Row>

          <Table
            rowKey={"id"}
            bordered
            columns={tableColumns}
            dataSource={tableData}
            loading={tableLoading}
            onChange={handleTableChange}
            pagination={
              tableData.length > 0
                ? {
                    current: tableQuery.page,
                    pageSize: tableQuery.perPage,
                    total: totalData,
                  }
                : false
            }
          />
        </VuiContent>
      </VuiContainer>

      <VuiModalConfirmation
        show={showDeleteModal}
        onOk={deleteModalCallback}
        onCancel={() => setShowDeleteModal(false)}
      />
    </>
  );
};

export default AppSettingAccountAccessRoleList;
