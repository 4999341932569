export interface IKey {
  DEFAULT_PER_PAGE: number;
  DEFAULT_PAGE: number;
  PRIMARY_COLOR: string;
  WHITE_COLOR: string;
  ERROR_COLOR: string;
  WARNING_COLOR: string;
  SUCCESS_COLOR: string;
  LINK_COLOR: string;
  BORDER_COLOR: string;
  DISABLED_COLOR: string;
  DISABLED_TEXT_COLOR: string;
  SECONDARY_TEXT_COLOR: string;
  BODY_TEXT_COLOR: string;
  TITLE_COLOR: string;
}

export const DEFAULT_CONSTANT: IKey = {
  DEFAULT_PER_PAGE: 10,
  DEFAULT_PAGE: 1,
  PRIMARY_COLOR: "#0072C2",
  WHITE_COLOR: "#FFFFFF",
  ERROR_COLOR: "#FF4D4F",
  WARNING_COLOR: "#FAAD14",
  SUCCESS_COLOR: "#52C41A",
  LINK_COLOR: "#1890FF",
  BORDER_COLOR: "#D9D9D9",
  DISABLED_COLOR: "rgba(0, 0, 0, 0.08)",
  DISABLED_TEXT_COLOR: "rgba(0, 0, 0, 0.3)",
  SECONDARY_TEXT_COLOR: "rgba(0, 0, 0, 0.48)",
  BODY_TEXT_COLOR: "rgba(0, 0, 0, 0.64)",
  TITLE_COLOR: "rgba(0, 0, 0, 0.96)",
};
