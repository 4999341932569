import { api, createCancelTokenHandler } from "../utils";

const endPoint = () => window._env_.REACT_APP_PASSPORT_END_POINT;

const Repository = {
  findAll: function (id: number | string, params: any = null) {
    return api.get(`${endPoint()}/api/order-delivery-log/${id}`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.findAll.name].handleRequestCancellation()
          .token,
    });
  },
  resend: function (payload: any, params: any = null) {
    return api.post(
      `${endPoint()}/api/order-delivery-log/resend-email`,
      payload,
      {
        params,
        cancelToken:
          cancelTokenHandlerObject[this.resend.name].handleRequestCancellation()
            .token,
      }
    );
  },
};

const cancelTokenHandlerObject = createCancelTokenHandler(Repository);

export default Repository;
