import React from "react";
import { useTranslation } from "react-i18next";
import Form from "antd/es/form";
import { useState } from "@hookstate/core";
import { Helmet } from "react-helmet-async";
import Typography from "antd/es/typography";
import Input from "antd/es/input";
import Button from "antd/es/button";
import Spin from "antd/lib/spin";
import { useQuery } from "../../../functions/global";
import AuthRepository from "../../../repositories/AuthRepository";
import { AxiosError, AxiosResponse } from "axios";
import { useNavigate } from "react-router-dom";
import { openNotification } from "../../../utils";

const { Title } = Typography;

const AuthResetPassword: React.FC<any> = () => {
  const url = new URL(window.location.href);
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const loading = useState(false);
  const query = useQuery();
  const navigate = useNavigate();
  const type = url.searchParams.get("type");

  const onFinish = (data: any) => {
    loading.set(true);

    let payload = {
      token: query.get("token"),
      email: query.get("email"),
      password: data.password,
      password_confirmation: data.password_confirmation,
    };

    if (type === "create") {
      AuthRepository.createPassword(payload)
          .then((res: AxiosResponse) => {
            if (res.data.message) {
              navigate("/login");
              openNotification("success", t("notification.success.resetPassword"));
            } else {
              openNotification("error", t("notification.error.default"));
              resetForm();
            }
          })
          .catch((e: AxiosError) => {
            let errors = e.response?.data;

            if (errors) {
              let firstKey = Object.keys(e.response?.data)[0];
              openNotification("error", errors[firstKey]);
            } else {
              openNotification("error", t("notification.error.default"));
            }

            resetForm();
          });
    }
    else {
      AuthRepository.resetPassword(payload)
          .then((res: AxiosResponse) => {
            if (res.data.message) {
              navigate("/login");
              openNotification("success", t("notification.success.resetPassword"));
            } else {
              openNotification("error", t("notification.error.default"));
              resetForm();
            }
          })
          .catch((e: AxiosError) => {
            let errors = e.response?.data;

            if (errors) {
              let firstKey = Object.keys(e.response?.data)[0];
              openNotification("error", errors[firstKey]);
            } else {
              openNotification("error", t("notification.error.default"));
            }

            resetForm();
          });
    }
  };

  const resetForm = () => {
    form.resetFields();
    loading.set(false);
  };

  return (
    <>
      <Helmet>
        <title>{t("auth.resetPassword.metaTitle")}</title>
      </Helmet>

      <div className="default-small-wrapper">
        <div className="inner-section">
          <div className="form-wrapper">
            <div className="form-header">
              <Title level={3} className="title">
                {t("auth.resetPassword.title")}
              </Title>
            </div>

            <Form
              form={form}
              layout={"vertical"}
              onFinish={onFinish}
              hideRequiredMark
            >
              <Form.Item
                name="password"
                label={t("common.form.newPassword.label")}
                rules={[
                  {
                    required: true,
                    message: t("validation.required", {
                      item: t("common.form.newPassword.label"),
                    }),
                  },
                ]}
              >
                <Input.Password
                  placeholder={t("common.form.newPassword.placeholder")}
                />
              </Form.Item>

              <Form.Item
                name="password_confirmation"
                label={t("common.form.newPasswordConfirmation.label")}
                dependencies={["password"]}
                rules={[
                  {
                    required: true,
                    message: t("validation.required", {
                      item: t("common.form.newPassword.label"),
                    }),
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(t("validation.notMatchPassword"))
                      );
                    },
                  }),
                ]}
              >
                <Input.Password
                  placeholder={t(
                    "common.form.newPasswordConfirmation.placeholder"
                  )}
                />
              </Form.Item>

              <Spin spinning={loading.get()}>
                <Button htmlType="submit" type="primary" block>
                  {t("common.button.save")}
                </Button>
              </Spin>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AuthResetPassword;
