import { FC } from "react";
import { Card, CardProps } from "antd";

interface IVuiFormCard {
  hasTab?: boolean;
}

const VuiFormCard: FC<IVuiFormCard & CardProps> = (props) => {
  const {
    hasTab = false,
    children,
    bodyStyle = { padding: hasTab ? 0 : 24 },
    ...other
  } = props;

  return (
    <Card bodyStyle={bodyStyle} {...other}>
      {children}
    </Card>
  );
};

export default VuiFormCard;
