import { Button } from "antd";
import Col from "antd/es/grid/col";
import Row from "antd/es/grid/row";
import { ColumnType } from "antd/es/table/interface";
import { AxiosResponse } from "axios";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import VuiAccessible from "../../../@vodea/vodea-ui/components/Accessible";
import {
  VuiCardChart,
  VuiCardSummary,
} from "../../../@vodea/vodea-ui/components/Card";
import { IVuiCardChartDataset } from "../../../@vodea/vodea-ui/components/Card/Chart";
import { type } from "../../../@vodea/vodea-ui/components/Card/Summary";
import VuiContainer from "../../../@vodea/vodea-ui/components/Container";
import VuiContent from "../../../@vodea/vodea-ui/components/Content";
import VuiFilterDateRange from "../../../@vodea/vodea-ui/components/Filter/DateRange";
import VuiSectionTitle from "../../../@vodea/vodea-ui/components/Sections/Title";
import VuiSelectSingle from "../../../@vodea/vodea-ui/components/Select/Single";
import { ACCESS_PERMISSION, RECENT_FILTER_KEY } from "../../../constant";
import { recentFilterData } from "../../../functions/global";
import CompanyRepository from "../../../repositories/CompanyRepository";
import WidgetRepository from "../../../repositories/WidgetRepository";
import { IBreadcrumbs } from "../../../stores/breadcrumbs";
import {
  haveAccess,
  useAccess,
  useBreadcrumbs,
  useIsMounted,
} from "../../../utils";
import VuiCardPieChart from "../../../@vodea/vodea-ui/components/Card/PieChart";

interface IDashboard {
  breadcrumbs: IBreadcrumbs[];
}

const qs = require("qs");

interface IParams {
  company: string | null;
  date_from: string | null;
  date_to: string | null;
}

interface ISummaryData {
  value: string;
  percentage: string;
  type: type;
  comparison: string;
}

interface ITableData {
  data: any[];
  columns: ColumnType<any>[];
  percentage: string;
  type: type;
  comparison: string;
}

interface IChartData {
  dataset: IVuiCardChartDataset[];
  percentage: string;
  type: type;
  comparison: string;
  additional: {
    label: string;
    currentPeriod: number;
    lastPeriod: number;
  };
}

const baseSummaryData: ISummaryData = {
  value: "0",
  percentage: "0%",
  type: "up",
  comparison: "30",
};

const baseTableData: ITableData = {
  data: [],
  columns: [],
  percentage: "0%",
  type: "up",
  comparison: "30",
};

const baseChartData: IChartData = {
  dataset: [],
  percentage: "0%",
  type: "up",
  comparison: "30",
  additional: {
    label: "",
    currentPeriod: 0,
    lastPeriod: 0,
  },
};

const AppDashboard: React.FC<IDashboard> = ({ breadcrumbs }) => {
  useBreadcrumbs(breadcrumbs);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isMounted = useIsMounted();
  const acl = useAccess();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [giftSent, setGiftSent] = useState<ISummaryData>(baseSummaryData);
  const [giftNotSent, setGiftNotSent] = useState<ISummaryData>(baseSummaryData);
  const [orderInProcess, setOrderInProcess] =
    useState<ISummaryData>(baseSummaryData);
  const [orderComplete, setOrderComplete] =
    useState<ISummaryData>(baseSummaryData);
  const [mostProductRedeem, setMostProductRedeem] =
    useState<ITableData>(baseTableData);
  const [transactionSummary, setTransactionSummary] =
    useState<IChartData>(baseChartData);
  const [selectedCompany, setSelectedCompany] = useState<any>(
    searchParams.get("company")
      ? recentFilterData.getSelectedFilter(RECENT_FILTER_KEY.company, [
          Number(searchParams.get("company")),
        ])[0]
      : null
  );
  const [filterDate, setFilterDate] = React.useState<any>([
    searchParams.get("start_date")
      ? moment(searchParams.get("start_date"), "YYYY-MM-DD")
      : moment().subtract(30, "days"),
    searchParams.get("end_date")
      ? moment(searchParams.get("end_date"), "YYYY-MM-DD")
      : moment(),
  ]);

  const mostProductRedeemColumns: ColumnType<any[]>[] = [
    {
      title: t("common.text.itemName"),
      dataIndex: "name",
      key: "name",
    },
    {
      title: t("common.text.qty"),
      dataIndex: "total",
      key: "total",
    },
  ];

  const setQueryParams = () => {
    const params: Partial<IParams> = {};

    Object.assign(params, {
      ...(selectedCompany ? { company: selectedCompany.value } : {}),
      ...(filterDate
        ? { start_date: moment(filterDate[0]).format("YYYY-MM-DD") }
        : {}),
      ...(filterDate
        ? { end_date: moment(filterDate[1]).format("YYYY-MM-DD") }
        : {}),
    });

    const queryParams = qs.stringify(params, { indices: false });

    if (queryParams) {
      setSearchParams(`?${queryParams}`);
    } else {
      navigate("");
    }
  };

  const loadData = async () => {
    if (!isMounted || !searchParams.toString()) {
      return;
    }

    setIsLoading(true);

    const params: IParams = {
      company: searchParams.get("company"),
      date_from: searchParams.get("start_date"),
      date_to: searchParams.get("end_date"),
    };

    try {
      if (haveAccess(acl, ACCESS_PERMISSION.widget.giftSent)) {
        const res: AxiosResponse = await WidgetRepository.giftSent(params);
        const { data: giftSentRes } = res.data;

        setGiftSent({
          value: giftSentRes.current_period,
          ...getComparisonPercentage(
            giftSentRes.current_period,
            giftSentRes.last_period
          ),
        });
      }
      if (haveAccess(acl, ACCESS_PERMISSION.widget.giftNotSent)) {
        const res: AxiosResponse = await WidgetRepository.giftNotSent(params);
        const { data: giftNotSentRes } = res.data;
        setGiftNotSent({
          value: giftNotSentRes.current_period,
          ...getComparisonPercentage(
            giftNotSentRes.current_period,
            giftNotSentRes.last_period
          ),
        });
      }
      if (haveAccess(acl, ACCESS_PERMISSION.widget.orderInProcess)) {
        const res: AxiosResponse = await WidgetRepository.orderInProcess(
          params
        );
        const { data: orderInProcessRes } = res.data;
        setOrderInProcess({
          value: orderInProcessRes.current_period,
          ...getComparisonPercentage(
            orderInProcessRes.current_period,
            orderInProcessRes.last_period
          ),
        });
      }
      if (haveAccess(acl, ACCESS_PERMISSION.widget.orderComplete)) {
        const res: AxiosResponse = await WidgetRepository.orderComplete(params);
        const { data: orderCompleteRes } = res.data;
        setOrderComplete({
          value: orderCompleteRes.current_period,
          ...getComparisonPercentage(
            orderCompleteRes.current_period,
            orderCompleteRes.last_period
          ),
        });
      }
      if (haveAccess(acl, ACCESS_PERMISSION.widget.mostProductRedeem)) {
        const res: AxiosResponse = await WidgetRepository.mostProductRedeem(
          params
        );
        const mostProductRedeemRes = res.data;
        setMostProductRedeem({
          data: _.map(mostProductRedeemRes.data, (item) => ({
            type: item.name,
            value: item.total,
          })),
          columns: mostProductRedeemColumns,
          ...getComparisonPercentage(
            mostProductRedeemRes.period.current_period,
            mostProductRedeemRes.period.last_period
          ),
        });
      }
      if (haveAccess(acl, ACCESS_PERMISSION.widget.productSummary)) {
        const res: AxiosResponse = await WidgetRepository.productSummary(
          params
        );

        const { data: productSummaryRes } = res.data;

        setTransactionSummary({
          dataset: _.map(productSummaryRes.items_per_day, (item) => ({
            items_out: item.items_out,
            label: moment(item.label).format("DD MMM YYYY"),
          })),
          ...getComparisonPercentage(
            productSummaryRes.items_out,
            productSummaryRes.items_out_last_period
          ),
          additional: {
            label: t("common.text.redeemedItems"),
            currentPeriod: productSummaryRes.items_out,
            lastPeriod: productSummaryRes.items_out_last_period,
          },
        });
      }

      setIsLoading(false);
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };

  const getComparisonPercentage = (
    currentPeriod: number,
    lastPeriod: number
  ) => {
    let difference = moment(filterDate[1]).diff(moment(filterDate[0]), "days");
    let diffPeriodValue = 0;
    let percentage;
    let type: type;

    if (lastPeriod !== 0) {
      diffPeriodValue = (currentPeriod - lastPeriod) / lastPeriod;
    } else {
      if (currentPeriod !== 0) {
        diffPeriodValue = 1;
      }
    }

    percentage = diffPeriodValue * 100;

    if (diffPeriodValue >= 0) {
      type = "up";
    } else {
      type = "down";
    }

    // eslint-disable-next-line eqeqeq
    if (difference != 0) {
      difference += 1;
    }

    return {
      percentage: `${Math.round(percentage)}%`,
      type: type,
      comparison: String(difference),
    };
  };

  // Lifecycle
  useEffect(() => {
    (async () => {
      await loadData();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  useEffect(() => {
    isMounted ? setQueryParams() : setSearchParams("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMounted, selectedCompany, filterDate]);

  const handleDateRangeCallback = (dates: any) => {
    setFilterDate(dates);
  };

  const handleReset = () => {
    setSelectedCompany(null);
    setFilterDate([moment().startOf("month"), moment().endOf("month")]);
  };

  return (
    <>
      <VuiContainer>
        <VuiSectionTitle title={t("common.text.dashboard")} />

        <VuiContent>
          <Row gutter={[10, 10]} className="mb16">
            <Col className="gutter-row">
              <VuiSelectSingle
                style={{ minWidth: 160 }}
                value={selectedCompany}
                onChange={(value: any) => {
                  setSelectedCompany(value);
                  recentFilterData.save(RECENT_FILTER_KEY.categoryType, [
                    value,
                  ]);
                }}
                repository={CompanyRepository}
                placeholder={t("common.text.company")}
              />
            </Col>
            <Col>
              <VuiFilterDateRange
                defaultValue={filterDate}
                callback={handleDateRangeCallback}
              />
            </Col>
            <Col>
              <Button type="link" onClick={handleReset}>
                Reset
              </Button>
            </Col>
          </Row>
          <Row gutter={[10, 10]} className="mb16">
            <VuiAccessible access={ACCESS_PERMISSION.widget.giftSent}>
              <Col className="gutter-row" span={6} xs={24} md={12} lg={6}>
                <VuiCardSummary
                  title={t("common.text.redeemedItems")}
                  value={giftSent.value}
                  percentage={giftSent.percentage}
                  type={giftSent.type}
                  comparison={t("widget.comparison", {
                    item: giftSent.comparison,
                  })}
                  loading={isLoading}
                />
              </Col>
            </VuiAccessible>

            <VuiAccessible access={ACCESS_PERMISSION.widget.giftNotSent}>
              <Col className="gutter-row" span={6} xs={24} md={12} lg={6}>
                <VuiCardSummary
                  title={t("common.text.availableItems")}
                  value={giftNotSent.value}
                  percentage={giftNotSent.percentage}
                  type={giftNotSent.type}
                  comparison={t("widget.comparison", {
                    item: giftNotSent.comparison,
                  })}
                  loading={isLoading}
                />
              </Col>
            </VuiAccessible>

            <VuiAccessible access={ACCESS_PERMISSION.widget.orderComplete}>
              <Col className="gutter-row" span={6} xs={24} md={12} lg={6}>
                <VuiCardSummary
                  title={t("common.text.orderComplete")}
                  value={orderComplete.value}
                  percentage={orderComplete.percentage}
                  type={orderComplete.type}
                  comparison={t("widget.comparison", {
                    item: orderComplete.comparison,
                  })}
                  loading={isLoading}
                />
              </Col>
            </VuiAccessible>

            <VuiAccessible access={ACCESS_PERMISSION.widget.orderInProcess}>
              <Col className="gutter-row" span={6} xs={24} md={12} lg={6}>
                <VuiCardSummary
                  title={t("common.text.orderInProcess")}
                  value={orderInProcess.value}
                  percentage={orderInProcess.percentage}
                  type={orderInProcess.type}
                  comparison={t("widget.comparison", {
                    item: orderInProcess.comparison,
                  })}
                  loading={isLoading}
                />
              </Col>
            </VuiAccessible>
          </Row>
          <Row gutter={[10, 10]} className="mb16">
            <VuiAccessible access={ACCESS_PERMISSION.widget.mostProductRedeem}>
              <Col className="gutter-row" span={12} xs={24} md={12}>
                <VuiCardPieChart
                  title={t("common.text.mostRedeemedItems")}
                  data={mostProductRedeem.data}
                  percentage={mostProductRedeem.percentage}
                  type={mostProductRedeem.type}
                  comparison={t("widget.comparison", {
                    item: mostProductRedeem.comparison,
                  })}
                  loading={isLoading}
                />
              </Col>
            </VuiAccessible>

            <VuiAccessible access={ACCESS_PERMISSION.widget.productSummary}>
              <Col className="gutter-row" span={12} xs={24} md={12}>
                <VuiCardChart
                  title={t("common.text.transactionSummary")}
                  dataset={transactionSummary.dataset}
                  percentage={transactionSummary.percentage}
                  type={transactionSummary.type}
                  comparison={t("widget.comparison", {
                    item: transactionSummary.comparison,
                  })}
                  additional={transactionSummary.additional}
                  loading={isLoading}
                />
              </Col>
            </VuiAccessible>
          </Row>
        </VuiContent>
      </VuiContainer>
    </>
  );
};

export default AppDashboard;
