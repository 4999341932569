import { Helmet } from "react-helmet-async";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Typography from "antd/es/typography";
import { ResetPasswordSuccessImage } from "../../../../components";
import Button from "antd/es/button";

const { Title, Text } = Typography;

interface IForgotPassword {
  username: string;
}

const defaultValues: IForgotPassword = {
  username: "",
};

const AuthResetPasswordSuccess: React.FC<any> = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);

  return (
    <>
      <Helmet>
        <title>{t("auth.forgotPassword.metaTitle")}</title>
      </Helmet>

      <div className="forgot-password-wrapper">
        <div className="forgot-password-main-wrapper">
          <ResetPasswordSuccessImage />
          <Title className="title" level={3}>
            {t("common.text.succeed")}
          </Title>
          <Text className="subtitle">
            {t("auth.resetPassword.sentToEmail")}
          </Text>
          <Button style={{ minWidth: 120 }} htmlType="button" type="primary">
            {t("common.button.ok")}
          </Button>
        </div>
      </div>
    </>
  );
};

export default AuthResetPasswordSuccess;
