import { InputNumber } from "antd/es";
import React from "react";
import { InputNumberProps } from "antd/es/input-number";

export const formatterNumber = (val: any) => {
  return `${val}`
    .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    .replace(/\.(?=\d{0,2}$)/g, ",");
};

export const parserNumber = (val: any) => {
  if (!val) return 0;
  return Number.parseFloat(
    val.replace(/\$\s?|(\.*)/g, "").replace(/(\,{1})/g, ".")
  ).toFixed(2);
};

const VuiInputCurrency = (props: InputNumberProps) => {
  return (
    <InputNumber
      {...props}
      min={0}
      formatter={(value) => formatterNumber(value)}
      parser={(value) => parserNumber(value)}
    />
  );
};

export default VuiInputCurrency;
