import { PlusOutlined } from "@ant-design/icons";
import { Tag, Tooltip } from "antd";
import Button from "antd/es/button";
import Col from "antd/es/grid/col";
import Row from "antd/es/grid/row";
import Table from "antd/es/table";
import { ColumnType } from "antd/es/table/interface";
import { AxiosError, AxiosResponse } from "axios";
import _ from "lodash";
import moment from "moment";
import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import {
  VuiActionTable,
  VuiSearch,
} from "../../../../../@vodea/vodea-ui/components";
import VuiAccessible from "../../../../../@vodea/vodea-ui/components/Accessible";
import VuiContainer from "../../../../../@vodea/vodea-ui/components/Container";
import VuiContent from "../../../../../@vodea/vodea-ui/components/Content";
import VuiFilterDateRange from "../../../../../@vodea/vodea-ui/components/Filter/DateRange";
import VuiModalConfirmation from "../../../../../@vodea/vodea-ui/components/Modal/Confirmation";
import VuiSectionTitle from "../../../../../@vodea/vodea-ui/components/Sections/Title";
import VuiSelectSingle from "../../../../../@vodea/vodea-ui/components/Select/Single";
import {
  ACCESS_PERMISSION,
  RECENT_FILTER_KEY,
  SALES_ORDER_STATUS_COLOR,
} from "../../../../../constant";
import { recentFilterData } from "../../../../../functions/global";
import CompanyRepository from "../../../../../repositories/CompanyRepository";
import ConstantRepository from "../../../../../repositories/ConstantRepository";
import SalesOrderRepository from "../../../../../repositories/SalesOrderRepository";
import { IBreadcrumbs } from "../../../../../stores/breadcrumbs";
import {
  ConstantService,
  ITableQuery,
  openNotification,
  UrlParamsType,
  useBreadcrumbs,
  useDebounce,
  useIsMounted,
} from "../../../../../utils";

const qs = require("qs");

interface IParams extends UrlParamsType {
  status: string | null;
  date_from: string | null;
  date_to: string | null;
}

interface IAppOrderSalesOrderList {
  breadcrumbs: IBreadcrumbs[];
}

const AppOrderSalesOrderList: FC<IAppOrderSalesOrderList> = ({
  breadcrumbs,
}) => {
  useBreadcrumbs(breadcrumbs);
  const { t } = useTranslation();
  const hasInitialize = useRef(false);
  const isMounted = useIsMounted();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [selectedDeleteId, setSelectedDeleteId] = useState<number>(0);

  const [search, setSearch] = useState<string>(
    searchParams.get("search") || ""
  );
  const debouncedSearch = useDebounce<string>(search, 500);
  const [tableQuery, setTableQuery] = useState<ITableQuery>({
    page: Number(
      searchParams.get("page") || ConstantService.getConstant("DEFAULT_PAGE")
    ),
    perPage: Number(
      searchParams.get("perPage") ||
        ConstantService.getConstant("DEFAULT_PER_PAGE")
    ),
    sortField: searchParams.get("sortField") || null,
    sortOrder: searchParams.get("sortOrder") || null,
  });
  const [tableLoading, setTableLoading] = useState<boolean>(false);
  const [tableData, setTableData] = useState<any[]>([]);
  const [totalData, setTotalData] = useState<number>(0);
  const [selectedStatus, setSelectedStatus] = useState<any>(
    searchParams.get("status")
      ? recentFilterData.getSelectedFilter(RECENT_FILTER_KEY.salesOrderStatus, [
          Number(searchParams.get("status")),
        ])[0]
      : null
  );
  // For DateRange Picker
  const [filterDate, setFilterDate] = React.useState<any>([
    searchParams.get("start_date")
      ? moment(searchParams.get("start_date"), "YYYY-MM-DD")
      : moment().subtract(30, "days"),
    searchParams.get("end_date")
      ? moment(searchParams.get("end_date"), "YYYY-MM-DD")
      : moment(),
  ]);

  const handleDateRangeCallback = (dates: any, dateString?: any) => {
    setFilterDate(dates);
  };
  // End DateRange Picker
  const tableColumns: ColumnType<any>[] = [
    {
      title: t("common.text.date"),
      dataIndex: "date",
      key: "date",
      render: (text: any) => {
        return moment(text).format("D MMM YYYY");
      },
    },
    {
      title: t("common.text.company"),
      dataIndex: "company_name",
      key: "company_name",
    },
    {
      title: t("common.text.orderNumber"),
      dataIndex: "number",
      key: "number",
    },
    {
      title: t("common.text.type"),
      dataIndex: "delivery_type",
      key: "delivery_type",
    },
    {
      title: t("common.text.item"),
      dataIndex: "items",
      key: "items",
      render: (text: any) => {
        const cloneItems = [...text];
        cloneItems.shift();

        if (text.length > 1) {
          return (
            <div>
              <span>{text[0]}, </span>
              <Tooltip title={cloneItems.join(", ")}>
                <span>{`+${cloneItems.length}`}</span>
              </Tooltip>
            </div>
          );
        } else {
          return <span>{text[0]}</span>;
        }
      },
    },
    {
      title: t("common.text.status"),
      dataIndex: "status_name",
      key: "status_name",
      render: (text: any) => {
        return <Tag color={_.get(SALES_ORDER_STATUS_COLOR, text)}>{text}</Tag>;
      },
    },
    {
      title: "",
      key: "action",
      render: (text: any, record: any) => (
        <VuiAccessible access={ACCESS_PERMISSION.salesOrder.show}>
          <VuiActionTable<any> detailLink={`${pathname}/${record.id}`} />
        </VuiAccessible>
      ),
    },
  ];

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const setDeleteModal = useCallback((id: any["id"]) => {
    setSelectedDeleteId(id);
    setShowDeleteModal(true);
  }, []);

  const deleteModalCallback = useCallback(async () => {
    if (selectedDeleteId) {
      await CompanyRepository.delete(selectedDeleteId)
        .then((response: AxiosResponse) => {
          setShowDeleteModal(false);
          setSelectedDeleteId(0);
          openNotification(
            "success",
            t("notification.success.deleteItem", { item: "Territory" })
          );
          // getTableData(true)
        })
        .catch((e: AxiosError) => {
          setShowDeleteModal(false);
          setSelectedDeleteId(0);
          openNotification("error", t("notification.error.default"));
        });
    }
  }, [t, selectedDeleteId]);

  const loadData = () => {
    if (!isMounted || !searchParams.toString()) {
      return;
    }

    setTableLoading(true);

    const params: IParams = {
      search: searchParams.get("search"),
      page: Number(searchParams.get("page")),
      per_page: Number(searchParams.get("perPage")),
      order_by: searchParams.get("sortField"),
      sorted_by: searchParams.get("sortOrder"),
      date_from: searchParams.get("start_date"),
      date_to: searchParams.get("end_date"),
      status: searchParams.get("status"),
    };

    SalesOrderRepository.findAll(params)
      .then((response: AxiosResponse) => {
        if (isMounted) {
          const { data: responseData, meta } = response.data;
          setTableLoading(false);
          setTableData(responseData);
          setTotalData(meta.total);
        }
      })
      .catch((error: AxiosError) => {
        if (isMounted) {
          setTableLoading(false);
        }
      });

    hasInitialize.current = true;
  };

  const handleTableChange = useCallback(
    (pagination: any, filters: any, sorter: any, extra: any) => {
      setTableQuery((prevState) => ({
        ...prevState,
        page: pagination.current,
        perPage: pagination.pageSize,
        ...(sorter.order ? { sortOrder: sorter.order } : {}),
        ...(sorter.field ? { sortField: sorter.field } : {}),
      }));
    },
    []
  );

  const setQueryParams = useCallback(() => {
    const params: Partial<IParams> = {};

    Object.assign(params, {
      ...(debouncedSearch ? { search: debouncedSearch } : {}),
      ...(tableQuery.page ? { page: tableQuery.page } : {}),
      ...(tableQuery.perPage ? { perPage: tableQuery.perPage } : {}),
      ...(tableQuery.sortField ? { sortField: tableQuery.sortField } : {}),
      ...(tableQuery.sortOrder ? { sortOrder: tableQuery.sortOrder } : {}),
      ...(selectedStatus ? { status: selectedStatus.value } : {}),
      ...(filterDate
        ? { start_date: moment(filterDate[0]).format("YYYY-MM-DD") }
        : {}),
      ...(filterDate
        ? { end_date: moment(filterDate[1]).format("YYYY-MM-DD") }
        : {}),
    });

    const queryParams = qs.stringify(params, { indices: false });

    if (queryParams) {
      setSearchParams(`?${queryParams}`);
    } else {
      navigate("");
    }
  }, [
    setSearchParams,
    navigate,
    debouncedSearch,
    tableQuery,
    filterDate,
    selectedStatus,
  ]);

  // Lifecycle
  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  useEffect(() => {
    isMounted ? setQueryParams() : setSearchParams("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMounted, tableQuery]);

  const handleReset = () => {
    setSelectedStatus(null);
    setFilterDate([moment().startOf("month"), moment().endOf("month")]);
  };

  useEffect(() => {
    if (isMounted && hasInitialize.current) {
      setTableQuery((s) => ({
        ...s,
        page: Number(ConstantService.getConstant("DEFAULT_PAGE")),
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearch, selectedStatus, filterDate]);

  return (
    <>
      <VuiContainer>
        <VuiSectionTitle title={t("salesOrder.title")}>
          <VuiAccessible access={ACCESS_PERMISSION.salesOrder.store}>
            <Button
              onClick={() => navigate(`${pathname}/add`)}
              type="primary"
              icon={<PlusOutlined />}
            >
              {t("common.button.add")}
            </Button>
          </VuiAccessible>
        </VuiSectionTitle>

        <VuiContent>
          <Row gutter={[10, 10]} className="mb24">
            <Col className="gutter-row" span={6} xs={24} md={12} lg={10}>
              <VuiSearch
                allowClear
                defaultValue={search}
                onSearch={(value) => setSearch(value)}
              />
            </Col>
          </Row>

          <Row gutter={[10, 10]} className="mb16">
            <Col className="gutter-row">
              <VuiSelectSingle
                value={selectedStatus}
                onChange={(value: any) => {
                  setSelectedStatus(value);
                  recentFilterData.save(RECENT_FILTER_KEY.salesOrderStatus, [
                    value,
                  ]);
                }}
                labelKey="label"
                repository={ConstantRepository}
                selectParams={{ for: "status_sales_order" }}
                placeholder={t("common.text.status")}
                style={{ minWidth: "160px" }}
              />
            </Col>
            <Col>
              <VuiFilterDateRange
                defaultValue={filterDate}
                callback={handleDateRangeCallback}
              />
            </Col>
            <Col>
              <Button type="link" onClick={handleReset}>
                Reset
              </Button>
            </Col>
          </Row>

          <Table
            rowKey={"id"}
            bordered
            columns={tableColumns}
            dataSource={tableData}
            loading={tableLoading}
            onChange={handleTableChange}
            pagination={{
              current: tableQuery.page,
              pageSize: tableQuery.perPage,
              total: totalData,
            }}
          />
        </VuiContent>
      </VuiContainer>

      <VuiModalConfirmation
        show={showDeleteModal}
        onOk={deleteModalCallback}
        onCancel={() => setShowDeleteModal(false)}
      />
    </>
  );
};

export default AppOrderSalesOrderList;
