import { useAppSelector } from "../../stores/hooks";

const useAccess = (): string[] => {
  const permissions = useAppSelector(
    (state) => state.system?.account?.permissions
  );
  return permissions || [];
};

export default useAccess;
