import { useState } from "@hookstate/core";
import { Checkbox, Col, Form, Row, Table } from "antd";
import Input from "antd/es/input";
import { ColumnType } from "antd/lib/table";
import Text from "antd/lib/typography/Text";
import { AxiosError, AxiosResponse } from "axios";
import _ from "lodash";
import React, { FC, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import {
  VuiFormAction,
  VuiFormCard,
  VuiFormLayout,
} from "../../../../../../@vodea/vodea-ui/components";
import VuiContainer from "../../../../../../@vodea/vodea-ui/components/Container";
import VuiContent from "../../../../../../@vodea/vodea-ui/components/Content";
import VuiSectionTitle from "../../../../../../@vodea/vodea-ui/components/Sections/Title";
import VuiSelectSingle from "../../../../../../@vodea/vodea-ui/components/Select/Single";
import { ACCESS_PERMISSION } from "../../../../../../constant";
import ConstantRepository from "../../../../../../repositories/ConstantRepository";
import PermissionRepository from "../../../../../../repositories/PermissionRepository";
import RoleRepository from "../../../../../../repositories/RoleRepository";
import { IBreadcrumbs } from "../../../../../../stores/breadcrumbs";
import {
  convertLabelInValue,
  openNotification,
  useBreadcrumbs,
} from "../../../../../../utils";

// interface IClientCreate extends Omit<Client, "id" | "status"> {}
interface ITableData {}

interface IAppSettingAccountAccessRoleForm {
  breadcrumbs: IBreadcrumbs[];
}
const initialValues = {
  name: "",
};

const baseColumns: ColumnType<ITableData> = {
  title: "",
  dataIndex: "name",
  key: "name",
  className: "text-capitalize",
};

const AppSettingAccountAccessRoleForm: FC<IAppSettingAccountAccessRoleForm> = ({
  breadcrumbs,
}) => {
  useBreadcrumbs(breadcrumbs);
  const { id } = useParams();
  const { t } = useTranslation();
  const title = t("common.text.role");

  const [tableLoading, setTableLoading] = React.useState<boolean>(false);
  const navigate = useNavigate();
  const selectedPermissions = useState<number[]>([]);
  const [masterColumns, setMasterColumns] = React.useState<
    ColumnType<ITableData>[]
  >([]);
  const [masterTableData, setMasterTableData] = React.useState<ITableData[]>(
    []
  );
  const [dashboardColumns, setDashboardColumns] = React.useState<
    ColumnType<ITableData>[]
  >([]);
  const [dashboardTableData, setDashboardTableData] = React.useState<
    ITableData[]
  >([]);

  //form
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const selectedType = Form.useWatch("type_id", form);

  const onFinish = useCallback((values: any) => {
    setIsLoading(true);

    const payload = {
      ...values,
      permission_ids: selectedPermissions.value,
      type_id: _.get(values.type_id, "value"),
    };

    (id ? RoleRepository.update(id, payload) : RoleRepository.create(payload))
      .then(() => {
        navigate(-1);

        if (!id) {
          openNotification(
            "success",
            t("notification.success.createItem", { item: title })
          );
        } else {
          openNotification(
            "success",
            t("notification.success.updateItem", { item: title })
          );
        }
      })
      .catch((e: AxiosError) => {
        console.log(e);
        openNotification("error", t("notification.error.default"));
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    if (id) {
      getData();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (selectedType) {
      getPermissionData();
    }
  }, [selectedType]);

  const getData = async () => {
    let repository: any = () =>
      Promise.all([
        RoleRepository.findOne(id, {
          with: ["gateSetting.permissions", "type"],
        }),
      ]);

    repository()
      .then((res: AxiosResponse[]) => {
        if (id) {
          const roles = res[0].data?.data || {};
          const currentPermissions = roles?.gate_setting?.permissions?.map(
            (item: any) => item.id
          );
          selectedPermissions.set(currentPermissions);

          form.setFieldsValue({
            name: roles.name,
            type_id: convertLabelInValue(roles.type, "label") || null,
          });
        }
      })
      .catch((e: AxiosError) => {
        console.log(e, "error");
      });
  };

  const getPermissionData = async () => {
    setTableLoading(true);

    let repository: any = () =>
      Promise.all([
        PermissionRepository.findAll({ type_id: selectedType.value }),
      ]);

    repository()
      .then((res: AxiosResponse[]) => {
        const permissions = res[0]?.data?.data || [];

        toDashboardTable(permissions);
        toMasterTable(permissions);
      })
      .catch((e: AxiosError) => {
        console.log(e, "error");
      })
      .finally(() => {
        setTableLoading(false);
      });
  };

  const transformPermission = (permissions: any[]) => {
    let baseData: any = {};
    const tableColumns: ColumnType<ITableData>[] = [baseColumns];

    permissions.forEach((permission) => {
      const name = permission.label.split(".")[0];
      if (baseData[name]) {
        baseData[name][permission.method] = permission.id;
      } else {
        baseData = {
          ...baseData,
          [name]: {
            [permission.method]: permission.id,
          },
        };
      }

      const isExist = tableColumns.find(
        (item) => item.key === permission.method
      );

      if (!isExist) {
        tableColumns.push({
          ...baseColumns,
          title: permission.label.split(".")[1].replaceAll("-", " "),
          dataIndex: permission.method,
          key: permission.method,
        });
      }
    });

    return {
      baseData,
      tableColumns,
    };
  };

  const toDashboardTable = (permissions: any[]) => {
    const dashboardPermissions = permissions.filter((item) =>
      item.name.includes("widget")
    );

    const { baseData, tableColumns } =
      transformPermission(dashboardPermissions);

    const tableData = Object.keys(baseData).map((key) => {
      return {
        name: "Dashboard",
        ...baseData[key],
      };
    });

    setDashboardTableData(tableData);
    setDashboardColumns(tableColumns);
  };

  const toMasterTable = (permissions: any[]) => {
    const masterPermissions = permissions.filter(
      (item) => !item.name.includes("widget")
    );

    const { baseData, tableColumns } = transformPermission(masterPermissions);

    const tableData = Object.keys(baseData).map((key) => {
      return {
        name: key.replaceAll("-", " "),
        ...baseData[key],
      };
    });

    setMasterTableData(tableData);
    setMasterColumns(tableColumns);
  };

  const handleChange = (id: number) => {
    let arr: number[] = selectedPermissions.get() || [];
    if (arr.includes(id)) {
      arr = arr.filter((item: any) => item !== id);
    } else {
      arr = [...arr, id];
    }
    selectedPermissions.set(arr);
  };

  const handleChecked = useCallback(
    (id: number) => {
      const arr: number[] = selectedPermissions?.get();
      if (arr?.includes(id)) {
        return true;
      } else {
        return false;
      }
    },
    [selectedPermissions.get()]
  );

  const getDashboardColumns: ColumnType<ITableData>[] = [
    ...dashboardColumns,
  ].map((item: ColumnType<ITableData>, i) => {
    if (i != 0) {
      item.render = (text: any, record: any) =>
        record[item.key as string] ? (
          <Checkbox
            checked={handleChecked(record[item.key as string])}
            onChange={() => handleChange(record[item.key as string])}
          />
        ) : (
          <span />
        );
    }
    return item;
  });

  const getMasterColumns: ColumnType<ITableData>[] = [...masterColumns].map(
    (item: ColumnType<ITableData>, i) => {
      if (i != 0) {
        item.render = (text: string, record: any) =>
          record[item.key as string] ? (
            <Checkbox
              checked={handleChecked(record[item.key as string])}
              onChange={() => handleChange(record[item.key as string])}
            />
          ) : (
            <span />
          );
      }
      return item;
    }
  );

  return (
    <VuiContainer>
      <VuiSectionTitle
        onBack={() => navigate(-1)}
        title={t(id ? "common.text.editItem" : "common.text.addItem", {
          item: title,
        })}
      />

      <VuiContent>
        <VuiFormLayout
          form={form}
          onFinish={onFinish}
          initialValues={initialValues}
        >
          <Row>
            <Col span={24}>
              <VuiFormCard>
                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <Form.Item
                      className="mb0"
                      rules={[
                        {
                          required: true,
                          message: t("validation.required", {
                            item: t("common.form.name.label"),
                          }),
                        },
                      ]}
                      name={"name"}
                      label={t("common.form.name.label")}
                    >
                      <Input placeholder={t("common.form.name.placeholder")} />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      rules={[
                        {
                          required: true,
                          message: t("validation.required", {
                            item: t("common.form.type.label"),
                          }),
                        },
                      ]}
                      name={"type_id"}
                      label={t("common.form.type.label")}
                    >
                      <VuiSelectSingle
                        labelKey="label"
                        onChange={() => {
                          selectedPermissions.set([]);
                        }}
                        repository={ConstantRepository}
                        selectParams={{ for: "user_type" }}
                        placeholder={t("common.form.type.placeholder")}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Text strong>Role Permission</Text>
                  </Col>
                  <Col span={24}>
                    <div className="mb24">
                      <Text>Dashboard</Text>
                    </div>
                    <div>
                      <Table
                        rowKey={"name"}
                        loading={tableLoading}
                        dataSource={dashboardTableData}
                        columns={getDashboardColumns}
                        pagination={false}
                      ></Table>
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="mb24">
                      <Text>Master Data & Transactional</Text>
                    </div>
                    <div>
                      <Table
                        rowKey={"name"}
                        loading={tableLoading}
                        dataSource={masterTableData}
                        columns={getMasterColumns}
                        pagination={false}
                      ></Table>
                    </div>
                  </Col>
                </Row>
              </VuiFormCard>

              <VuiFormAction
                label={id ? "common.text.editItem" : "common.text.addItem"}
                labelOption={{ item: t("common.text.role") }}
                isLoading={isLoading}
                permission={
                  id
                    ? ACCESS_PERMISSION.role.update
                    : ACCESS_PERMISSION.role.store
                }
              />
            </Col>
          </Row>
        </VuiFormLayout>
      </VuiContent>
    </VuiContainer>
  );
};

export default AppSettingAccountAccessRoleForm;
