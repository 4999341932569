import { api, createCancelTokenHandler } from "../utils";

const endPoint = () => window._env_.REACT_APP_PASSPORT_END_POINT;

const Repository = {
    update: function (payload: any, params: any = null) {
        return api.put(`${endPoint()}/api/v1/account/update`, payload, {
            params,
            cancelToken: cancelTokenHandlerObject[this.update.name].handleRequestCancellation().token
        });
    },
    updatePassword: function (payload: any, params: any = null) {
        return api.put(`${endPoint()}/api/v1/account/update/password`, payload, {
            params,
            cancelToken: cancelTokenHandlerObject[this.updatePassword.name].handleRequestCancellation().token
        });
    },
}

const cancelTokenHandlerObject = createCancelTokenHandler(Repository);

export default Repository;
