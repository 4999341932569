import "./style.less";
import { Link } from "react-router-dom";
import { VuiHorizontalMoreIcon } from "../../Icon";
import React, { ReactElement, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Dropdown, Menu, Space } from "antd";

interface IRecord {
  id: number | string;
}

interface IVuiActionTable<T extends Partial<IRecord>> {
  detailLink: string;
  detailLabel?: string;
  children?: ReactElement<any, any> | null;
}

const VuiActionTable = <T extends Partial<IRecord>>({
  detailLink,
  detailLabel,
  children,
}: IVuiActionTable<T>) => {
  const { t } = useTranslation();

  const renderChildren = useMemo(
    () =>
      children ? (
        <Dropdown
          overlay={() => <Menu style={{ marginTop: 10 }}>{children}</Menu>}
        >
          <VuiHorizontalMoreIcon style={{ marginLeft: 15 }} />
        </Dropdown>
      ) : null,
    [children]
  );

  const renderLink = useMemo(
    () =>
      detailLink ? (
        <Link to={detailLink}>
          {detailLabel ? detailLabel : t("common.text.viewDetail")}
        </Link>
      ) : null,
    [detailLink, t]
  );

  return (
    <Space align={"center"}>
      {renderLink}
      {renderChildren}
    </Space>
  );
};

export default VuiActionTable;
