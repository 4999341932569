import * as part from "./part";

export const breadcrumbs = {
  dashboard: {
    index: [
      {
        link: "/",
        title: "breadcrumbs.dashboard.title",
      },
    ],
  },
  report: {
    index: [
      {
        link: "/report",
        title: "breadcrumbs.report.title",
      },
    ],
  },
  accountProfile: {
    index: [
      {
        link: "/account/profile",
        title: "common.text.profile",
      },
    ],
  },
  accountChangePassword: {
    index: [
      {
        link: "/account/change-password",
        title: "common.text.changePassword",
      },
    ],
  },
  settingAccountCompany: {
    index: part.settingAccountCompany,
    add: [
      ...part.settingAccountCompany,
      {
        link: "#",
        title: "common.text.add",
      },
    ],
    update: [
      ...part.settingAccountCompany,
      {
        link: "#",
        title: "common.text.edit",
      },
    ],
  },
  settingProduct: {
    index: part.settingProduct,
    add: [
      ...part.settingProduct,
      {
        link: "#",
        title: "common.text.add",
      },
    ],
    detail: [
      ...part.settingProduct,
      {
        link: "#",
        title: "common.text.detail",
      },
    ],
  },
  settingAccountUser: {
    index: part.settingAccountUser,
    add: [
      ...part.settingAccountUser,
      {
        link: "#",
        title: "common.text.add",
      },
    ],
    update: [
      ...part.settingAccountUser,
      {
        link: "#",
        title: "common.text.edit",
      },
    ],
  },
  settingAccountAccessRole: {
    index: part.settingAccountAccessRole,
    add: [
      ...part.settingAccountAccessRole,
      {
        link: "#",
        title: "common.text.add",
      },
    ],
    update: [
      ...part.settingAccountAccessRole,
      {
        link: "#",
        title: "common.text.edit",
      },
    ],
  },
  orderSalesOrder: {
    index: part.orderSalesOrder,
    add: [
      ...part.orderSalesOrder,
      {
        link: "#",
        title: "common.text.add",
      },
    ],
    detail: [
      ...part.orderSalesOrder,
      {
        link: "#",
        title: "common.text.detail",
      },
    ],
    batch: [
      ...part.orderSalesOrder,
      {
        link: "#",
        title: "common.text.detail",
        isDisabled: true,
      },
      {
        link: "#",
        title: "common.text.batch",
      },
    ],
    reward: [
      ...part.orderSalesOrder,
      {
        link: "#",
        title: "common.text.detail",
        isDisabled: true,
      },
      {
        link: "#",
        title: "common.text.reward",
      },
    ],
  },
  orderProductDelivery: {
    index: part.orderProductDelivery,
    detail: [
      ...part.orderProductDelivery,
      {
        link: "#",
        title: "common.text.detail",
      },
    ],
    reward: [
      ...part.orderProductDelivery,
      {
        link: "#",
        title: "common.text.detail",
        isDisabled: true,
      },
      {
        link: "#",
        title: "common.text.reward",
      },
    ],
    gift: [
      ...part.orderProductDelivery,
      {
        link: "#",
        title: "common.text.detail",
        isDisabled: true,
      },
      {
        link: "#",
        title: "common.text.gift",
      },
    ],
  },
};
