import { Helmet } from "react-helmet-async";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Typography from "antd/es/typography";
import Row from "antd/es/grid/row";
import Input from "antd/es/input";
import Button from "antd/es/button";
import Form from "antd/es/form";
import Spin from "antd/lib/spin";
import AuthRepository from "../../../repositories/AuthRepository";
import { AxiosError, AxiosResponse } from "axios";
import { openNotification } from "../../../utils";

const { Title } = Typography;

const url = () => window._env_.REACT_APP_URL;
interface IForgotPassword {
  username: string;
}

const defaultValues: IForgotPassword = {
  username: "",
};

const AuthForgotPassword: React.FC<any> = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);

  const onFinish = (data: any) => {
    setLoading(true);

    AuthRepository.forgotPassword({
      email: data.email,
      url: url() + "/reset-password",
    })
      .then((response: AxiosResponse) => {
        if (response.data.message) {
          openNotification(
            "success",
            "Success, reset link has send to your email"
          );
        } else {
          openNotification("error", t("notification.error.default"));
        }

        resetForm();
      })
      .catch((e: AxiosError) => {
        openNotification("error", t("notification.error.default"));

        resetForm();
      });
  };

  const resetForm = () => {
    form.resetFields();
    setLoading(false);
  };

  return (
    <>
      <Helmet>
        <title>{t("auth.forgotPassword.metaTitle")}</title>
      </Helmet>

      <div className="forgot-password-wrapper">
        <div className="forgot-password-main-wrapper">
          <Title className="title" level={3}>
            {t("auth.forgotPassword.title")}
          </Title>

          <Form
            form={form}
            layout={"vertical"}
            onFinish={onFinish}
            hideRequiredMark
          >
            <Form.Item
              rules={[
                {
                  required: true,
                  message: t("validation.required", {
                    item: t("common.form.email.label"),
                  }),
                },
                {
                  type: "email",
                  message: t("validation.type.email", {
                    item: t("common.form.email.label"),
                  }),
                },
              ]}
              name={"email"}
              label={t("common.form.email.label")}
            >
              <Input placeholder={t("common.form.email.placeholder")} />
            </Form.Item>

            <Row className="mt24" justify={"space-between"} align={"middle"}>
              <div />

              <Spin spinning={loading}>
                <Button htmlType="submit" type="primary" block>
                  {t("common.button.resetPassword")}
                </Button>
              </Spin>
            </Row>
          </Form>
        </div>
      </div>
    </>
  );
};

export default AuthForgotPassword;
