import { Input } from "antd";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { SearchProps } from "antd/lib/input/Search";

const { Search } = Input;

interface IVuiSearch extends SearchProps {}

const VuiSearch = (props: IVuiSearch) => {
  const { t } = useTranslation();
  const { placeholder = t("common.text.search"), onSearch, ...other } = props;

  return <Search placeholder={placeholder} onSearch={onSearch} {...other} />;
};

export default VuiSearch;
