import { AxiosError, AxiosResponse } from "axios";
import { mapHookErrors } from "../../functions/global";
import { openNotification } from "./notification.helper";
import _ from "lodash";
import { NavigateFunction } from "react-router";

export const generateLinkFromId = (url: string, id: number | string) => {
  return url + id;
};

export const haveAccess = (
  acl: string[],
  access: string | string[]
): boolean => {
  if (access === "IGNORE") {
    return true;
  }

  if (typeof access === "string") {
    return acl.includes(access);
  } else {
    let allow = false;
    access.forEach((item: any) => {
      if (acl.includes(item)) {
        allow = true;
      }
    });
    return allow;
  }
};

export const handleSaveResponseError = (error: AxiosError) => {
  const tempErrors: any[] = error?.response?.data?.errors;
  if (tempErrors && !_.isEmpty(tempErrors)) {
    const flattenError: any[] = Object.values(tempErrors).flat();
    if (flattenError.length > 0) {
      openNotification("error", flattenError[0]);
    }
  } else {
    openNotification("error", error?.response?.data.message);
  }
};

export const handleSaveResponseSuccess = (
  response: AxiosResponse,
  message: string | null,
  navigate?: NavigateFunction
) => {
  openNotification(
    response.data.success ? "success" : "error",
    message || response.data.message
  );

  if (navigate) navigate(-1);
};

export const convertLabelInValue = (
  data: any,
  labelKey: string = "name",
  valueKey: string = "id"
) => {
  let result: any = null;

  if (data) {
    if (data.length > 0) {
      result = data.map((item: any) => ({
        value: item[valueKey],
        label: item[labelKey],
      }));
    } else {
      result = {
        value: data[valueKey],
        label: data[labelKey],
      };
    }
  }

  return result;
};
