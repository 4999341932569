import { useNavigate } from "react-router-dom";
import Spin from "antd/lib/spin";
import Button from "antd/es/button";
import { Row, Space } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { TOptions } from "i18next";
import VuiAccessible from "../../Accessible";

interface IVuiFormAction {
  isLoading: boolean;
  label?: string;
  labelOption?: TOptions | string;
  cancelLabel?: string;
  saveLabel?: string;
  permission?: string;
}

const VuiFormAction = ({
  isLoading,
  label = "common.text.addItem",
  labelOption = "",
  cancelLabel = "common.button.cancel",
  saveLabel = "common.button.save",
  permission = "",
}: IVuiFormAction) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Row className="mt24" justify={"space-between"} align={"middle"}>
      <div>{t(label, labelOption)}</div>

      <Space>
        <Button type="primary" ghost onClick={() => navigate(-1)}>
          {t(cancelLabel)}
        </Button>

        <VuiAccessible access={permission}>
          <Spin spinning={isLoading}>
            <Button style={{ minWidth: 72 }} htmlType="submit" type="primary">
              {t(saveLabel)}
            </Button>
          </Spin>
        </VuiAccessible>
      </Space>
    </Row>
  );
};

export default VuiFormAction;
