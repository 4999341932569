import { Button, Checkbox, Col, DatePicker, Form, Input, Row } from "antd/lib";
import Modal from "antd/lib/modal";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import VuiSelectSingle from "../../../../../@vodea/vodea-ui/components/Select/Single";
import CategoryRepository from "../../../../../repositories/CategoryRepository";
import OperatorRepository from "../../../../../repositories/OperatorRepository";
import ProductRepository from "../../../../../repositories/ProductRepository";
import { VuiInputCurrency } from "../../../../../@vodea/vodea-ui/components";

interface Props {
  show: boolean;
  onCancel: () => void;
  onSave: (data: any) => void;
}

const ModalAddDirectBatch: React.FC<Props> = ({ show, onCancel, onSave }) => {
  const { t } = useTranslation();

  // form
  const [form] = Form.useForm();
  const selectedCategory = Form.useWatch("category", form);
  const selectedProduct = Form.useWatch("product", form);

  useEffect(() => {
    if (show) {
      form.resetFields();
    }
  }, [show]);

  const onFinish = async (data: any) => {
    const formData = {
      id: Date.now(),
      quantity: Number(data.quantity),
      price: Number(data.price),
      is_multiple_redeem: Boolean(data.is_multiple_redeem),
      valid_from: moment(data.valid_from).format("YYYY-MM-DD"),
      valid_to: moment(data.valid_to).format("YYYY-MM-DD"),
      category: {
        id: data.category.value,
        name: data.category.label,
      },
      product: {
        id: data.product.value,
        name: data.product.label,
      },
      variant: {
        id: data.variant.value,
        name: data.variant.label,
      },
    };

    onSave(formData);
    onCancel();
  };

  return (
    <Modal
      title={t("common.text.addItem", { item: t("common.text.order") })}
      centered
      visible={show}
      onCancel={onCancel}
      footer={null}
      width={750}
    >
      <div className="content">
        <Form
          className="text-align-left"
          form={form}
          layout={"vertical"}
          onFinish={onFinish}
        >
          <Row gutter={16}>
            <Col span={8} xs={24} md={12}>
              <Form.Item
                name="category"
                label={t("common.form.category.label")}
                rules={[
                  {
                    required: true,
                    message: t("validation.required", {
                      item: t("common.form.category.label"),
                    }),
                  },
                ]}
              >
                <VuiSelectSingle
                  onChange={() => {
                    form.setFieldsValue({
                      product: null,
                      variant: null,
                    });
                  }}
                  repository={CategoryRepository}
                  placeholder={t("common.form.category.placeholder")}
                />
              </Form.Item>
            </Col>
            <Col span={8} xs={24} md={12}>
              <Form.Item
                name="product"
                label={t("common.form.product.label")}
                rules={[
                  {
                    required: true,
                    message: t("validation.required", {
                      item: t("common.form.product.label"),
                    }),
                  },
                ]}
              >
                <VuiSelectSingle
                  onChange={() => {
                    form.setFieldsValue({
                      variant: null,
                    });
                  }}
                  disabled={!selectedCategory}
                  repository={OperatorRepository}
                  selectParams={{ category: selectedCategory?.value }}
                  placeholder={t("common.form.product.placeholder")}
                />
              </Form.Item>
            </Col>
            <Col span={8} xs={24}>
              <Form.Item
                name="variant"
                label={t("common.form.item.label")}
                rules={[
                  {
                    required: true,
                    message: t("validation.required", {
                      item: t("common.form.item.label"),
                    }),
                  },
                ]}
              >
                <VuiSelectSingle
                  disabled={!selectedProduct}
                  repository={ProductRepository}
                  selectParams={{ operator: selectedProduct?.value }}
                  placeholder={t("common.form.item.placeholder")}
                />
              </Form.Item>
            </Col>
            <Col md={12} xs={24}>
              <Form.Item
                name={"valid_from"}
                label={t("common.form.validFrom.label")}
                rules={[
                  {
                    required: true,
                    message: t("validation.required", {
                      item: "Date",
                    }),
                  },
                ]}
              >
                <DatePicker
                  style={{ width: "100%" }}
                  placeholder={t("common.form.validFrom.placeholder")}
                  format="DD-MM-YYYY"
                />
              </Form.Item>
            </Col>
            <Col md={12} xs={24}>
              <Form.Item
                name={"valid_to"}
                label={t("common.form.validUntil.label")}
                rules={[
                  {
                    required: true,
                    message: t("validation.required", {
                      item: "Date",
                    }),
                  },
                ]}
              >
                <DatePicker
                  style={{ width: "100%" }}
                  placeholder={t("common.form.validUntil.placeholder")}
                  format="DD-MM-YYYY"
                />
              </Form.Item>
            </Col>
            <Col span={24} md={8}>
              <Form.Item
                name="quantity"
                label={t("common.form.quantity.label")}
                rules={[
                  {
                    required: true,
                    message: t("validation.required", {
                      item: t("common.form.quantity.label"),
                    }),
                  },
                ]}
              >
                <Input
                  type="number"
                  placeholder={t("common.form.quantity.placeholder")}
                />
              </Form.Item>
            </Col>
            <Col span={24} md={16}>
              <Form.Item
                name="price"
                label={t("common.form.price.label")}
                rules={[
                  {
                    required: true,
                    message: t("validation.required", {
                      item: t("common.form.price.label"),
                    }),
                  },
                ]}
              >
                <VuiInputCurrency
                  prefix="Rp "
                  placeholder={t("common.form.price.placeholder")}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <div className="vui-form-group">
                <div className="input-section">
                  <Form.Item
                    label={t("common.text.rules")}
                    name="is_multiple_redeem"
                    valuePropName="checked"
                  >
                    <Checkbox>
                      {t("salesOrder.checkbox.multipleRedemption")}
                    </Checkbox>
                  </Form.Item>
                </div>
              </div>
            </Col>
          </Row>
          <div className="vui-form-btn-group">
            <Button key="back" onClick={onCancel}>
              Back
            </Button>

            <Button key="submit" type="primary" htmlType="submit">
              Save
            </Button>
          </div>
        </Form>
      </div>
    </Modal>
  );
};

export default ModalAddDirectBatch;
