import AuthLayout from "../layouts/Auth";
import AuthLogin from "../pages/Auth/Login";
import AuthForgotPassword from "../pages/Auth/ForgotPassword";
import AuthResetPassword from "../pages/Auth/ResetPassword";
import AuthResetPasswordSuccess from "../pages/Auth/ResetPassword/Success";

const authRoutes = [
  {
    path: "/",
    element: <AuthLayout />,
    children: [
      { path: "/login", element: <AuthLogin /> },
      { path: "/forgot-password", element: <AuthForgotPassword /> },
      { path: "/reset-password", element: <AuthResetPassword /> },
      {
        path: "/reset-password/success",
        element: <AuthResetPasswordSuccess />,
      },
    ],
  },
];

export default authRoutes;
