import './style.less'
import React from "react";
import Card from "antd/es/card";
import {Link} from "react-router-dom";

interface Props {
    title?: string
    viewLink: string
    viewText: string
    action?: React.ReactNode
}

const VuiCardMore: React.FC<Props> = (
    {
        title,
        viewLink,
        viewText,
        action,
        children
    }
) => {
    return (
        <>
            <Card className="vui-card-more" title={title}
                  extra={action}
                  actions={[
                      <Link to={viewLink}>{viewText}</Link>,
                  ]}
            >
                {children}
            </Card>
        </>
    )
}

export default VuiCardMore
