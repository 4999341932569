import "./style.less";
import React, { useMemo } from "react";
import Typography from "antd/es/typography";
import Space from "antd/es/space";
import { VuiBackIcon } from "../../Icon";
import clsx from "clsx";

interface Props {
  title?: string;
  customChildren?: boolean;
  onBack?: () => void;
}

const { Title } = Typography;

const VuiSectionTitle: React.FC<Props> = ({
  title = "",
  customChildren = false,
  onBack,
  children,
}) => {
  const renderChildren = useMemo(
    () =>
      customChildren ? (
        <>{children}</>
      ) : (
        <Space size={10} wrap={true} className="btn-wrapper">
          {children}
        </Space>
      ),
    [customChildren, children]
  );

  const renderBackIcon = useMemo(
    () =>
      onBack && (
        <VuiBackIcon
          style={{ marginRight: 12, cursor: "pointer" }}
          onClick={onBack}
        />
      ),
    [onBack]
  );

  return (
    <div className="vui-section-title">
      <div className={clsx(["left-section", onBack ? "with-icon" : ""])}>
        {renderBackIcon}

        <Title
          level={4}
          style={{
            marginBottom: 0,
          }}
        >
          {title}
        </Title>
      </div>

      <div className="right-section">{renderChildren}</div>
    </div>
  );
};

export default VuiSectionTitle;
