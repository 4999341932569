import { Tag, Tooltip } from "antd";
import Button from "antd/es/button";
import Col from "antd/es/grid/col";
import Row from "antd/es/grid/row";
import Table from "antd/es/table";
import { ColumnType } from "antd/es/table/interface";
import { AxiosError, AxiosResponse } from "axios";
import _ from "lodash";
import { FC, useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import {
  VuiActionTable,
  VuiSearch,
} from "../../../../../@vodea/vodea-ui/components";
import VuiAccessible from "../../../../../@vodea/vodea-ui/components/Accessible";
import VuiContainer from "../../../../../@vodea/vodea-ui/components/Container";
import VuiContent from "../../../../../@vodea/vodea-ui/components/Content";
import VuiSectionTitle from "../../../../../@vodea/vodea-ui/components/Sections/Title";
import VuiSelectSingle from "../../../../../@vodea/vodea-ui/components/Select/Single";
import {
  ACCESS_PERMISSION,
  PRODUCT_DELIVERY_STATUS_COLOR,
  RECENT_FILTER_KEY,
} from "../../../../../constant";
import { recentFilterData } from "../../../../../functions/global";
import CompanyRepository from "../../../../../repositories/CompanyRepository";
import ConstantRepository from "../../../../../repositories/ConstantRepository";
import ProductDeliveryRepository from "../../../../../repositories/ProductDeliveryRepository";
import { IBreadcrumbs } from "../../../../../stores/breadcrumbs";
import {
  ConstantService,
  ITableQuery,
  UrlParamsType,
  useBreadcrumbs,
  useDebounce,
  useIsMounted,
} from "../../../../../utils";

const qs = require("qs");

interface IParams extends UrlParamsType {
  company: string | null;
  type: string | null;
  status: string | null;
}

interface IAppOrderProductDeliveryList {
  breadcrumbs: IBreadcrumbs[];
}

const AppOrderProductDeliveryList: FC<IAppOrderProductDeliveryList> = ({
  breadcrumbs,
}) => {
  useBreadcrumbs(breadcrumbs);
  const { t } = useTranslation();
  const hasInitialize = useRef(false);
  const isMounted = useIsMounted();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const [search, setSearch] = useState<string>(
    searchParams.get("search") || ""
  );
  const debouncedSearch = useDebounce<string>(search, 500);
  const [tableQuery, setTableQuery] = useState<ITableQuery>({
    page: Number(
      searchParams.get("page") || ConstantService.getConstant("DEFAULT_PAGE")
    ),
    perPage: Number(
      searchParams.get("perPage") ||
        ConstantService.getConstant("DEFAULT_PER_PAGE")
    ),
    sortField: searchParams.get("sortField") || null,
    sortOrder: searchParams.get("sortOrder") || null,
  });
  const [tableLoading, setTableLoading] = useState<boolean>(false);
  const [tableData, setTableData] = useState<any[]>([]);
  const [totalData, setTotalData] = useState<number>(0);

  // filter
  const [selectedCompany, setSelectedCompany] = useState<any>(
    searchParams.get("company")
      ? recentFilterData.getSelectedFilter(RECENT_FILTER_KEY.company, [
          Number(searchParams.get("company")),
        ])[0]
      : null
  );
  const [selectedDeliveryType, setSelectedDeliveryType] = useState<any>(
    searchParams.get("type")
      ? recentFilterData.getSelectedFilter(RECENT_FILTER_KEY.deliveryType, [
          Number(searchParams.get("type")),
        ])[0]
      : null
  );
  const [selectedStatus, setSelectedStatus] = useState<any>(
    searchParams.get("status")
      ? recentFilterData.getSelectedFilter(
          RECENT_FILTER_KEY.orderDeliveryStatus,
          [Number(searchParams.get("status"))]
        )[0]
      : null
  );

  const tableColumns: ColumnType<any>[] = [
    {
      title: t("common.text.orderNumber"),
      dataIndex: "number",
      key: "number",
    },
    {
      title: t("common.text.deliveryType"),
      dataIndex: "delivery_type",
      key: "delivery_type",
    },
    {
      title: t("common.text.company"),
      dataIndex: "company_name",
      key: "company_name",
    },
    {
      title: t("common.text.item"),
      dataIndex: "items",
      key: "items",
      render: (text: any) => {
        const cloneItems = [...text];
        cloneItems.shift();

        if (text.length > 1) {
          return (
            <div>
              <span>{text[0]}, </span>
              <Tooltip title={cloneItems.join(", ")}>
                <span>{`+${cloneItems.length}`}</span>
              </Tooltip>
            </div>
          );
        } else {
          return <span>{text[0]}</span>;
        }
      },
    },
    {
      title: t("common.text.status"),
      dataIndex: "status_name",
      key: "status_name",
      render: (text: any) => {
        return (
          <Tag color={_.get(PRODUCT_DELIVERY_STATUS_COLOR, text)}>{text}</Tag>
        );
      },
    },

    {
      title: "",
      key: "action",
      render: (text: any, record: any) => (
        <VuiAccessible access={ACCESS_PERMISSION.orderDelivery.show}>
          <VuiActionTable<any> detailLink={`${pathname}/${record.id}`} />
        </VuiAccessible>
      ),
    },
  ];

  const loadData = async (searchParams: any) => {
    if (!isMounted || !searchParams.get("page")) {
      return;
    }

    setTableLoading(true);

    const params: IParams = {
      search: searchParams.get("search"),
      page: Number(searchParams.get("page")),
      per_page: Number(searchParams.get("perPage")),
      order_by: searchParams.get("sortField"),
      sorted_by: searchParams.get("sortOrder"),
      company: searchParams.get("company"),
      type: searchParams.get("type"),
      status: searchParams.get("status"),
    };

    await ProductDeliveryRepository.findAll(params)
      .then((response: AxiosResponse) => {
        if (isMounted) {
          const { data: responseData, meta } = response.data;
          setTableLoading(false);
          setTableData(responseData);
          setTotalData(meta.total);
        }
      })
      .catch((error: AxiosError) => {
        if (isMounted) {
          setTableLoading(false);
        }
      });

    hasInitialize.current = true;
  };

  const handleTableChange = useCallback(
    (pagination: any, filters: any, sorter: any, extra: any) => {
      setTableQuery((prevState) => ({
        ...prevState,
        page: pagination.current,
        perPage: pagination.pageSize,
        ...(sorter.order ? { sortOrder: sorter.order } : {}),
        ...(sorter.field ? { sortField: sorter.field } : {}),
      }));
    },
    []
  );

  const setQueryParams = useCallback(() => {
    const params: Partial<IParams> = {};

    Object.assign(params, {
      ...(debouncedSearch ? { search: debouncedSearch } : {}),
      ...(tableQuery.page ? { page: tableQuery.page } : {}),
      ...(tableQuery.perPage ? { perPage: tableQuery.perPage } : {}),
      ...(tableQuery.sortField ? { sortField: tableQuery.sortField } : {}),
      ...(tableQuery.sortOrder ? { sortOrder: tableQuery.sortOrder } : {}),
      ...(selectedCompany ? { company: selectedCompany.value } : {}),
      ...(selectedDeliveryType ? { type: selectedDeliveryType.value } : {}),
      ...(selectedStatus ? { status: selectedStatus.value } : {}),
    });

    const queryParams = qs.stringify(params, { indices: false });

    if (queryParams) {
      setSearchParams(`?${queryParams}`);
    } else {
      navigate("");
    }
  }, [
    setSearchParams,
    navigate,
    debouncedSearch,
    tableQuery,
    selectedCompany,
    selectedStatus,
    selectedDeliveryType,
  ]);

  // Lifecycle
  useEffect(() => {
    loadData(searchParams);
  }, [searchParams]);

  const handleReset = () => {
    setSelectedDeliveryType(null);
    setSelectedCompany(null);
    setSelectedStatus(null);
  };

  useEffect(() => {
    isMounted ? setQueryParams() : setSearchParams("");
  }, [isMounted, tableQuery]);

  useEffect(() => {
    if (isMounted && hasInitialize.current) {
      setTableQuery((s) => ({
        ...s,
        page: Number(ConstantService.getConstant("DEFAULT_PAGE")),
      }));
    }
  }, [debouncedSearch, selectedCompany, selectedDeliveryType, selectedStatus]);

  return (
    <>
      <VuiContainer>
        <VuiSectionTitle title={t("productDelivery.title")} />

        <VuiContent>
          <Row gutter={[10, 10]} className="mb24">
            <Col className="gutter-row" span={6} xs={24} md={12} lg={10}>
              <VuiSearch
                allowClear
                defaultValue={search}
                onSearch={(value) => setSearch(value)}
              />
            </Col>
          </Row>

          <Row gutter={[10, 10]} className="mb16">
            <Col className="gutter-row">
              <VuiSelectSingle
                value={selectedDeliveryType}
                onChange={(value: any) => {
                  setSelectedDeliveryType(value);
                  recentFilterData.save(RECENT_FILTER_KEY.deliveryType, [
                    value,
                  ]);
                }}
                labelKey="label"
                repository={ConstantRepository}
                selectParams={{ for: "delivery_type" }}
                placeholder={t("common.text.deliveryType")}
              />
            </Col>
            <Col>
              <VuiSelectSingle
                value={selectedCompany}
                onChange={(value: any) => {
                  setSelectedCompany(value);
                  recentFilterData.save(RECENT_FILTER_KEY.company, [value]);
                }}
                style={{ minWidth: "160px" }}
                repository={CompanyRepository}
                placeholder={t("common.text.company")}
              />
            </Col>
            <Col>
              <VuiSelectSingle
                value={selectedStatus}
                onChange={(value: any) => {
                  setSelectedStatus(value);
                  recentFilterData.save(RECENT_FILTER_KEY.orderDeliveryStatus, [
                    value,
                  ]);
                }}
                labelKey="label"
                style={{ minWidth: "160px" }}
                repository={ConstantRepository}
                selectParams={{ for: "status_order_delivery" }}
                placeholder={t("common.text.status")}
              />
            </Col>
            <Col>
              <Button type="link" onClick={handleReset}>
                Reset
              </Button>
            </Col>
          </Row>

          <Table
            rowKey={"id"}
            bordered
            columns={tableColumns}
            dataSource={tableData}
            loading={tableLoading}
            onChange={handleTableChange}
            pagination={
              tableData.length > 0
                ? {
                    current: tableQuery.page,
                    pageSize: tableQuery.perPage,
                    total: totalData,
                  }
                : false
            }
          />
        </VuiContent>
      </VuiContainer>
    </>
  );
};

export default AppOrderProductDeliveryList;
