import React from "react";

const VuiBackIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="25"
    height="26"
    viewBox="0 0 25 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.5 0.5C5.60754 0.5 0 6.10755 0 13C0 19.8925 5.60754 25.5 12.5 25.5C19.3925 25.5 25 19.8925 25 13C25 6.10755 19.3925 0.5 12.5 0.5ZM12.5 2.375C18.3791 2.375 23.125 7.12087 23.125 13C23.125 18.8791 18.3791 23.625 12.5 23.625C6.62087 23.625 1.875 18.8791 1.875 13C1.875 7.12087 6.62087 2.375 12.5 2.375ZM14.0442 6.74146C13.8007 6.74855 13.5696 6.85012 13.3997 7.02466L8.39966 12.0247C8.22391 12.2005 8.12518 12.4389 8.12518 12.6875C8.12518 12.9361 8.22391 13.1745 8.39966 13.3503L13.3997 18.3503C13.486 18.4403 13.5895 18.5121 13.704 18.5616C13.8185 18.6111 13.9417 18.6373 14.0664 18.6385C14.1912 18.6398 14.3149 18.6162 14.4304 18.569C14.5459 18.5219 14.6508 18.4522 14.739 18.364C14.8272 18.2758 14.8969 18.1709 14.944 18.0554C14.9912 17.9399 15.0148 17.8162 15.0135 17.6914C15.0123 17.5667 14.9861 17.4435 14.9366 17.329C14.8871 17.2145 14.8153 17.111 14.7253 17.0247L10.3882 12.6875L14.7253 8.35034C14.8606 8.21862 14.9529 8.04914 14.9903 7.86407C15.0276 7.679 15.0082 7.48698 14.9346 7.31312C14.861 7.13927 14.7366 6.99168 14.5777 6.88968C14.4189 6.78768 14.2329 6.73602 14.0442 6.74146V6.74146Z"
      fill="black"
    />
  </svg>
);

export default VuiBackIcon;
