import PageHeader from "antd/es/page-header";
import React from "react";
import VuiContainer from "../../../../@vodea/vodea-ui/components/Container";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Form from "antd/lib/form";
import { useState } from "@hookstate/core";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import Card from "antd/lib/card";
import Space from "antd/lib/space";
import Button from "antd/es/button";
import Spin from "antd/lib/spin";
import Input from "antd/es/input";
import VuiFormTitle from "../../../../@vodea/vodea-ui/components/Form/Title";
import AccountRepository from "../../../../repositories/AccountRepository";
import { AxiosError, AxiosResponse } from "axios";
import { openNotification, useBreadcrumbs } from "../../../../utils";
import { IBreadcrumbs } from "../../../../stores/breadcrumbs";
import {
  VuiFormAction,
  VuiFormLayout,
} from "../../../../@vodea/vodea-ui/components";
import VuiContent from "../../../../@vodea/vodea-ui/components/Content";
import VuiSectionTitle from "../../../../@vodea/vodea-ui/components/Sections/Title";

interface IAppAccountChangePassword {
  breadcrumbs: IBreadcrumbs[];
}

const AppAccountChangePassword: React.FC<IAppAccountChangePassword> = ({
  breadcrumbs,
}) => {
  useBreadcrumbs(breadcrumbs);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const title = t("changePassword.title");
  const pageTitle = t("common.text.editItem", { item: title });

  // Detail
  const [form] = Form.useForm();
  const disable = useState(false);
  const loading = useState(false);

  const onFinish = async (data: any) => {
    loading.set(true);

    let payload = {
      old_password: data.old_password,
      password: data.password,
      password_confirmation: data.password_confirmation,
    };

    await AccountRepository.updatePassword(payload)
      .then((res: AxiosResponse) => {
        openNotification(
          "success",
          t("notification.success.updateItem", { item: title })
        );
        loading.set(false);
        form.resetFields();
      })
      .catch((e: AxiosError) => {
        const errors = e.response?.data;

        Object.keys(errors).forEach((key: any) => {
          form.setFields([
            {
              name: key,
              errors: errors[key],
            },
          ]);
        });

        openNotification("error", t("notification.error.default"));
        loading.set(false);
      });
  };

  return (
    <>
      <VuiContainer>
        <VuiSectionTitle
          onBack={() => navigate(-1)}
          title={t("common.text.changePassword")}
        />

        <VuiContent>
          <VuiFormLayout form={form} layout={"vertical"} onFinish={onFinish}>
            <Row>
              <Col md={16} xs={24}>
                <Card title={t("common.text.itemInformation", { item: title })}>
                  <Space
                    style={{
                      width: "100%",
                    }}
                    direction="vertical"
                    size={5}
                  >
                    <div className="vui-form-group type-column">
                      <div className="input-section">
                        <Form.Item
                          name="old_password"
                          label={t("common.form.password.label")}
                          rules={[
                            {
                              required: true,
                              message: t("validation.required", {
                                item: t("common.form.password.label"),
                              }),
                            },
                            {
                              min: 8,
                              message: t("validation.type.min", {
                                item: t("common.form.password.label"),
                                min: 8,
                              }),
                            },
                          ]}
                        >
                          <Input.Password
                            placeholder={t("common.form.password.placeholder")}
                          />
                        </Form.Item>
                      </div>
                    </div>

                    <div className="vui-form-group type-column">
                      <div className="input-section">
                        <Form.Item
                          name="password"
                          label={t("common.form.newPassword.label")}
                          rules={[
                            {
                              required: true,
                              message: t("validation.required", {
                                item: t("common.form.newPassword.label"),
                              }),
                            },
                            {
                              min: 8,
                              message: t("validation.type.min", {
                                item: t("common.form.newPassword.label"),
                                min: 8,
                              }),
                            },
                          ]}
                        >
                          <Input.Password
                            placeholder={t(
                              "common.form.newPassword.placeholder"
                            )}
                          />
                        </Form.Item>
                      </div>
                    </div>

                    <div className="vui-form-group type-column">
                      <div className="input-section">
                        <Form.Item
                          name="password_confirmation"
                          label={t("common.form.newPasswordConfirmation.label")}
                          dependencies={["password"]}
                          rules={[
                            {
                              required: true,
                              message: t("validation.required", {
                                item: t(
                                  "common.form.newPasswordConfirmation.label"
                                ),
                              }),
                            },
                            {
                              min: 8,
                              message: t("validation.type.min", {
                                item: t(
                                  "common.form.newPasswordConfirmation.label"
                                ),
                                min: 8,
                              }),
                            },
                            ({ getFieldValue }) => ({
                              validator(_, value) {
                                if (
                                  !value ||
                                  getFieldValue("password") === value
                                ) {
                                  return Promise.resolve();
                                }
                                return Promise.reject(
                                  new Error(
                                    t("validation.type.match", {
                                      item: t(
                                        "common.form.newPasswordConfirmation.label"
                                      ),
                                    })
                                  )
                                );
                              },
                            }),
                          ]}
                        >
                          <Input.Password
                            placeholder={t(
                              "common.form.newPasswordConfirmation.placeholder"
                            )}
                          />
                        </Form.Item>
                      </div>
                    </div>
                  </Space>
                </Card>
              </Col>
            </Row>

            <VuiFormAction
              label={"common.text.saveChange"}
              saveLabel={"common.button.save"}
              isLoading={loading.get()}
            />
          </VuiFormLayout>
        </VuiContent>
      </VuiContainer>
    </>
  );
};

export default AppAccountChangePassword;
