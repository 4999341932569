import { Button, Col, DatePicker, Form, Input, Row } from "antd/lib";
import Modal from "antd/lib/modal";
import moment from "moment";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  show: boolean;
  onCancel: () => void;
  onSave: (data: any) => void;
}

const ModalAddLoyaltyBatch: React.FC<Props> = ({ show, onCancel, onSave }) => {
  const { t } = useTranslation();

  // form
  const [form] = Form.useForm();

  useEffect(() => {
    if (show) {
      form.resetFields();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  const onFinish = async (data: any) => {
    const formData = {
      ...data,
      valid_from: moment(data.valid_from).format("YYYY-MM-DD"),
      valid_to: moment(data.valid_to).format("YYYY-MM-DD"),
    };
    onSave(formData);
    onCancel();
  };

  return (
    <Modal
      title={t("common.text.addItem", { item: t("common.text.batch") })}
      centered
      visible={show}
      onCancel={onCancel}
      footer={null}
      width={750}
    >
      <div className="content">
        <Form
          className="text-align-left"
          form={form}
          layout={"vertical"}
          onFinish={onFinish}
        >
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="point"
                label={t("common.form.point.label")}
                rules={[
                  {
                    required: true,
                    message: t("validation.required", {
                      item: t("common.form.point.label"),
                    }),
                  },
                ]}
              >
                <Input
                  type="number"
                  placeholder={t("common.form.point.placeholder")}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="quantity"
                label={t("common.form.quantity.label")}
                rules={[
                  {
                    required: true,
                    message: t("validation.required", {
                      item: t("common.form.quantity.label"),
                    }),
                  },
                ]}
              >
                <Input
                  type="number"
                  placeholder={t("common.form.quantity.placeholder")}
                />
              </Form.Item>
            </Col>
            <Col md={12} xs={24}>
              <Form.Item
                name={"valid_from"}
                label={t("common.form.validFrom.label")}
                rules={[
                  {
                    required: true,
                    message: t("validation.required", {
                      item: "Date",
                    }),
                  },
                ]}
              >
                <DatePicker
                  style={{ width: "100%" }}
                  placeholder={t("common.form.validFrom.placeholder")}
                  format="DD-MM-YYYY"
                />
              </Form.Item>
            </Col>
            <Col md={12} xs={24}>
              <Form.Item
                name={"valid_to"}
                label={t("common.form.validUntil.label")}
                rules={[
                  {
                    required: true,
                    message: t("validation.required", {
                      item: "Date",
                    }),
                  },
                ]}
              >
                <DatePicker
                  style={{ width: "100%" }}
                  placeholder={t("common.form.validUntil.placeholder")}
                  format="DD-MM-YYYY"
                />
              </Form.Item>
            </Col>
          </Row>
          <div className="vui-form-btn-group">
            <Button key="back" onClick={onCancel}>
              Back
            </Button>

            <Button key="submit" type="primary" htmlType="submit">
              Save
            </Button>
          </div>
        </Form>
      </div>
    </Modal>
  );
};

export default ModalAddLoyaltyBatch;
