import "./style.less";
import React from "react";
import Card from "antd/es/card";
import { ArrowDownOutlined, ArrowUpOutlined } from "@ant-design/icons";
import clsx from "clsx";
import { Skeleton } from "antd";
import Table, { ColumnType } from "antd/lib/table";

export declare type type = "up" | "down";

interface Props {
  title: string;
  data: any[];
  columns: ColumnType<any>[];
  percentage: string;
  type: type;
  comparison: string;
  loading?: boolean;
}

const VuiCardTable: React.FC<Props> = ({
  title,
  data,
  percentage,
  columns,
  loading,
  type,
  comparison,
}) => {
  return (
    <Card className="vui-table-card">
      <div className="info-wrapper">
        <Skeleton
          loading={loading}
          active
          paragraph={{ rows: 4, width: "100%" }}
        >
          <p className="title mb8">{title}</p>
          <div className="value-wrapper mb16">
            <label className="info-value">
              {comparison}{" "}
              <span
                className={clsx("percentage", {
                  up: type === "up",
                  down: type === "down",
                })}
              >
                {percentage}{" "}
                {type === "up" ? <ArrowUpOutlined /> : <ArrowDownOutlined />}
              </span>
            </label>
          </div>
          <div>
            <Table
              rowKey={"id"}
              bordered
              columns={columns}
              dataSource={data}
              loading={loading}
              pagination={false}
            />
          </div>
        </Skeleton>
      </div>
    </Card>
  );
};

export default VuiCardTable;
