import "./style.less";
import React from "react";
import Card from "antd/es/card";
import { ArrowDownOutlined, ArrowUpOutlined } from "@ant-design/icons";
import clsx from "clsx";
import { Skeleton } from "antd";
import { Line } from "@ant-design/plots";
import { LineConfig } from "@ant-design/plots/es/components/line";

export declare type type = "up" | "down";

export interface IVuiCardChartDataset {
  label: string;
  items_out: number;
}

interface Props {
  title: string;
  dataset: IVuiCardChartDataset[];
  percentage: string;
  type: type;
  comparison: string;
  loading?: boolean;
  additional: {
    label: string;
    currentPeriod: number;
    lastPeriod: number;
  };
}

const VuiCardChart: React.FC<Props> = ({
  title,
  dataset = [],
  percentage,
  loading,
  type,
  comparison,
  additional,
}) => {
  const config: LineConfig = {
    data: dataset,
    xField: "label",
    yField: "items_out",
    xAxis: {
      tickCount: 5,
    },
    height: 300,
    tooltip: {
      formatter: (datum) => {
        return { name: "Redeemed Items", value: datum.items_out };
      },
    },
    smooth: true,
  };

  return (
    <Card className="vui-chart-card">
      <div className="info-wrapper">
        <Skeleton
          loading={loading}
          active
          paragraph={{ rows: 4, width: "100%" }}
        >
          <p className="title mb16">{title}</p>
          <p className="total-value mb8">{`${additional.label} ${additional.currentPeriod}`}</p>
          <div className="value-wrapper mb16">
            <label className="info-value">
              {comparison}{" "}
              <span
                className={clsx("percentage", {
                  up: type === "up",
                  down: type === "down",
                })}
              >
                {percentage}{" "}
                {type === "up" ? <ArrowUpOutlined /> : <ArrowDownOutlined />}
              </span>
            </label>
          </div>

          <div>
            <Line {...config} />
          </div>
        </Skeleton>
      </div>
    </Card>
  );
};

export default VuiCardChart;
