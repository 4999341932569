import { api, createCancelTokenHandler } from "../utils";

const endPoint = () => window._env_.REACT_APP_PASSPORT_END_POINT;

const Repository = {
  giftSent: function (params: any = null) {
    return api.get(`${endPoint()}/api/widget/gift-sent`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.giftSent.name].handleRequestCancellation()
          .token,
    });
  },
  giftNotSent: function (params: any = null) {
    return api.get(`${endPoint()}/api/widget/gift-not-sent`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[
          this.giftNotSent.name
        ].handleRequestCancellation().token,
    });
  },
  orderComplete: function (params: any = null) {
    return api.get(`${endPoint()}/api/widget/order-complete`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[
          this.orderComplete.name
        ].handleRequestCancellation().token,
    });
  },
  orderInProcess: function (params: any = null) {
    return api.get(`${endPoint()}/api/widget/order-in-process`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[
          this.orderInProcess.name
        ].handleRequestCancellation().token,
    });
  },
  mostProductRedeem: function (params: any = null) {
    return api.get(`${endPoint()}/api/widget/most-product-redeem`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[
          this.mostProductRedeem.name
        ].handleRequestCancellation().token,
    });
  },
  productSummary: function (params: any = null) {
    return api.get(`${endPoint()}/api/widget/product-summary`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[
          this.productSummary.name
        ].handleRequestCancellation().token,
    });
  },
};

const cancelTokenHandlerObject = createCancelTokenHandler(Repository);

export default Repository;
