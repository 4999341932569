import "./style.less";
import React, { useCallback, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Menu from "antd/es/menu";
import Dropdown from "antd/es/dropdown";
import {
  BellOutlined,
  CheckOutlined,
  CloseOutlined,
  LogoutOutlined,
  MenuOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { useAppDispatch, useAppSelector } from "../../../../stores/hooks";
import Space from "antd/es/space";
import Badge from "antd/es/badge";
import Avatar from "antd/es/avatar/avatar";
import Typography from "antd/es/typography";
import Drawer from "antd/es/drawer";
import Switch from "antd/es/switch";
import Tooltip from "antd/es/tooltip";
import VuiMainMenu from "../MainMenu";
import { VscGear } from "react-icons/vsc";
import Input from "antd/es/input";
import Tag from "antd/es/tag";
import { generateDefaultMenus } from "../../../../constant/menu";
import { passportService } from "../../../../utils";
import { update } from "../../../../stores/system";
import { getAliasesName } from "../../../../functions/global";
import { VuiBreadcrumbs, VuiSMBLogo } from "../index";
import { Image } from "antd";

const { Text, Paragraph } = Typography;

const VuiHeader: React.FC<any> = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const menus = generateDefaultMenus(t);
  const [showDrawerMenu, setShowDrawerMenu] = useState<boolean>(false);
  const [showNotification, setShowNotification] = useState<boolean>(false);
  const [showSearch, setShowSearch] = useState<boolean>(false);
  const [unread, setUnread] = useState<boolean>(false);

  const system = useAppSelector((state) => state.system);

  const closeDrawerMenu = useCallback(() => setShowDrawerMenu(false), []);

  const logout = useCallback(
    async (e: React.MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();

      await passportService
        .logout()
        .then(() => {
          dispatch(
            update({
              isLoggedIn: false,
              account: null,
            })
          );
        })
        .catch(() => {});
    },
    [dispatch]
  );

  return (
    <>
      <header className="vui-header">
        <div className="brand-wrapper">
          <div className="hide-lg">
            {system.isLoggedIn ? (
              <>
                <MenuOutlined
                  className="menu-icon"
                  style={{
                    fontSize: 18,
                  }}
                  onClick={() => setShowDrawerMenu(true)}
                />

                <Drawer
                  title={
                    <img
                      className="drawer-logo"
                      src={"/images/logo-text.png"}
                      alt="logo"
                    />
                  }
                  placement={"left"}
                  closable={true}
                  onClose={closeDrawerMenu}
                  visible={showDrawerMenu}
                  className="header-menu-drawer"
                >
                  <VuiMainMenu
                    menus={menus}
                    mode="inline"
                    onChange={closeDrawerMenu}
                  />
                </Drawer>
              </>
            ) : null}
          </div>
          <div className="show-lg">
            <Link style={{ display: "flex" }} to={"/"}>
              {/* <VuiSMBLogo /> */}
              <Image
                src={"/images/logo-text.png"}
                width={120}
                preview={false}
              />
              {/*<Text className="text-logo"> Bukado</Text>*/}
            </Link>
          </div>
        </div>

        <div className="show-lg">
          <VuiBreadcrumbs />
        </div>

        <div className="profile-wrapper">
          {system.isLoggedIn ? (
            <div className="auth-wrapper">
              <Space className="auth-spaces" size={15}>
                {/* <Badge dot>
                  <BellOutlined
                    style={{ fontSize: 20 }}
                    onClick={() => setShowNotification(true)}
                  />
                </Badge> */}

                <Dropdown
                  overlayClassName={"main-header-dropdown"}
                  overlay={
                    <Menu>
                      <Menu.Item key="0" className="avatar-menu-item">
                        <div className="avatar-wrapper">
                          <Avatar size="large" className="mb6">
                            {getAliasesName(system?.account?.name || "")}
                          </Avatar>
                          <Text strong>{system.account?.name}</Text>
                        </div>
                      </Menu.Item>

                      <Menu.Divider />

                      <Menu.Item key="3">
                        <Link to={"/account/profile"}>
                          <VscGear className="icon" />{" "}
                          {t("header.profile.view")}
                        </Link>
                      </Menu.Item>

                      <Menu.Item key="4">
                        <Link to={"/account/change-password"}>
                          <VscGear className="icon" />{" "}
                          {t("header.profile.changePassword")}
                        </Link>
                      </Menu.Item>

                      <Menu.Item key="5">
                        <span onClick={logout}>
                          <LogoutOutlined className="icon" />{" "}
                          {t("header.profile.signOut")}
                        </span>
                      </Menu.Item>
                    </Menu>
                  }
                  trigger={["click"]}
                >
                  <div>
                    <Avatar>
                      {getAliasesName(system?.account?.name || "")}
                    </Avatar>
                  </div>
                </Dropdown>
              </Space>
            </div>
          ) : null}
        </div>
      </header>

      <Drawer
        placement="top"
        closable={false}
        onClose={() => setShowSearch(false)}
        visible={showSearch}
        key={"search-drawer"}
        className="header-search-drawer"
      >
        <div className="search-header">
          <Input
            size="large"
            placeholder={t("header.search.placeholder")}
            suffix={
              <SearchOutlined
                style={{
                  fontSize: 18,
                }}
                onClick={() => {}}
              />
            }
            className="search-input"
          />

          <CloseOutlined
            style={{
              fontSize: 18,
            }}
            onClick={() => setShowSearch(false)}
          />
        </div>

        <div className="search-content">
          <Space direction="vertical" size={25}>
            <Space direction="vertical">
              <Text className="search-title" type="secondary">
                {t("header.search.recent")}
              </Text>

              <div>
                <Tag>modern dashboard</Tag>
                <Tag>calendar app</Tag>
                <Tag>modal examples</Tag>
                <Tag>avatar</Tag>
              </div>
            </Space>

            <Space direction="vertical">
              <Text className="search-title" type="secondary">
                {t("header.search.suggestion")}
              </Text>

              <div>
                <Tag>cryptocurrency</Tag>
                <Tag>button groups</Tag>
                <Tag>form elements</Tag>
                <Tag>contact app</Tag>
              </div>
            </Space>
          </Space>
        </div>
      </Drawer>

      <Drawer
        title={t("header.notification.title")}
        closable={true}
        placement="right"
        onClose={() => setShowNotification(false)}
        visible={showNotification}
        className="header-notification-drawer"
        key={"notification-drawer"}
      >
        <div className="action-wrapper">
          <span className="mark-all">{t("header.notification.markAll")}</span>

          <div className="switch-wrapper">
            <span>{t("header.notification.onlyShowUnread")}</span>
            <Switch
              size={"small"}
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
              defaultChecked={false}
              onChange={() => setUnread(!unread)}
            />
          </div>
        </div>

        {unread ? (
          <div className="empty-wrapper">
            <img
              className="icon"
              src={"/images/icon-default.png"}
              alt="empty notifications"
            />
            <p>{t("header.notification.empty.title")}</p>
          </div>
        ) : null}

        {!unread ? (
          <div className="main-notif-wrapper">
            <div className="segment-wrapper">
              <p className="segment-title">
                {t("header.notification.segment.today")}
              </p>

              <div className="notif-list">
                <div className="notif-item">
                  <Avatar>SA</Avatar>

                  <div className="info-wrapper">
                    <Paragraph
                      className="info-title"
                      ellipsis={{
                        rows: 1,
                      }}
                    >
                      Socrates Itumay
                    </Paragraph>

                    <Paragraph
                      className="info-description"
                      ellipsis={{
                        rows: 2,
                      }}
                    >
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s
                    </Paragraph>

                    <label className="info-timestamp">July 19 4.59 pm</label>
                  </div>

                  <div className="status-wrapper">
                    <Tooltip
                      placement="left"
                      title={t("header.notification.markUnread")}
                    >
                      <span className="status unread"></span>
                    </Tooltip>
                  </div>
                </div>
              </div>
            </div>

            <div className="segment-wrapper">
              <p className="segment-title">
                {t("header.notification.segment.older")}
              </p>

              <div className="notif-list">
                <div className="notif-item">
                  <Avatar>SA</Avatar>

                  <div className="info-wrapper">
                    <Paragraph
                      className="info-title"
                      ellipsis={{
                        rows: 1,
                      }}
                    >
                      Socrates Itumay
                    </Paragraph>

                    <Paragraph
                      className="info-description"
                      ellipsis={{
                        rows: 2,
                      }}
                    >
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s
                    </Paragraph>

                    <label className="info-timestamp">July 18 2.59 pm</label>
                  </div>

                  <div className="status-wrapper">
                    <Tooltip
                      placement="left"
                      title={t("header.notification.markRead")}
                    >
                      <span className="status"></span>
                    </Tooltip>
                  </div>
                </div>

                <div className="notif-item">
                  <Avatar>SA</Avatar>

                  <div className="info-wrapper">
                    <Paragraph
                      className="info-title"
                      ellipsis={{
                        rows: 1,
                      }}
                    >
                      Socrates Itumay
                    </Paragraph>

                    <Paragraph
                      className="info-description"
                      ellipsis={{
                        rows: 2,
                      }}
                    >
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s
                    </Paragraph>

                    <label className="info-timestamp">July 17 9.22 am</label>
                  </div>

                  <div className="status-wrapper">
                    <Tooltip
                      placement="left"
                      title={t("header.notification.markRead")}
                    >
                      <span className="status"></span>
                    </Tooltip>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </Drawer>
    </>
  );
};

export default VuiHeader;
