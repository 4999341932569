import { Navigate, Outlet } from "react-router";
import VuiHeader from "../../@vodea/vodea-ui/components/Header";
import { useAppSelector } from "../../stores/hooks";
import VuiMainMenu from "../../@vodea/vodea-ui/components/MainMenu";
import { generateDefaultMenus } from "../../constant/menu";
import VuiLoadingScreen from "../../@vodea/vodea-ui/components/LoadingScreen";
import { usePassportService } from "../../utils";
import { FC } from "react";
import { useTranslation } from "react-i18next";

const AppLayout: FC = () => {
  const { t } = useTranslation();
  const { isOnFetchingUser } = usePassportService();
  const system = useAppSelector((state) => state.system);

  if (isOnFetchingUser) {
    return <VuiLoadingScreen />;
  }

  if (!system.isLoggedIn) {
    return <Navigate to={"/login"} replace />;
  }

  return (
    <section id={"app-layout"}>
      <VuiHeader />
      <div className="container-wrapper">
        <div className="navigation-vertical-wrapper show-lg">
          {system.isLoggedIn ? (
            <>
              <VuiMainMenu menus={generateDefaultMenus(t)} mode="inline" />
            </>
          ) : null}
        </div>

        <Outlet />
      </div>
    </section>
  );
};

export default AppLayout;
