import React from "react";
import {
  VuiDashboardIcon,
  VuiSettingIcon,
  VuiShoppingBagIcon,
} from "../@vodea/vodea-ui/components";
import { TFunction } from "react-i18next";
import { ACCESS_PERMISSION } from ".";

export interface IMenu {
  title: string;
  key: string;
  link: string;
  access: string | string[];
  icon?: React.SVGProps<SVGSVGElement>;
  subMenus?: Pick<IMenu, "title" | "key" | "link" | "subMenus" | "access">[];
}

export const generateDefaultMenus = (t: TFunction): IMenu[] => {
  return [
    {
      title: t("common.text.dashboard"),
      key: "dashboard",
      link: "/dashboard",
      access: "IGNORE",
      icon: <VuiDashboardIcon />,
    },
    {
      title: t("common.text.order"),
      key: "order",
      link: "/order",
      access: "IGNORE",
      icon: <VuiShoppingBagIcon />,
      subMenus: [
        {
          title: t("common.text.salesOrder"),
          key: "sales-order",
          access: ACCESS_PERMISSION.salesOrder.index,
          link: "/order/sales-order",
        },
        {
          title: t("common.text.itemDelivery"),
          key: "product-delivery",
          access: ACCESS_PERMISSION.orderDelivery.index,
          link: "/order/product-delivery",
        },
      ],
    },
    // {
    //   title: t("common.text.report"),
    //   key: "report",
    //   link: "/report",
    //   access: "IGNORE",
    //   icon: <VuiReportIcon />,
    // },
    {
      title: t("common.text.setting"),
      key: "setting",
      link: "/setting",
      access: "IGNORE",
      icon: <VuiSettingIcon />,
      subMenus: [
        {
          title: t("common.text.item"),
          key: "product",
          access: ACCESS_PERMISSION.product.index,
          link: "/setting/product",
        },
        {
          title: t("common.text.account"),
          key: "account",
          link: "/setting/account",
          access: "IGNORE",
          subMenus: [
            {
              title: t("common.text.company"),
              key: "company",
              access: ACCESS_PERMISSION.company.index,
              link: "/setting/account/company",
            },
            {
              title: t("common.text.user"),
              key: "user",
              access: ACCESS_PERMISSION.user.index,
              link: "/setting/account/user",
            },
            {
              title: t("common.text.accessRole"),
              key: "access-role",
              access: ACCESS_PERMISSION.role.index,
              link: "/setting/account/access-role",
            },
          ],
        },
      ],
    },
  ];
};
