import React from "react";
import VuiContainer from "../../../../@vodea/vodea-ui/components/Container";
import PageHeader from "antd/es/page-header";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Form from "antd/lib/form";
import { useState } from "@hookstate/core";
import { Col, Row } from "antd/lib/grid";
import Card from "antd/lib/card";
import Space from "antd/lib/space";
import VuiUploadImages from "../../../../@vodea/vodea-ui/components/UploadImages";
import VuiFormTitle from "../../../../@vodea/vodea-ui/components/Form/Title";
import Input from "antd/es/input";
import { useAppDispatch, useAppSelector } from "../../../../stores/hooks";
import DatePicker from "antd/es/date-picker";
import Button from "antd/es/button";
import Spin from "antd/lib/spin";
import Radio from "antd/es/radio";
import AccountRepository from "../../../../repositories/AccountRepository";
import { AxiosError, AxiosResponse } from "axios";
import { openNotification, useBreadcrumbs } from "../../../../utils";
import moment from "moment";
import { update } from "../../../../stores/system";
import {
  VuiFormAction,
  VuiFormLayout,
} from "../../../../@vodea/vodea-ui/components";
import VuiContent from "../../../../@vodea/vodea-ui/components/Content";
import VuiSectionTitle from "../../../../@vodea/vodea-ui/components/Sections/Title";
import { IBreadcrumbs } from "../../../../stores/breadcrumbs";

interface IAppAccountProfile {
  breadcrumbs: IBreadcrumbs[];
}

const AppAccountProfile: React.FC<IAppAccountProfile> = ({ breadcrumbs }) => {
  useBreadcrumbs(breadcrumbs);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const title = t("profile.title");
  const pageTitle = t("common.text.editItem", { item: title });
  const system = useAppSelector((state) => state.system);

  // Detail
  const [form] = Form.useForm();
  const disable = useState(false);
  const loading = useState(false);

  const onFinish = async (data: any) => {
    loading.set(true);

    const formData = new FormData();

    formData.append("name", data.name);
    formData.append("email", data.email);
    formData.append("mobile_phone", data.mobile_phone);
    formData.append("date_of_birth", data.date_of_birth.format("YYYY-MM-DD"));
    formData.append("gender", data.gender);

    data.avatar.forEach((file: any) => {
      formData.append("avatar[]", file);
    });

    let payload = {
      name: data.name,
      email: data.email,
      mobile_phone: data.mobile_phone,
      date_of_birth: data.date_of_birth.format("YYYY-MM-DD"),
      gender: data.gender,
      avatar: data.avatar,
    };

    await AccountRepository.update(payload)
      .then((res: AxiosResponse) => {
        openNotification(
          "success",
          t("notification.success.updateItem", { item: title })
        );
        dispatch(
          update({
            account: res.data.data,
          })
        );

        loading.set(false);
      })
      .catch((e: AxiosError) => {
        openNotification("error", t("notification.error.default"));
        loading.set(false);
      });
  };

  return (
    <>
      <VuiContainer>
        <VuiSectionTitle
          onBack={() => navigate(-1)}
          title={t("common.text.profile")}
        />

        <VuiContent>
          <VuiFormLayout
            form={form}
            layout={"vertical"}
            onFinish={onFinish}
            initialValues={{
              number: system.account?.number,
              name: system.account?.name,
              email: system.account?.email,
              mobile_phone: system.account?.phone_number,
            }}
          >
            <Row gutter={[16, 16]}>
              <Col
                className="gutter-row"
                md={{
                  span: 16,
                  order: 1,
                }}
                xs={{
                  span: 24,
                  order: 2,
                }}
              >
                <Card title={t("common.text.itemInformation", { item: title })}>
                  <Space
                    style={{
                      width: "100%",
                    }}
                    direction="vertical"
                    size={5}
                  >
                    <div className="vui-form-group type-column">
                      <div className="input-section">
                        <Form.Item name="number" label={"ID"}>
                          <Input disabled={true} />
                        </Form.Item>
                      </div>
                    </div>

                    <div className="vui-form-group type-column">
                      <div className="input-section">
                        <Form.Item
                          name="name"
                          label={t("common.form.name.label")}
                          rules={[
                            {
                              required: true,
                              message: t("validation.required", {
                                item: t("common.form.name.label"),
                              }),
                            },
                          ]}
                        >
                          <Input
                            disabled={true}
                            placeholder={t("common.form.name.placeholder")}
                          />
                        </Form.Item>
                      </div>
                    </div>

                    <div className="vui-form-group type-column">
                      <div className="input-section">
                        <Form.Item
                          name="email"
                          label={t("common.form.email.label")}
                          rules={[
                            {
                              required: true,
                              message: t("validation.required", {
                                item: t("common.form.email.label"),
                              }),
                            },
                          ]}
                        >
                          <Input
                            disabled={true}
                            placeholder={t("common.form.email.placeholder")}
                          />
                        </Form.Item>
                      </div>
                    </div>

                    <div className="vui-form-group type-column">
                      <div className="input-section">
                        <Form.Item
                          name="phone_number"
                          label={t("common.form.phoneNumber.label")}
                          rules={[
                            {
                              required: true,
                              message: t("validation.required", {
                                item: t("common.form.phoneNumber.label"),
                              }),
                            },
                          ]}
                        >
                          <Input disabled={true} placeholder="-" />
                        </Form.Item>
                      </div>
                    </div>
                  </Space>
                </Card>
              </Col>

              <Col
                className="gutter-row"
                md={{
                  span: 8,
                  order: 2,
                }}
                xs={{
                  span: 24,
                  order: 1,
                }}
              >
                <Card title={t("common.text.image")}>
                  <Form.Item name="avatar">
                    <VuiUploadImages />
                  </Form.Item>
                </Card>
              </Col>
            </Row>
            <VuiFormAction
              label={"common.text.saveChange"}
              saveLabel={"common.button.save"}
              isLoading={loading.get()}
            />

            {/* <div className="vui-form-btn-group">
              <Button
                className="vui-btn"
                size={"large"}
                onClick={() => navigate(-1)}
              >
                {t("common.button.cancel")}
              </Button>
              <Spin spinning={loading.get()}>
                <Button
                  className="vui-btn"
                  htmlType="submit"
                  type="primary"
                  size={"large"}
                  disabled={true}
                >
                  {t("common.button.save")}
                </Button>
              </Spin>
            </div> */}
          </VuiFormLayout>
        </VuiContent>
      </VuiContainer>
    </>
  );
};

export default AppAccountProfile;
