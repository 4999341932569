import React, { useEffect } from "react";
import { ThemeSwitcherProvider } from "react-css-theme-switcher";
import ReactDOM from "react-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Provider } from "react-redux";
import { BrowserRouter, useLocation } from "react-router-dom";
import App from "./App";
import "./i18n";
import { store } from "./stores";
import "./styles/style.less";
import { ConfigProvider } from "antd";
import ID from "antd/lib/locale/id_ID";

const themes = {
  dark: `/dark-theme.css`,
  light: `/light-theme.css`,
};

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

ReactDOM.render(
  <Provider store={store}>
    <ThemeSwitcherProvider
      themeMap={themes}
      defaultTheme="light"
      insertionPoint="styles-insertion-point"
    >
      <BrowserRouter>
        <HelmetProvider>
          <Helmet>
            <title>Bukado</title>
          </Helmet>
          <ScrollToTop />
          <ConfigProvider locale={ID}>
            <App />
          </ConfigProvider>
        </HelmetProvider>
      </BrowserRouter>
    </ThemeSwitcherProvider>
  </Provider>,
  document.getElementById("root")
);
