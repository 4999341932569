import { Button, Col, DatePicker, Form, Row } from "antd/lib";
import Modal from "antd/lib/modal";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Input from "antd/es/input";
import SalesOrderRepository from "../../../../../repositories/SalesOrderRepository";
import { AxiosError } from "axios";
import { handleBackendError } from "../../../../../functions/global";
import { openNotification } from "../../../../../utils";

interface Props {
  show: boolean;
  onCancel: () => void;
  onSave: (data: any) => void;
  data: any;
  id: string;
}

type Form = {
  date: string;
  number: string;
};

const ModalUpdateInformation: React.FC<Props> = ({
  show,
  onCancel,
  onSave,
  data = null,
  id,
}) => {
  const { t } = useTranslation();
  const [submitLoading, setSubmitLoading] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    if (show && data && id) {
      form.setFieldsValue({
        date: moment(data.date),
        number: data.number,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show, data]);

  const onFinish = useCallback(
    async (form: Form) => {
      if (!id) {
        return;
      }

      const formData: Form = {
        date: moment(form.date).format("YYYY-MM-DD"),
        number: form.number,
      };

      setSubmitLoading(true);

      await SalesOrderRepository.update(id, formData)
        .then(() => {
          openNotification(
            "success",
            t("notification.success.updateItem", { item: "Information" })
          );
          onSave(form);
          onCancel();
        })
        .catch((e: AxiosError) => {
          handleBackendError(e, t("notification.error.default"));
        })
        .finally(() => {
          setSubmitLoading(false);
        });
    },
    [id]
  );

  return (
    <Modal
      title={t("common.text.editItem", { item: t("common.text.information") })}
      centered
      visible={show}
      onCancel={onCancel}
      footer={null}
      width={400}
    >
      <div className="content">
        <Form
          className="text-align-left"
          form={form}
          layout={"vertical"}
          onFinish={onFinish}
        >
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name={"date"}
                label={t("common.form.date.label")}
                rules={[
                  {
                    required: true,
                    message: t("validation.required", {
                      item: "Date",
                    }),
                  },
                ]}
              >
                <DatePicker
                  style={{ width: "100%" }}
                  placeholder={t("common.form.date.placeholder")}
                  format="DD-MM-YYYY"
                />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                name="number"
                label={t("common.form.orderNumber.label")}
                rules={[
                  {
                    required: true,
                    message: t("validation.required", {
                      item: t("common.form.orderNumber.label"),
                    }),
                  },
                ]}
              >
                <Input placeholder={t("common.form.orderNumber.placeholder")} />
              </Form.Item>
            </Col>
          </Row>
          <div className="vui-form-btn-group">
            <Button key="back" onClick={onCancel}>
              Back
            </Button>

            <Button
              key="submit"
              type="primary"
              htmlType="submit"
              loading={submitLoading}
            >
              Save
            </Button>
          </div>
        </Form>
      </div>
    </Modal>
  );
};

export default ModalUpdateInformation;
