import { Button, Card, Col, Form, Row, Space, Table, Typography } from "antd";
import Input from "antd/es/input";
import { AxiosError, AxiosResponse } from "axios";
import _ from "lodash";
import React, { FC, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useState } from "@hookstate/core";
import {
  VuiFormAction,
  VuiFormCard,
  VuiFormLayout,
} from "../../../../../../@vodea/vodea-ui/components";
import VuiContainer from "../../../../../../@vodea/vodea-ui/components/Container";
import VuiContent from "../../../../../../@vodea/vodea-ui/components/Content";
import VuiSectionTitle from "../../../../../../@vodea/vodea-ui/components/Sections/Title";
import VuiSelectMulti from "../../../../../../@vodea/vodea-ui/components/Select/Multi";
import VuiUploadImage from "../../../../../../@vodea/vodea-ui/components/UploadImages";
import { ACCESS_PERMISSION } from "../../../../../../constant";
import {
  handleBackendError,
  toFileList,
} from "../../../../../../functions/global";
import CompanyRepository from "../../../../../../repositories/CompanyRepository";
import UserRepository from "../../../../../../repositories/UserRepository";
import { IBreadcrumbs } from "../../../../../../stores/breadcrumbs";
import {
  convertLabelInValue,
  openNotification,
  useBreadcrumbs,
  useIsMounted,
} from "../../../../../../utils";
import ModalAddCampaign from "../Components/ModalAddCampaign";
import { ColumnType } from "antd/lib/table";

const { Text } = Typography;

interface IAppSettingAccountCompanyForm {
  breadcrumbs: IBreadcrumbs[];
}

const AppSettingAccountCompanyForm: FC<IAppSettingAccountCompanyForm> = ({
  breadcrumbs,
}) => {
  useBreadcrumbs(breadcrumbs);
  const { id } = useParams();
  const { t } = useTranslation();
  const title = t("common.text.company");
  const isMounted = useIsMounted();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [showCampaignForm, setShowCampaignForm] = React.useState(false);
  const [tableData, setTableData] = React.useState<any[]>([]);
  const tableColumns: ColumnType<any>[] = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: t("common.text.name"),
      dataIndex: "name",
      key: "name",
    },
    {
      title: t("common.text.type"),
      render: (text: any, record: any) => <>{record.type.label}</>,
    },
    {
      title: "Action",
      key: "action",
      render: (text: any, record: any) => {
        return (
          <Space size="middle">
            {record.tempId && (
              <Text
                className="cursor-pointer"
                type={"danger"}
                onClick={() => {
                  const newTableData = tableData.filter(
                    (item) => item.tempId !== record.tempId
                  );

                  setTableData(newTableData);
                }}
              >
                Delete
              </Text>
            )}
          </Space>
        );
      },
    },
  ];

  const onFinish = useCallback(
    (values: any) => {
      setIsLoading(true);

      const payload = {
        ...values,
        logo_id: _.get(values, "logo_id[0].id"),
        user_ids: _.map(values.user_ids, "value"),
      };

      const newCampaigns = tableData.filter((item) => item.tempId);

      if (newCampaigns.length > 0) {
        payload.campaigns = newCampaigns.map((item) => {
          return {
            name: item.name,
            type_id: item.type.value,
          };
        });
      }

      (id
        ? CompanyRepository.update(id, payload)
        : CompanyRepository.create(payload)
      )
        .then(() => {
          navigate(-1);

          if (!id) {
            openNotification(
              "success",
              t("notification.success.createItem", { item: title })
            );
          } else {
            openNotification(
              "success",
              t("notification.success.updateItem", { item: title })
            );
          }
        })
        .catch((e: AxiosError) => {
          console.log(e);
          openNotification("error", t("notification.error.default"));
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [isMounted, tableData]
  );

  const loadData = () => {
    if (!id) {
      return;
    }

    CompanyRepository.findOne(id, { with: ["logo", "users", "campaigns.type"] })
      .then((response: AxiosResponse) => {
        const { data: responseData } = response.data;

        const formValue: any = {
          name: responseData.name,
          logo_id: toFileList(responseData.logo),
          user_ids: _.isEmpty(responseData.users)
            ? []
            : convertLabelInValue(responseData.users),
        };
        form.setFieldsValue(formValue);
        setTableData(responseData.campaigns);
      })
      .catch((error: AxiosError) => {
        handleBackendError(error, t("notification.error.default"));
      });
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <>
      <VuiContainer>
        <VuiSectionTitle
          onBack={() => navigate(-1)}
          title={t(id ? "common.text.editItem" : "common.text.addItem", {
            item: title,
          })}
        />

        <VuiContent>
          <VuiFormLayout form={form} onFinish={onFinish}>
            <Row gutter={[16, 16]}>
              <Col span={14} xs={24}>
                <VuiFormCard>
                  <Row gutter={16}>
                    <Col span={24}>
                      <Form.Item
                        rules={[
                          {
                            required: true,
                            message: t("validation.required", {
                              item: t("common.form.name.label"),
                            }),
                          },
                        ]}
                        name={"name"}
                        label={t("common.form.name.label")}
                      >
                        <Input
                          placeholder={t("common.form.name.placeholder")}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        name={"user_ids"}
                        label={t("common.form.user.label")}
                        rules={[
                          {
                            required: true,
                            message: t("validation.required", {
                              item: t("common.form.user.label"),
                            }),
                          },
                        ]}
                      >
                        <VuiSelectMulti
                          placeholder={t("common.form.user.placeholder")}
                          repository={UserRepository}
                        />
                      </Form.Item>
                    </Col>

                    <Col span={24}>
                      <Form.Item
                        name={"logo_id"}
                        label={t("common.form.image.label")}
                      >
                        <VuiUploadImage isUploadFirst />
                      </Form.Item>
                    </Col>
                  </Row>
                </VuiFormCard>
              </Col>
              <Col span={14} xs={24}>
                <Card
                  title={t("common.text.campaign")}
                  extra={
                    <Button
                      type="primary"
                      ghost
                      onClick={() => {
                        setShowCampaignForm(true);
                      }}
                    >
                      {t("common.button.add")}
                    </Button>
                  }
                >
                  <Table
                    rowKey={(record) => (record.id ? record.id : record.tempId)}
                    bordered
                    columns={tableColumns}
                    dataSource={tableData}
                    pagination={false}
                  />
                </Card>
              </Col>
            </Row>
            <VuiFormAction
              label={id ? "common.text.editItem" : "common.text.addItem"}
              labelOption={{ item: t("common.text.company") }}
              saveLabel={id ? "common.button.save" : "common.button.add"}
              isLoading={isLoading}
              permission={
                id
                  ? ACCESS_PERMISSION.company.update
                  : ACCESS_PERMISSION.company.store
              }
            />
          </VuiFormLayout>
        </VuiContent>
      </VuiContainer>
      <ModalAddCampaign
        show={showCampaignForm}
        onCancel={() => setShowCampaignForm(false)}
        onSave={(data) => {
          setTableData((s) => [...s, ...data]);
        }}
      />
    </>
  );
};

export default AppSettingAccountCompanyForm;
