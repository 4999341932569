import Select from "antd/lib/select";
import React, { ReactNode, useCallback, useEffect, useState } from "react";
import { AxiosError, AxiosResponse } from "axios";
import {
  ConstantService,
  useDebounce,
  useIsMounted,
} from "../../../../../utils";
import { SizeType } from "antd/lib/config-provider/SizeContext";

const { Option } = Select;

interface valueProps {
  value: string | number;
  label?: ReactNode;
}

interface Props {
  value?: any;
  repositoryName?: string;
  onChange?: (value: valueProps | object) => void;
  repository: any;
  placeholder?: string;
  disabled?: boolean;
  defaultOptions?: Array<any>;
  style?: any;
  labelInValue?: boolean;
  size?: SizeType;
  selectParams?: object;
  labelKey?: string;
  valueKey?: string;
}

const VuiSelectSingle: React.FC<Props> = ({
  value,
  onChange,
  repository,
  placeholder,
  disabled = false,
  defaultOptions = [],
  style = {},
  repositoryName = "select",
  size = "middle",
  labelInValue = true,
  selectParams = {},
  labelKey = "name",
  valueKey = "id",
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const isMounted = useIsMounted();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [search, setSearch] = useState<string>("");
  const [hasNextData, setHasNextData] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [perPage, setPerPage] = useState<number>(
    Number(ConstantService.getConstant("DEFAULT_PER_PAGE"))
  );
  const [page, setPage] = useState<number>(
    Number(ConstantService.getConstant("DEFAULT_PAGE"))
  );
  const [data, setData] = useState<any[]>([]);

  const debouncedSearch = useDebounce<string>(search, 500);

  useEffect(() => {
    if (isMounted && isOpen) {
      getData(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearch, isOpen]);

  const getData = useCallback(
    async (reset: boolean = false) => {
      if (repository[repositoryName]) {
        let pageParams = 1;

        if (!reset) {
          pageParams = page + 1;
        }

        const params = {
          ...selectParams,
          per_page: perPage,
          page: pageParams,
        };

        if (debouncedSearch) {
          Object.assign(params, {
            search: debouncedSearch,
          });
        }

        setLoading(true);
        setHasNextData(false);

        await repository[repositoryName](params)
          .then((response: AxiosResponse) => {
            let { data: responseData } = response.data;
            if (reset) {
              if (defaultOptions.length) {
                responseData = [...defaultOptions, ...responseData];
              }
              setPage(1);
              setData(responseData);
            } else {
              setData((prevState) => [...prevState, ...responseData]);
              setPage(pageParams);
            }

            if (responseData.length >= perPage) {
              setHasNextData(true);
            }

            setLoading(false);
          })
          .catch((error: AxiosError) => {
            setLoading(false);
          });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [page, perPage, debouncedSearch, selectParams, hasNextData]
  );

  const handlePopUpScroll = useCallback(
    (event: any) => {
      const { scrollTop, offsetHeight, scrollHeight } = event.target;
      const isBottom = scrollTop + offsetHeight === scrollHeight;

      if (hasNextData && isBottom) {
        getData();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [hasNextData]
  );

  return (
    <>
      <Select
        showSearch
        size={size}
        value={value}
        style={style}
        placeholder={placeholder}
        defaultActiveFirstOption={false}
        filterOption={false}
        labelInValue={labelInValue}
        onSearch={(value) => {
          setSearch(value);
        }}
        onChange={(value) => {
          if (typeof onChange === "function") {
            onChange(value);
          }
        }}
        onDropdownVisibleChange={(open) => {
          setIsOpen(open);
        }}
        notFoundContent={null}
        onPopupScroll={handlePopUpScroll}
        loading={loading}
        disabled={disabled}
        dropdownStyle={{ 
          minWidth: 'fit-content'
         }}
      >
        {data.map((item: any, i: number) => {
          return (
            <Option key={i} value={item[valueKey]} label={item[labelKey]}>
              {item[labelKey]}
            </Option>
          );
        })}
      </Select>
    </>
  );
};

export default VuiSelectSingle;
