import { Space, Tag } from "antd";
import Button from "antd/es/button";
import Col from "antd/es/grid/col";
import Row from "antd/es/grid/row";
import Table from "antd/es/table";
import { ColumnType } from "antd/es/table/interface";
import { AxiosError, AxiosResponse } from "axios";
import _ from "lodash";
import { FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { VuiSearch } from "../../../../../@vodea/vodea-ui/components";
import VuiAccessible from "../../../../../@vodea/vodea-ui/components/Accessible";
import VuiContainer from "../../../../../@vodea/vodea-ui/components/Container";
import VuiContent from "../../../../../@vodea/vodea-ui/components/Content";
import VuiSectionTitle from "../../../../../@vodea/vodea-ui/components/Sections/Title";
import VuiSelectSingle from "../../../../../@vodea/vodea-ui/components/Select/Single";
import {
  ACCESS_PERMISSION,
  PRODUCT_DELIVERY_DETAIL_STATUS_COLOR,
  RECENT_FILTER_KEY,
} from "../../../../../constant";
import {
  handleBackendError,
  recentFilterData,
} from "../../../../../functions/global";
import ConstantRepository from "../../../../../repositories/ConstantRepository";
import ProductDeliveryRepository from "../../../../../repositories/ProductDeliveryRepository";
import { IBreadcrumbs } from "../../../../../stores/breadcrumbs";
import {
  useBreadcrumbs,
  useDebounce,
  useIsMounted,
} from "../../../../../utils";
import ModalLogEmail from "../components/ModalLogEmail";

var fileDownload = require("js-file-download");

const qs = require("qs");

interface IParams {
  search: string | null;
  status: string | null;
}

interface IAppOrderProductDeliveryRewardView {
  breadcrumbs: IBreadcrumbs[];
}

const AppOrderProductDeliveryRewardView: FC<
  IAppOrderProductDeliveryRewardView
> = ({ breadcrumbs }) => {
  useBreadcrumbs(breadcrumbs);
  const { id } = useParams();
  const { t } = useTranslation();
  const isMounted = useIsMounted();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [title, setTitle] = useState<string>(
    t("common.text.detailItem", {
      item: t("common.text.product"),
    })
  );
  const [search, setSearch] = useState<string>(
    searchParams.get("search") || ""
  );
  const [selectedStatus, setSelectedStatus] = useState<any>(
    searchParams.get("status")
      ? recentFilterData.getSelectedFilter(
          RECENT_FILTER_KEY.rewardRedeemStatus,
          [Number(searchParams.get("status"))]
        )[0]
      : null
  );
  const [selectedId, setSelectedId] = useState<any>(null);
  const debouncedSearch = useDebounce<string>(search, 500);
  const [tableLoading, setTableLoading] = useState<boolean>(false);
  const [tableData, setTableData] = useState<any[]>([]);

  const tableColumns: ColumnType<any>[] = [
    {
      title: t("common.text.uniqueCode"),
      dataIndex: "code",
      key: "code",
    },
    {
      title: t("common.text.status"),
      dataIndex: "status_name",
      key: "status",
      render: (text: any) => {
        return (
          <Tag color={_.get(PRODUCT_DELIVERY_DETAIL_STATUS_COLOR, text)}>
            {text}
          </Tag>
        );
      },
    },
    {
      title: t("common.text.information"),
      dataIndex: "information",
      key: "information",
    },
    {
      title: "",
      key: "action",
      render: (text: any, record: any) => (
        <Space align={"center"}>
          <Button
            onClick={() => {
              setSelectedId(record.id);
            }}
            type="link"
          >
            {t("common.button.viewLog")}
          </Button>
        </Space>
      ),
    },
  ];

  const loadData = () => {
    if (!isMounted) {
      return;
    }

    setTableLoading(true);

    const params: IParams = {
      search: searchParams.get("search"),
      status: searchParams.get("status"),
    };

    ProductDeliveryRepository.detailCode(id, params)
      .then((response: AxiosResponse) => {
        const { data: responseData } = response.data;
        const productName = response.data.product_name;
        if (productName) {
          setTitle(productName);
        }
        setTableData(responseData);
        setTableLoading(false);
      })
      .catch((error: AxiosError) => {
        handleBackendError(error, t("notification.error.default"));
        setTableLoading(false);
      });
  };

  const setQueryParams = useCallback(() => {
    const params: Partial<IParams> = {};

    Object.assign(params, {
      ...(debouncedSearch ? { search: debouncedSearch } : {}),
      ...(selectedStatus ? { status: selectedStatus.value } : {}),
    });

    const queryParams = qs.stringify(params, { indices: false });

    if (queryParams) {
      setSearchParams(`?${queryParams}`);
    } else {
      navigate("", { replace: true });
    }
  }, [setSearchParams, navigate, debouncedSearch, selectedStatus]);

  // Lifecycle
  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  const handleReset = () => {
    setSelectedStatus(null);
  };

  const handleExport = async () => {
    try {
      let res = await ProductDeliveryRepository.export(id);
      await fileDownload(res.data, `${Date.now()} - ${title} .xlsx`);
    } catch (err: any) {
      console.log(err, "err");
      handleBackendError(err);
    }
  };

  useEffect(() => {
    isMounted ? setQueryParams() : setSearchParams("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearch, isMounted, selectedStatus]);

  return (
    <>
      <VuiContainer>
        <VuiSectionTitle onBack={() => navigate(-1)} title={title}>
          <VuiAccessible access={ACCESS_PERMISSION.orderDelivery.excel}>
            <Button onClick={handleExport} type="primary">
              {t("common.button.export")}
            </Button>
          </VuiAccessible>
        </VuiSectionTitle>

        <VuiContent>
          <Row gutter={[10, 10]} className="mb24">
            <Col className="gutter-row" span={6} xs={24} md={12} lg={10}>
              <VuiSearch
                allowClear
                defaultValue={search}
                onSearch={(value: any) => setSearch(value)}
              />
            </Col>
          </Row>
          <Row gutter={[10, 10]} className="mb16">
            <Col>
              <VuiSelectSingle
                value={selectedStatus}
                onChange={(value: any) => {
                  setSelectedStatus(value);
                  recentFilterData.save(RECENT_FILTER_KEY.rewardRedeemStatus, [
                    value,
                  ]);
                }}
                labelKey="label"
                selectParams={{ for: "status_order_delivery_detail" }}
                repository={ConstantRepository}
                placeholder={t("common.text.status")}
              />
            </Col>
            <Col>
              <Button type="link" onClick={handleReset}>
                Reset
              </Button>
            </Col>
          </Row>

          <Table
            rowKey={"id"}
            bordered
            columns={tableColumns}
            dataSource={tableData}
            loading={tableLoading}
            pagination={false}
          />
        </VuiContent>
      </VuiContainer>

      <ModalLogEmail
        show={Boolean(selectedId)}
        onCancel={() => setSelectedId(null)}
        id={selectedId}
      />
    </>
  );
};

export default AppOrderProductDeliveryRewardView;
