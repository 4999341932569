import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IBreadcrumbs {
  title: string;
  link: string;
  isDisabled?: boolean;
}

const initialState: IBreadcrumbs[] = [];

export const breadcrumbsSlice = createSlice({
  name: "breadcrumbs",
  initialState,
  reducers: {
    updateBreadcrumbs: (state, action: PayloadAction<IBreadcrumbs[]>) =>
      action.payload,
  },
});

export const { updateBreadcrumbs } = breadcrumbsSlice.actions;
export default breadcrumbsSlice.reducer;
