import { Tabs } from "antd";
import { FC } from "react";
import { TabPaneProps } from "rc-tabs/lib/TabPanelList/TabPane";
const { TabPane } = Tabs;

const VuiTabPane: FC<TabPaneProps> = (props: TabPaneProps) => {
  const { children, style = { padding: "12px 24px 24px" }, ...other } = props;
  return (
    <TabPane style={style} {...other}>
      {children}
    </TabPane>
  );
};

export default VuiTabPane;
