import { Footer } from "antd/lib/layout/layout";
import { Navigate, Outlet } from "react-router";
import VuiLoadingScreen from "../../@vodea/vodea-ui/components/LoadingScreen";
import { useAppSelector } from "../../stores/hooks";
import { usePassportService } from "../../utils";

const AuthLayout: React.FC<any> = () => {
  const { isOnFetchingUser } = usePassportService();
  const system = useAppSelector((state) => state.system);

  if (isOnFetchingUser) {
    return <VuiLoadingScreen />;
  }

  if (system.isLoggedIn) {
    return <Navigate to={"/"} replace />;
  }

  return (
    <section id={"auth-layout"}>
      <div className="container-wrapper">
        <div className="container">
          <Outlet />
        </div>
      </div>
      <Footer className="footer">© 2022 Bukado</Footer>
    </section>
  );
};

export default AuthLayout;
