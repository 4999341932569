import { Tag } from "antd";
import * as React from "react";
import { TagProps } from "antd/lib/tag";
import { CSSProperties, useCallback, useMemo, useState } from "react";
import { ConstantService } from "../../../../utils";

export type StatusType =
  | "Active"
  | "Not Active"
  | "Open"
  | "Paid"
  | "Overdue"
  | "Confirmation"
  | "Suspended"
  | "Done"
  | "On Progress";

interface IVuiStatus extends TagProps, React.RefAttributes<HTMLElement> {
  type: StatusType;
}

const VuiStatus = ({ type }: IVuiStatus) => {
  const [styles, setStyles] = useState<CSSProperties>({
    color: "",
    backgroundColor: "",
    borderRadius: 20,
    fontWeight: 500,
    border: "none",
    padding: "1px 12px 2px 12px",
  });

  const generateStyles = useCallback((type: StatusType) => {
    if (type === "Paid" || type === "Done") {
      setStyles((prevState) => {
        return {
          ...prevState,
          color: String(ConstantService.getConstant("SUCCESS_COLOR")),
          backgroundColor: "rgba(82, 196, 26, 0.2)",
        };
      });
    } else if (type === "Not Active" || type === "Overdue") {
      setStyles((prevState) => {
        return {
          ...prevState,
          color: String(ConstantService.getConstant("ERROR_COLOR")),
          backgroundColor: "rgba(255, 77, 79, 0.2)",
        };
      });
    } else if (
      type === "Confirmation" ||
      type === "Suspended" ||
      type === "On Progress"
    ) {
      setStyles((prevState) => {
        return {
          ...prevState,
          color: String(ConstantService.getConstant("WARNING_COLOR")),
          backgroundColor: "rgba(250, 173, 20, 0.2)",
        };
      });
    } else if (type === "Open") {
      setStyles((prevState) => {
        return {
          ...prevState,
          color: String(ConstantService.getConstant("SECONDARY_TEXT_COLOR")),
          backgroundColor: "#E7E7E7",
        };
      });
    } else if (type === "Active") {
      setStyles((prevState) => {
        return {
          ...prevState,
          color: String(ConstantService.getConstant("PRIMARY_COLOR")),
          backgroundColor: "rgba(82, 196, 26, 0.2)",
        };
      });
    } else {
      setStyles((prevState) => {
        return {
          ...prevState,
          color: "#FF4D4F",
          backgroundColor: "rgba(255, 77, 79, 0.2)",
        };
      });
    }
  }, []);

  useMemo(() => {
    generateStyles(type);
  }, [generateStyles, type]);

  return <Tag style={styles}>{type}</Tag>;
};

export default VuiStatus;
