import { Table } from "antd/es";
import { Button, Space, Tag } from "antd/lib";
import Modal from "antd/lib/modal";
import { ColumnType } from "antd/lib/table";
import { AxiosError, AxiosResponse } from "axios";
import _ from "lodash";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ORDER_DELIVERY_LOG_STATUS_COLOR } from "../../../../../constant";
import { handleBackendError } from "../../../../../functions/global";
import OrderDeliveryLogRepository from "../../../../../repositories/OrderDeliveryLog";
import {
  ConstantService,
  ITableQuery,
  openNotification,
} from "../../../../../utils";

interface Props {
  show: boolean;
  onCancel: () => void;
  id: string | number;
}

const ModalLogEmail: React.FC<Props> = ({
  show,
  onCancel,

  id,
}) => {
  const { t } = useTranslation();
  const [resendId, setResendId] = useState<null | string | number>(null);
  const [tableQuery, setTableQuery] = useState<ITableQuery>({
    page: Number(ConstantService.getConstant("DEFAULT_PAGE")),
    perPage: Number(ConstantService.getConstant("DEFAULT_PER_PAGE")),
  });
  const [tableLoading, setTableLoading] = useState<boolean>(false);
  const [tableData, setTableData] = useState<any[]>([]);
  const [totalData, setTotalData] = useState<number>(0);

  const tableColumns: ColumnType<any>[] = [
    {
      title: t("common.text.email"),
      dataIndex: "email",
      key: "email",
    },
    {
      title: t("common.text.subject"),
      dataIndex: "subject",
      key: "subject",
    },
    {
      title: t("common.text.sentDate"),
      dataIndex: "sent_date",
      key: "sent_date",
      render: (text: any) => {
        return <div>{moment(text).format("DD MMMM YYYY, HH:mm")}</div>;
      },
    },
    {
      title: t("common.text.status"),
      dataIndex: "status",
      key: "status",
      render: (text: any) => {
        return (
          <Tag color={_.get(ORDER_DELIVERY_LOG_STATUS_COLOR, text)}>{text}</Tag>
        );
      },
    },

    {
      title: "",
      key: "action",
      render: (text: any, record: any) => (
        <Space align={"center"}>
          <Button
            loading={resendId === record.id}
            onClick={() => handleResend(record.id)}
            type="link"
          >
            {t("common.button.resend")}
          </Button>
        </Space>
      ),
    },
  ];

  const handleResend = (id: string | number) => {
    setResendId(id);

    const params = {
      email_log_id: id,
    };

    OrderDeliveryLogRepository.resend(id, params)
      .then(() => {
        openNotification("success", t("notification.success.resendEmail"));
      })
      .catch((e: AxiosError) => {
        handleBackendError(e, t("notification.error.default"));
      })
      .finally(() => {
        setResendId(null);
      });
  };

  useEffect(() => {
    if (show) {
      loadData();
    } else {
      setTableQuery((prevState) => ({
        ...prevState,
        page: 1,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableQuery.page, tableQuery.perPage, show]);

  const loadData = () => {
    setTableLoading(true);

    const params = {
      page: tableQuery.page,
      per_page: tableQuery.perPage,
    };

    OrderDeliveryLogRepository.findAll(id, params)
      .then((response: AxiosResponse) => {
        const { data: responseData, meta } = response.data;
        setTableLoading(false);
        setTableData(responseData);
        setTotalData(meta.total);
      })
      .catch((error: AxiosError) => {
        setTableLoading(false);
      });
  };

  const handleTableChange = useCallback((pagination: any) => {
    setTableQuery((prevState) => ({
      ...prevState,
      page: pagination.current,
      perPage: pagination.pageSize,
    }));
  }, []);

  return (
    <Modal
      title={t("common.text.emailLog")}
      centered
      visible={show}
      onCancel={onCancel}
      width="full"
      footer={null}
    >
      <div className="content">
        <Table
          rowKey={"id"}
          bordered
          columns={tableColumns}
          dataSource={tableData}
          loading={tableLoading}
          onChange={handleTableChange}
          pagination={
            tableData.length > 0
              ? {
                  current: tableQuery.page,
                  pageSize: tableQuery.perPage,
                  total: totalData,
                }
              : false
          }
        />
      </div>
    </Modal>
  );
};

export default ModalLogEmail;
