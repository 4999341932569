import { Button, Col, DatePicker, Form, Row } from "antd/lib";
import Modal from "antd/lib/modal";
import moment from "moment";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  show: boolean;
  onCancel: () => void;
  onSave: (data: any) => void;
  validTo: string;
  isUpdating: boolean;
}

const ModalUpdateValidDate: React.FC<Props> = ({
  show,
  onCancel,
  onSave,
  validTo,
  isUpdating,
}) => {
  const { t } = useTranslation();

  // form
  const [form] = Form.useForm();

  useEffect(() => {
    if (show) {
      form.setFieldsValue({
        valid_to: moment(validTo),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  const onFinish = async (data: any) => {
    const formData = {
      valid_to: moment(data.valid_to).format("YYYY-MM-DD"),
    };
    onSave(formData);
  };

  return (
    <Modal
      title={t("common.text.editItem", { item: t("common.text.validTo") })}
      centered
      visible={show}
      onCancel={onCancel}
      footer={null}
      width={400}
    >
      <div className="content">
        <Form
          className="text-align-left"
          form={form}
          layout={"vertical"}
          onFinish={onFinish}
        >
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name={"valid_to"}
                rules={[
                  {
                    required: true,
                    message: t("validation.required", {
                      item: "Date",
                    }),
                  },
                ]}
              >
                <DatePicker
                  style={{ width: "100%" }}
                  placeholder={t("common.form.validUntil.placeholder")}
                  format="DD-MM-YYYY"
                />
              </Form.Item>
            </Col>
          </Row>
          <div className="vui-form-btn-group">
            <Button key="back" onClick={onCancel}>
              Back
            </Button>

            <Button
              key="submit"
              type="primary"
              htmlType="submit"
              loading={isUpdating}
            >
              Save
            </Button>
          </div>
        </Form>
      </div>
    </Modal>
  );
};

export default ModalUpdateValidDate;
