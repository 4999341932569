import React, { useEffect } from "react";
import { useState } from "@hookstate/core";
import Modal from "antd/lib/modal";
import Text from "antd/lib/typography/Text";
import { useTranslation } from "react-i18next";
import {
  Form,
  Button,
  Input,
  Row,
  Col,
  Select,
  Space,
  Checkbox,
  Radio,
  Divider,
  Typography,
  Card,
} from "antd/lib";
import { CloseOutlined, DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import VuiSelectSingle from "../../../../../../@vodea/vodea-ui/components/Select/Single";
import ConstantRepository from "../../../../../../repositories/ConstantRepository";
import _ from "lodash";

const { Title } = Typography;

interface Props {
  show: boolean;
  onCancel: () => void;
  onSave: (data: any) => void;
}

const ModalAddCampaign: React.FC<Props> = ({ show, onCancel, onSave }) => {
  const { t } = useTranslation();

  // form
  const [form] = Form.useForm();

  useEffect(() => {
    if (show) {
      form.resetFields();
    }
  }, [show]);

  const onFinish = async (data: any) => {
    const campaigns = data.campaigns.map((item: any) => {
      return {
        ...item,
        tempId: `${new Date().getTime()}${_.uniqueId()}`,
      };
    });
    onSave(campaigns);
    onCancel();
  };

  return (
    <Modal
      title={t("common.text.addItem", { item: t("common.text.campaign") })}
      centered
      visible={show}
      onCancel={onCancel}
      footer={null}
      width={750}
    >
      <div className="content">
        <Form
          className="text-align-left"
          form={form}
          layout={"vertical"}
          onFinish={onFinish}
          initialValues={{
            campaigns: [
              {
                name: "",
                type: null,
              },
            ],
          }}
        >
          <Row gutter={16}>
            <Col span={24}>
              <Form.List name="campaigns">
                {(fields, { add, remove }) => (
                  <Space
                    style={{
                      width: "100%",
                    }}
                    direction="vertical"
                    size={15}
                  >
                    {fields.map(({ key, name, ...restField }) => (
                      <Card title={""} key={key}>
                        <Row align={"middle"} justify={"space-between"}>
                          <Col>
                            <Title level={5}>{`${t("common.text.campaign")} ${
                              name + 1
                            }`}</Title>
                          </Col>
                          {name !== 0 && (
                            <Col>
                              <Button
                                type="ghost"
                                danger
                                onClick={() => remove(name)}
                              >
                                {t("common.button.remove")}
                              </Button>
                            </Col>
                          )}
                        </Row>

                        <div className="vui-form-group type-column">
                          <div className="input-section">
                            <Form.Item
                              {...restField}
                              name={[name, "name"]}
                              label={t("common.form.name.label")}
                              rules={[
                                {
                                  required: true,
                                  message: t("validation.required", {
                                    item: t("common.form.name.label"),
                                  }),
                                },
                              ]}
                            >
                              <Input
                                placeholder={t("common.form.name.placeholder")}
                              />
                            </Form.Item>
                          </div>
                        </div>

                        <div className="vui-form-group type-column">
                          <div className="input-section">
                            <Form.Item
                              {...restField}
                              name={[name, "type"]}
                              label={t("common.text.deliveryType")}
                              rules={[
                                {
                                  required: true,
                                  message: t("validation.required", {
                                    item: t("common.text.deliveryType"),
                                  }),
                                },
                              ]}
                            >
                              <VuiSelectSingle
                                labelKey="label"
                                repository={ConstantRepository}
                                selectParams={{ for: "delivery_type" }}
                                placeholder={t("common.text.deliveryType")}
                              />
                            </Form.Item>
                          </div>
                        </div>
                      </Card>
                    ))}
                    <Form.Item className={"mt16"}>
                      <Button
                        type="dashed"
                        onClick={() => add()}
                        block
                        icon={<PlusOutlined />}
                      >
                        {t("common.button.add")}
                      </Button>
                    </Form.Item>
                  </Space>
                )}
              </Form.List>
            </Col>
          </Row>
          <div className="vui-form-btn-group">
            <Button key="back" onClick={onCancel}>
              Back
            </Button>

            <Button key="submit" type="primary" htmlType="submit">
              Save
            </Button>
          </div>
        </Form>
      </div>
    </Modal>
  );
};

export default ModalAddCampaign;
