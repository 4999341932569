import React, { useMemo } from "react";
import { useAppSelector } from "../../../../stores/hooks";
import { IBreadcrumbs } from "../../../../stores/breadcrumbs";
import { Link } from "react-router-dom";
import { Breadcrumb } from "antd";

const VuiBreadcrumbs = () => {
  const breadcrumbs: IBreadcrumbs[] = useAppSelector(
    (state) => state.breadcrumbs
  );

  const renderBreadcrumbs = useMemo(() => {
    return breadcrumbs.length
      ? breadcrumbs.map((breadcrumb, index) => {
          const isLast = breadcrumbs.length - 1 === index;
          return (
            <Breadcrumb.Item key={index}>
              {breadcrumb.isDisabled || isLast ? (
                breadcrumb.title
              ) : (
                <Link to={breadcrumb.link}>{breadcrumb.title}</Link>
              )}
            </Breadcrumb.Item>
          );
        })
      : null;
  }, [breadcrumbs]);

  return (
    <div className="title-wrapper">
      <Breadcrumb>{renderBreadcrumbs}</Breadcrumb>
    </div>
  );
};

export default VuiBreadcrumbs;
