import "./style.less";
import React from "react";
import Card from "antd/es/card";
import { ArrowDownOutlined, ArrowUpOutlined } from "@ant-design/icons";
import clsx from "clsx";
import { Skeleton } from "antd";

export declare type type = "up" | "down";

interface Props {
  title: string;
  value: string;
  percentage: string;
  loading?: boolean;
  type: type;
  comparison: string;
}

const VuiCardSummary: React.FC<Props> = ({
  title,
  value,
  percentage,
  loading,
  type,
  comparison = "than last week",
}) => {
  return (
    <Card className="vui-summary-card">
      <div className="info-wrapper">
        <Skeleton
          loading={loading}
          active
          paragraph={{ rows: 1, width: "100%" }}
        >
          <p className="title mb8">{title}</p>
          <div className="value-wrapper">
            <label className="value">{value}</label>
          </div>
          <div className="value-wrapper">
            <label className="info-value">
              {comparison}{" "}
              <span
                className={clsx("percentage", {
                  up: type === "up",
                  down: type === "down",
                })}
              >
                {percentage}{" "}
                {type === "up" ? <ArrowUpOutlined /> : <ArrowDownOutlined />}
              </span>
            </label>
          </div>
        </Skeleton>
      </div>
    </Card>
  );
};

export default VuiCardSummary;
