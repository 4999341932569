import { Card, Skeleton, Space, Tag } from "antd";
import Col from "antd/es/grid/col";
import Row from "antd/es/grid/row";
import Table from "antd/es/table";
import { ColumnType } from "antd/es/table/interface";
import Link from "antd/lib/typography/Link";
import Text from "antd/lib/typography/Text";
import { AxiosError, AxiosResponse } from "axios";
import _ from "lodash";
import moment from "moment";
import { FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
  VuiFormTitle,
  VuiSearch,
} from "../../../../../../@vodea/vodea-ui/components";
import VuiContainer from "../../../../../../@vodea/vodea-ui/components/Container";
import VuiContent from "../../../../../../@vodea/vodea-ui/components/Content";
import VuiSectionTitle from "../../../../../../@vodea/vodea-ui/components/Sections/Title";
import { PRODUCT_DELIVERY_DETAIL_STATUS_COLOR } from "../../../../../../constant";
import { handleBackendError } from "../../../../../../functions/global";
import SalesOrderRepository from "../../../../../../repositories/SalesOrderRepository";
import { IBreadcrumbs } from "../../../../../../stores/breadcrumbs";
import {
  useBreadcrumbs,
  useDebounce,
  useIsMounted,
} from "../../../../../../utils";
import ModalUpdateValidDate from "../../Components/ModalUpdateValidDate";

const qs = require("qs");

interface IParams {
  search: string | null;
}

interface IAppOrderSalesOrderRewardView {
  breadcrumbs: IBreadcrumbs[];
}

const AppOrderSalesOrderRewardView: FC<IAppOrderSalesOrderRewardView> = ({
  breadcrumbs,
}) => {
  useBreadcrumbs(breadcrumbs);
  const { id } = useParams();
  const { t } = useTranslation();
  const isMounted = useIsMounted();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [batchData, setBatchData] = useState<any>({});
  const [showModalUpdateValidTo, setShowModalUpdateValidTo] = useState(false);
  const [isUpdatingValidTo, setIsUpdatingValidTo] = useState(false);

  const [search, setSearch] = useState<string>(
    searchParams.get("search") || ""
  );
  const debouncedSearch = useDebounce<string>(search, 500);

  const [tableLoading, setTableLoading] = useState<boolean>(false);

  const tableColumns: ColumnType<any>[] = [
    {
      title: t("common.text.uniqueCode"),
      dataIndex: "code",
      key: "code",
    },
    {
      title: t("common.text.status"),
      dataIndex: "status_name",
      key: "status_name",
      render: (text: any) => {
        return (
          <Tag color={_.get(PRODUCT_DELIVERY_DETAIL_STATUS_COLOR, text)}>
            {text}
          </Tag>
        );
      },
    },
  ];

  const loadData = () => {
    if (!isMounted) {
      return;
    }

    setTableLoading(true);

    const params: IParams = {
      search: searchParams.get("search"),
    };

    SalesOrderRepository.detailCode(id, params)
      .then((response: AxiosResponse) => {
        const responseData = response.data;

        setBatchData(responseData);

        setTableLoading(false);
      })
      .catch((error: AxiosError) => {
        handleBackendError(error, t("notification.error.default"));
        setTableLoading(false);
      });
  };

  const setQueryParams = useCallback(() => {
    const params: Partial<IParams> = {};

    Object.assign(params, {
      ...(debouncedSearch ? { search: debouncedSearch } : {}),
    });

    const queryParams = qs.stringify(params, { indices: false });

    if (queryParams) {
      setSearchParams(`?${queryParams}`);
    } else {
      navigate("", { replace: true });
    }
  }, [setSearchParams, navigate, debouncedSearch]);

  const handleUpdateValidTo = (data: any) => {
    setIsUpdatingValidTo(true);

    SalesOrderRepository.updateValidDate(id, data)
      .then(() => {
        setIsUpdatingValidTo(false);
        setShowModalUpdateValidTo(false);

        loadData();
      })
      .catch((error: AxiosError) => {
        handleBackendError(error, t("notification.error.default"));
        setIsUpdatingValidTo(false);
      });
  };

  // Lifecycle
  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  useEffect(() => {
    isMounted ? setQueryParams() : setSearchParams("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearch, isMounted]);

  return (
    <>
      <VuiContainer>
        <VuiSectionTitle
          onBack={() => navigate(-1)}
          title={t("common.text.detailItem", {
            item: t("common.text.reward"),
          })}
        />

        <VuiContent>
          <Row gutter={[24, 24]}>
            <Col xs={24} md={18} lg={16}>
              <Card>
                <Row gutter={[24, 24]}>
                  <Col span={24}>
                    <VuiFormTitle title={t("common.form.price.label")} />
                    <Skeleton
                      loading={tableLoading}
                      active
                      paragraph={false}
                      title
                    >
                      <Text strong>{batchData?.point || "-"}</Text>
                    </Skeleton>
                  </Col>
                  <Col span={24}>
                    <VuiFormTitle title={t("common.form.quantity.label")} />
                    <Skeleton
                      loading={tableLoading}
                      active
                      paragraph={false}
                      title
                    >
                      <Text strong>{batchData?.quantity || "-"}</Text>
                    </Skeleton>
                  </Col>
                  {!!batchData?.valid_from && (
                    <Col span={8}>
                      <VuiFormTitle title={t("common.text.validFrom")} />
                      <Skeleton
                        loading={tableLoading}
                        active
                        paragraph={false}
                        title
                      >
                        <Text strong>
                          {moment(batchData?.valid_from).format(
                            "DD MMMM YYYY"
                          ) || "-"}
                        </Text>
                      </Skeleton>
                    </Col>
                  )}
                  {!!batchData?.valid_to && (
                    <Col span={16}>
                      <VuiFormTitle title={t("common.text.validTo")} />
                      <Skeleton
                        loading={tableLoading}
                        active
                        paragraph={false}
                        title
                      >
                        <Space direction="horizontal">
                          <Text strong>
                            {moment(batchData?.valid_to).format(
                              "DD MMMM YYYY"
                            ) || "-"}
                          </Text>
                          <Link onClick={() => setShowModalUpdateValidTo(true)}>
                            Edit
                          </Link>
                        </Space>
                      </Skeleton>
                    </Col>
                  )}
                </Row>
              </Card>
            </Col>
            <Col xs={24} md={18} lg={16}>
              <Row gutter={[10, 10]} className="mb24">
                <Col className="gutter-row" span={6} xs={24} md={12} lg={10}>
                  <VuiSearch
                    allowClear
                    defaultValue={search}
                    onSearch={(value: any) => setSearch(value)}
                  />
                </Col>
              </Row>

              <Table
                rowKey={"id"}
                bordered
                columns={tableColumns}
                dataSource={batchData?.data || []}
                loading={tableLoading}
                pagination={false}
              />
            </Col>
          </Row>
        </VuiContent>
      </VuiContainer>
      <ModalUpdateValidDate
        show={showModalUpdateValidTo}
        onCancel={() => setShowModalUpdateValidTo(false)}
        onSave={handleUpdateValidTo}
        validTo={batchData?.valid_to}
        isUpdating={isUpdatingValidTo}
      />
    </>
  );
};

export default AppOrderSalesOrderRewardView;
