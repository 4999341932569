import React, { useCallback, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Form from "antd/es/form";
import Input from "antd/es/input";
import { Image, Row, Typography } from "antd";
import { openNotification, usePassportService } from "../../../utils";
import VuiButton from "../../../@vodea/vodea-ui/components/Button";

const { Title } = Typography;

interface ILogin {
  username: string;
  password: string;
}

const AuthLogin: React.FC<any> = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [form] = Form.useForm<ILogin>();
  const [loading, setLoading] = useState<boolean>(false);
  const { login } = usePassportService();

  const onFinish = useCallback(async (data: ILogin) => {
    setLoading(true);

    await login(data.username, data.password)
      .then(() => {
        setLoading(false);
        navigate("/");
      })
      .catch((e) => {
        setLoading(false);
        openNotification(
          "error",
          e.response?.data?.message || t("notification.error.default")
        );
      });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet>
        <title>{t("auth.login.metaTitle")}</title>
      </Helmet>

      <div className="main-wrapper">
        <div className="form-wrapper">
          <div className="form-logo">
            <Image src={"images/logo-text.png"} width={120} preview={false} />
          </div>
          <div className="form-header">
            <Title level={3} className="title">
              {t("auth.login.title")}
            </Title>
          </div>

          <Form
            form={form}
            layout={"vertical"}
            onFinish={onFinish}
            hideRequiredMark
          >
            <Form.Item
              rules={[
                {
                  required: true,
                  message: t("validation.required", {
                    item: t("common.form.email.label"),
                  }),
                },
                {
                  type: "email",
                  message: t("validation.type.email", {
                    item: t("common.form.email.label"),
                  }),
                },
              ]}
              name={"username"}
              label={t("common.form.email.label")}
            >
              <Input placeholder={t("common.form.email.placeholder")} />
            </Form.Item>

            <Form.Item
              rules={[
                {
                  required: true,
                  message: t("validation.required", {
                    item: t("common.form.password.label"),
                  }),
                },
              ]}
              name="password"
              label={t("common.form.password.label")}
            >
              <Input.Password
                placeholder={t("common.form.password.placeholder")}
              />
            </Form.Item>

            <Row className="mt24" justify={"space-between"} align={"middle"}>
              <Link to={"/forgot-password"}>
                {t("auth.login.forgotPassword")}
              </Link>

              <VuiButton
                loading={loading}
                label={t("common.button.login")}
                buttonProps={{
                  htmlType: "submit",
                }}
              />
            </Row>
          </Form>
        </div>
      </div>
    </>
  );
};

export default AuthLogin;
